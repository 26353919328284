import React, { useState } from "react";
import {
  getAuth,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from "firebase/auth";
import { toast } from "react-toastify";
import "./AccountSettingsModal.css";
import { useTutorial } from "./TutorialContext"; // Ensure this is correctly imported

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const AccountSettingsModal = ({ onClose }) => {
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const auth = getAuth();

  const { startTour } = useTutorial(); // Use the hook to get the startTour function

  const handlePasswordChange = () => {
    const user = auth.currentUser;
    const credential = EmailAuthProvider.credential(
      user.email,
      currentPassword
    ); // Create credential

    reauthenticateWithCredential(user, credential)
      .then(() => {
        // User re-authenticated.
        updatePassword(user, newPassword)
          .then(() => {
            toast.success("Password updated successfully.");
            onClose(); // Close modal on success
          })
          .catch((error) => {
            toast.error("Failed to update password.");
          });
      })
      .catch((error) => {
        toast.error("Current password is incorrect.");
      });
  };

  const startTutorialAndClose = () => {
    localStorage.removeItem("tourCompleted"); // Clear the tour completion flag from localStorage
    startTour(); // Start the tutorial
    onClose(); // Close the modal
  };

  return (
    <div className="modal-background">
      <div className="account-modal-container">
        <button className="account-close-btn" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <h2>Account Settings</h2>
        <button onClick={() => setShowChangePassword(!showChangePassword)}>
          Change Password
        </button>
        {showChangePassword && (
          <>
            <input
              type="password"
              placeholder="Current Password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
            <input
              type="password"
              placeholder="New Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <button onClick={handlePasswordChange}>Update Password</button>
          </>
        )}
        <button onClick={startTutorialAndClose}>View Tutorial</button>{" "}
        {/* Combined function for tutorial and close */}
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default AccountSettingsModal;
