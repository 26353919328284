import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { doc, onSnapshot } from "firebase/firestore";
import { format } from "date-fns"; // Importing date-fns for date formatting

const CustomerSupportChat = ({ firestore }) => {
  const [emails, setEmails] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [sessionId, setSessionId] = useState(null);
  const [tokenValid, setTokenValid] = useState(false);
  const [loading, setLoading] = useState(false); // Loading state
  const chatContainerRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");

    if (token === process.env.REACT_APP_CUSTOMER_SUPPORT_TOKEN) {
      // Replace with your actual secure token
      setTokenValid(true);
    } else {
      navigate("/"); // Redirect to home if token is invalid
    }
  }, [location, navigate]);

  useEffect(() => {
    if (tokenValid) {
      // Fetch the list of emails (you need to implement the API endpoint)
      fetch("https://getchatemails-cgn2j7f72a-uc.a.run.app")
        .then((response) => response.json())
        .then((data) => {
          const uniqueEmails = Array.from(
            new Set(data.emails.map((email) => email))
          );
          setEmails(uniqueEmails);
        });
    }
  }, [tokenValid]);

  useEffect(() => {
    if (selectedEmail) {
      // Fetch the conversation for the selected email (you need to implement the API endpoint)
      fetch(`https://getchat-cgn2j7f72a-uc.a.run.app?email=${selectedEmail}`)
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            setMessages(data.messages || []);
            setSessionId(data.sessionId || null);
            console.log("NOW DATA SESSION ID " + (data.sessionId || ""));
          }
        });
    }
  }, [selectedEmail]);

  useEffect(() => {
    if (sessionId) {
      const unsubscribe = onSnapshot(
        doc(firestore, "chatSessions", sessionId),
        (doc) => {
          if (doc.exists) {
            const data = doc.data();
            if (data) {
              setMessages(data.messages || []);
            }
          }
        },
        (error) => {
          console.error("Error listening to session document:", error);
        }
      );

      return () => {
        unsubscribe();
      };
    }
  }, [sessionId, firestore]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const handleSend = async () => {
    if (input.trim() && sessionId) {
      setLoading(true); // Set loading to true
      console.log("sending a message " + sessionId);
      try {
        // Send message to the backend (you need to implement the API endpoint)
        await fetch("https://humanmessage-cgn2j7f72a-uc.a.run.app", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ sessionId, message: input }),
        });
        setMessages((prevMessages) => [
          ...prevMessages,
          { from: "support", text: input, timestamp: new Date().toISOString() },
        ]);
        setInput("");
      } catch (error) {
        console.error("Error sending message:", error);
      } finally {
        setLoading(false); // Set loading to false
      }
    }
  };

  const handleResumeBot = async () => {
    if (sessionId) {
      setLoading(true); // Set loading to true
      try {
        await fetch("https://resumebot-cgn2j7f72a-uc.a.run.app", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ sessionId }),
        });
        // Add any additional handling needed after resuming the bot
      } catch (error) {
        console.error("Error resuming bot:", error);
      } finally {
        setLoading(false); // Set loading to false
      }
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !loading) {
      handleSend();
    }
  };

  return tokenValid ? (
    <div style={{ display: "flex" }}>
      <div style={{ width: "20%", borderRight: "1px solid #ccc" }}>
        <h3>Emails</h3>
        <ul>
          {emails.map((email) => (
            <li key={email} onClick={() => setSelectedEmail(email)}>
              {email}
            </li>
          ))}
        </ul>
      </div>
      <div style={{ width: "80%", padding: "10px" }}>
        {selectedEmail ? (
          <>
            <h3>Chat with {selectedEmail}</h3>
            <div
              ref={chatContainerRef}
              style={{
                height: "400px",
                overflowY: "scroll",
                border: "1px solid #ccc",
                padding: "10px",
              }}
            >
              {messages.map((msg, index) => (
                <div
                  key={index}
                  style={{ textAlign: msg.from === "user" ? "right" : "left" }}
                >
                  <p style={{ fontSize: "0.8em", color: "gray" }}>
                    {msg.timestamp
                      ? format(new Date(msg.timestamp), "MMMM d 'at' h:mm a")
                      : ""}
                  </p>
                  <p>
                    <strong>{msg.from}:</strong> {msg.text}
                  </p>
                </div>
              ))}
            </div>
            <div style={{ marginTop: "10px" }}>
              <input
                type="text"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={handleKeyDown}
                style={{ width: "80%" }}
                disabled={loading} // Disable input while loading
              />
              <button
                onClick={handleSend}
                style={{ width: "10%" }}
                disabled={loading}
              >
                {loading ? "Sending..." : "Send"}
              </button>
              <button
                onClick={handleResumeBot}
                style={{ width: "10%" }}
                disabled={loading}
              >
                {loading ? "Resuming..." : "Resume Bot"}
              </button>
            </div>
          </>
        ) : (
          <p>Select an email to view the chat.</p>
        )}
      </div>
    </div>
  ) : (
    <p>Invalid token. Please provide a valid token to access this page.</p>
  );
};

export default CustomerSupportChat;
