import React, { useState, useEffect, useRef } from "react";
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import "./ChatInput.css";
import QuickReplies from "./QuickReplies";
import { useTutorial } from "./TutorialContext"; // Import the hook
import allFormsImage from "../assets/all_forms_arrow.png";
import Spinner from "./Spinner";
import { ToastContainer, toast } from "react-toastify";
import StartNewVoiceForm from "./StartNewVoiceForm"; // Import the new component
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { debounce } from "lodash";

import {
  faCopy,
  faPaperclip,
  faPaperPlane,
  faExternalLinkAlt,
  faFolderOpen,
  faEdit,
  faTrashAlt,
  faChevronDown,
  faChevronUp,
  faFileAlt,
  faEye,
  faSyncAlt,
  faArchive,
  faPlusSquare,
  faMicrophone,
} from "@fortawesome/free-solid-svg-icons";
import ConfirmationModal from "./ConfirmationModalDelete";
import {
  getFirestore,
  collection,
  getDocs,
  doc,
  getDoc,
  deleteDoc,
  updateDoc,
  addDoc,
  onSnapshot,
  query,
  where,
  writeBatch,
  increment,
  limit,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";

import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import DownloadDropdown from "./DownloadDropdown"; // Import the new component

const ChatInput = ({
  isSidebarOpen,
  currentUser,
  currentMessage,
  promptMessage,
  onQuickReply,
  onShowCreateFormModal,
  onShowCreateQuestionnaireModal,
  forms = [],
  questionnaires = [],
  onShowEditFormModal,
  onCreateSubFolder,
  onDeleteSubFolder,
  sessions,
  showAccountNameModal,
}) => {
  const [isFirstTimeTourActive, setIsFirstTimeTourActive] = useState(false);
  const [firstTimeTourCompleted, setFirstTimeTourCompleted] = useState(false);
  const [isFirstTimeTourComplete, setIsFirstTimeTourComplete] = useState(false);
  const [startMainTour, setStartMainTour] = useState(false);

  // Locale configuration to change the button text
  const locale = {
    last: "Close", // Changing the text for the last step
  };

  const { runTour, startTour, stopTour } = useTutorial();
  const [steps, setSteps] = useState([
    {
      target: ".content-box:nth-of-type(1)", // First button
      content: "Click here to set up a new form and add questions.",
      placement: "top",
      disableBeacon: true,
    },
    {
      target: ".content-box:nth-of-type(2)", // Second button
      content:
        "Once you have a form to use, click here to create a form session.  Transfer your respondents to the voice chatbot, or have them call directly.",
      placement: "top",
      disableBeacon: true,
    },

    {
      target: ".subfolders-table", // Assuming you have a way to point to the sidebar
      content:
        "You can edit created forms and view transcripts here.\nWe've created one Example Form for you already.",
      placement: "left",
      disableBeacon: true,
    },
    {
      target: ".toggle-sidebar-btn", // Assuming you have a way to point to the sidebar
      content:
        "Use the sidebar menu to change your chatbot's voice, add credits and manage other settings.",
      placement: "left",
      disableBeacon: true,
    },
    {
      target: ".help-message", // Assuming you have a way to point to the sidebar
      content:
        "Access more help options or contact support using the chat widget.",
      placement: "right-start",
      disableBeacon: true,
    },
  ]);

  const handleFirstTimeTourCallback = (data) => {
    const { status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setIsFirstTimeTourComplete(true);
      localStorage.setItem("firstTimeTourCompleted", "true");

      const tourCompleted = localStorage.getItem("tourCompleted");
      if (!tourCompleted) {
        setStartMainTour(true);
      }
    }
  };

  const handleMainTourCallback = (data) => {
    const { status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      stopTour();
      localStorage.setItem("tourCompleted", "true");
    }
  };

  const [activeRow, setActiveRow] = useState(null);
  const tableRef = useRef(null);
  const containerRef = useRef(null);
  const archiveRef = useRef(null);
  const chatLogRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [isLoadedComplete, setIsLoadedComplete] = useState(false);

  const [inputValue, setInputValue] = useState("");
  const [chatLog, setChatLog] = useState([]);
  const [quickReplies, setQuickReplies] = useState([
    "How it works",
    "Automate New Client Form",
  ]);
  const [localSessions, setLocalSessions] = useState([]);

  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [collapsedFolders, setCollapsedFolders] = useState({});
  const [phoneNumber, setPhoneNumber] = useState("");

  const [sessionPin, setSessionPin] = useState("");
  const [selectedSubFolders, setSelectedSubFolders] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [formToDelete, setFormToDelete] = useState(null);
  const [subFolderToDelete, setSubFolderToDelete] = useState(null);
  const [localSubFolders, setLocalSubFolders] = useState([]);
  const [showStartNewVoiceForm, setShowStartNewVoiceForm] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState({});
  const [statusData, setStatusData] = useState({});
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [archiveSessionToDelete, setArchiveSessionToDelete] = useState(null);
  const [sortConfig, setSortConfig] = useState({
    key: "createdAt",
    direction: "descending",
  });

  const [searchClientID, setSearchClientID] = useState("");
  const [searchResult, setSearchResult] = useState(null);

  const [copyText, setCopyText] = useState("Copy");

  const [formSearchClientID, setFormSearchClientID] = useState({});
  const [formSearchInputs, setFormSearchInputs] = useState({});
  const [formSearchResults, setFormSearchResults] = useState({});

  const handleSearch = () => {
    const foundSession = localSessions.find(
      (session) => session.clientID === searchClientID
    );
    if (foundSession) {
      setSearchResult(foundSession);
    } else {
      setSearchResult("not-found");
    }
  };

  const handleClearSearch = () => {
    setSearchClientID("");
    setSearchResult(null);
  };

  const addAuditTrail = async (mainUserId, auditEntry) => {
    const now = new Date();
    const year = now.getFullYear().toString();
    const month = (now.getMonth() + 1).toString().padStart(2, "0"); // Month index starts from 0
    const firestore = getFirestore();
    const auditRef = collection(
      firestore,
      "users",
      mainUserId,
      "auditTrails",
      year,
      month
    );
    await addDoc(auditRef, auditEntry);
  };

  const getUserIP = async () => {
    const response = await fetch("https://api.ipify.org?format=json");
    const data = await response.json();
    return data.ip;
  };
  const generateCSV = async (session) => {
    console.log("inside generateCSV");
    console.log(session);
    const mainUserId = currentUser.mainUserId || currentUser.uid; // Fallback to currentUser.uid if mainUserId is undefined
    if (!mainUserId) {
      console.error("User ID is undefined");
      return; // Exit if no user ID available
    }

    const formatDate = (timestamp) => {
      try {
        const date = new Date(timestamp);
        return date.toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: false,
        });
      } catch (error) {
        console.error("Error formatting date:", error);
        return "Invalid date";
      }
    };

    const sanitizeText = (text) => {
      return text.replace(/[\r\n]+/g, " "); // Replace new lines with space
    };

    const rows = session.transcripts.map((transcript, index) => {
      // Debugging: Check each transcript entry
      console.log(`Processing transcript ${index}:`, transcript);

      return {
        QuestionNumber: transcript.questionNumber + 1,
        Question: sanitizeText(transcript.question.text),
        Response: sanitizeText(transcript.response || "No response"), // Handling missing response
        Timestamp: formatDate(transcript.timestamp),
      };
    });

    const csvHeader = "Question Number,Question,Response,Timestamp\n";
    const csvRows = rows.map((e) => Object.values(e).join(",")).join("\n");
    console.log("csvRows " + csvRows);
    const csvContent = `data:text/csv;charset=utf-8,Client ID: ${session.clientID}\nSession ID: ${session.sessionID}\nFirst Name: ${session.firstName}\nLast Name: ${session.lastName}\nForm: ${session.formName}\n\n${csvHeader}${csvRows}`;

    // Create a Blob from the CSV String
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    // Create a link and trigger a download
    const link = document.createElement("a");
    link.href = url;
    link.download = `${session.sessionID}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Remove the link when done

    // URL cleanup
    URL.revokeObjectURL(url);

    // Add audit trail entry
    const auditEntry = {
      username: currentUser.email,
      timestamp: new Date().toISOString(),
      documentAccessed: `${session.sessionID}.csv`,
      clientId: `${session.clientID}`,
      actionPerformed: "download",
      sourceIP: await getUserIP(),
      location: window.location.href,
      device: navigator.userAgent,
    };

    await addAuditTrail(mainUserId, auditEntry);
  };

  const generatePDF = async (session) => {
    const mainUserId = currentUser.mainUserId || currentUser.uid; // Fallback to currentUser.uid if mainUserId is undefined
    if (!mainUserId) {
      console.error("User ID is undefined");
      return; // Exit if no user ID available
    }
    const doc = new jsPDF();

    doc.text(`Client ID: ${session.clientID}`, 10, 10);
    doc.text(`Session ID: ${session.sessionID}`, 10, 20);
    if (session.firstName) doc.text(`First Name: ${session.firstName}`, 10, 30);
    if (session.lastName) doc.text(`Last Name: ${session.lastName}`, 10, 40);
    if (session.formName) doc.text(`Form: ${session.formName}`, 10, 50);

    const formatDate = (timestamp) => {
      const date = new Date(timestamp);
      return date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false, // Set to true if you want AM/PM format
      });
    };

    const rows = session.transcripts.map((transcript, index) => [
      transcript.questionNumber + 1,
      transcript.question.text,
      transcript.response,
      formatDate(transcript.timestamp),
    ]);

    autoTable(doc, {
      head: [["Question Number", "Question", "Response", "Timestamp"]],
      body: rows,
      startY: 60,
    });

    doc.save(`${session.sessionID}.pdf`);

    // Add audit trail entry
    const auditEntry = {
      username: currentUser.email,
      timestamp: new Date().toISOString(),
      documentAccessed: `${session.sessionID}.pdf`,
      clientId: `${session.clientID}`,
      actionPerformed: "download",
      sourceIP: await getUserIP(),
      location: window.location.href,
      device: navigator.userAgent,
    };

    await addAuditTrail(mainUserId, auditEntry);
  };

  const handleFormSearch = (formId) => {
    const searchValue = formSearchClientID[formId] || "";
    const foundSessions = localSessions.filter(
      (session) => session.formID === formId && session.clientID === searchValue
    );
    setFormSearchResults((prevResults) => ({
      ...prevResults,
      [formId]: foundSessions.length > 0 ? foundSessions : "not-found",
    }));
  };

  const handleClearFormSearch = (formId) => {
    setFormSearchClientID((prevSearch) => ({
      ...prevSearch,
      [formId]: "",
    }));
    setFormSearchResults((prevResults) => ({
      ...prevResults,
      [formId]: null,
    }));
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const findSessionsForForm = (formId) => {
    return localSessions.filter((session) => session.formID === formId);
  };

  const onSessionCreated = (newSession) => {
    const userId = currentUser.uid; // Assuming getUserId() fetches the current user's ID
    incrementActiveSessionCount(userId); // Increment active session count

    setLocalSessions((prevSessions) => {
      if (
        !prevSessions.some(
          (session) => session.sessionID === newSession.sessionID
        )
      ) {
        return [...prevSessions, newSession];
      }
      return prevSessions;
    });
  };

  const handleRowClick = async (formId) => {
    const isActive = activeRow === formId;
    setActiveRow(isActive ? null : formId); // Toggle active state or close if already open

    if (!isActive) {
      await refreshStatuses(formId);
    }
  };

  const fetchSubFolders = async (retryCount = 0) => {
    try {
      const firestore = getFirestore();

      if (currentUser) {
        const subFoldersCollection = collection(
          firestore,
          "users",
          currentUser.uid,
          "subFolders"
        );
        const subFoldersSnapshot = await getDocs(subFoldersCollection);
        const subFoldersList = subFoldersSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        subFoldersList.unshift({
          id: "all-forms",
          name: "All Forms",
          forms: forms,
        });

        setLocalSubFolders(subFoldersList);
      }
    } catch (error) {
      console.error("Error fetching subfolders: ", error);

      if (retryCount < 3) {
        setTimeout(
          () => fetchSubFolders(retryCount + 1),
          Math.pow(2, retryCount) * 1000
        );
      }
    }
  };

  useEffect(() => {
    if (runTour) {
      console.log("Tour started"); // For debugging
      // If you need to do anything when the tour starts, do it here
    }
  }, [runTour]);

  useEffect(() => {
    const checkInterval = setInterval(checkElementsAndRunTour, 1000); // Check every 1 second

    return () => clearInterval(checkInterval); // Cleanup interval on component unmount
  }, [isFirstTimeTourActive, startMainTour]);

  useEffect(() => {
    const fetchUserSettings = async () => {
      if (currentUser) {
        const effectiveUserId = currentUser.mainUserId || currentUser.uid;
        const db = getFirestore();
        const userRef = doc(db, "users", effectiveUserId);

        // Fetch user document for phone number
        const userDocSnap = await getDoc(userRef);
        if (userDocSnap.exists()) {
          console.log("++++++");
          console.log(userDocSnap.data().accessPhone);
          setPhoneNumber(userDocSnap.data().accessPhone); // Using 'accessPhone' field
        } else {
          console.log("No such user document!");
        }

        // Fetch first session document for session PIN
        const sessionsRef = collection(
          db,
          "users",
          effectiveUserId,
          "sessions"
        );
        const q = query(sessionsRef, limit(1));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const firstSessionDoc = querySnapshot.docs[0];
          console.log("++++++");
          console.log(firstSessionDoc);
          setSessionPin(firstSessionDoc.data().sessionID); // Using 'sessionID' as the session PIN
        } else {
          console.log("No sessions available for this user.");
        }
      }
    };

    fetchUserSettings();
  }, [currentUser]); // Dependency array includes currentUser to re-run on change

  useEffect(() => {
    fetchSubFolders();
  }, [currentUser, forms]);

  useEffect(() => {
    if (!loading) {
      const tourCompleted = localStorage.getItem("tourCompleted") === "true";
      const firstTimeTourDone =
        localStorage.getItem("firstTimeTourCompleted") === "true";

      // Debugging outputs
      console.log("Tour Completed:", tourCompleted);
      console.log("First Time Tour Done:", firstTimeTourDone);

      const isMainUser = !currentUser.mainUserId;

      if (!firstTimeTourDone && isMainUser) {
        console.log("Activating First Time Tour");
        setIsFirstTimeTourActive(true);
      } else if (!tourCompleted) {
        console.log("Activating Main Tour");
        setIsFirstTimeTourActive(false);
        setStartMainTour(true);
      } else {
        console.log("Deactivating All Tours");
        setIsFirstTimeTourActive(false);
        setStartMainTour(false);
      }
    }
  }, [loading]);

  useEffect(() => {
    const fetchUserDetails = async () => {
      const firestore = getFirestore();

      // Determine the correct user ID to use
      const effectiveUserId = currentUser.mainUserId || currentUser.uid;

      const userRef = doc(firestore, "users", effectiveUserId);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        const accessPhone = userData.accessPhone; // Access the phone number field
        console.log("Phone Number:", accessPhone); // Use this phone number as needed in your app
        setPhoneNumber(accessPhone);
      } else {
        console.log("No user document found!");
      }
    };

    if (currentUser) {
      fetchUserDetails();
    }
  }, [currentUser]);

  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return "";
    const country = phoneNumber.substring(0, 1);
    const area = phoneNumber.substring(1, 4);
    const prefix = phoneNumber.substring(4, 7);
    const line = phoneNumber.substring(7, 11);
    return `${country} (${area}) ${prefix}-${line}`;
  };

  const firstTimeStep = [
    {
      target: "body",
      content: (
        <div
          style={{
            fontSize: "16px",
            color: "#333",
            width: "90%",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
          }}
        >
          <h2 style={{ fontSize: "1.5em", textAlign: "center" }}>
            Welcome to FormVox!
          </h2>
          <p style={{ textAlign: "justify" }}>
            Get started by experiencing our voice chatbot with a quick,
            3-question demo form. It's a great way to see how easy and effective
            our tool can be!
          </p>
          <ol style={{ paddingLeft: "20px", textAlign: "left" }}>
            <li>
              <strong>Call this number: </strong>
              {formatPhoneNumber(phoneNumber)}
            </li>
            <li>
              <strong>Enter session PIN:</strong> {sessionPin}
            </li>
            <li>
              <strong>View the transcript</strong> in <strong>All Forms</strong>
              /<strong>Example Form</strong>
            </li>
          </ol>
          <div
            style={{
              fontSize: "2em",
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            <img
              src={allFormsImage}
              alt="Example Form Directions"
              style={{ height: "150px", borderRadius: "5px" }}
            />
          </div>
          <p style={{ textAlign: "justify" }}>
            If you'd like to try it later, you can find the session PIN in{" "}
            <strong>All Forms</strong>/<strong>Example Form.</strong>
          </p>
        </div>
      ),
      placement: "center",
      disableBeacon: true,
    },
  ];

  useEffect(() => {
    if (activeRow !== null) {
      // Delay scrolling a bit until the row is fully expanded
      setTimeout(() => {
        // Scroll the active row into view inside the table
        const activeRowElement = document.querySelector(".active-row");
        if (activeRowElement) {
          activeRowElement.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
          });
        }

        // Scroll the container to be visible at the top of the viewport
        if (containerRef.current) {
          window.scrollTo({
            top:
              containerRef.current.getBoundingClientRect().top +
              window.scrollY -
              100, // Adjust 100 pixels as a buffer from the top
            behavior: "smooth",
          });
        }
      }, 300); // Adjust timing based on your animation or expansion duration
    }
  }, [activeRow]);

  // New effect for scrolling to archive section when expanded
  useEffect(() => {
    if (collapsedFolders["archive"] && archiveRef.current) {
      setTimeout(() => {
        archiveRef.current.scrollIntoView({ behavior: "smooth" });
      }, 300);
    }
  }, [collapsedFolders]);

  const handleQuickReply = (message) => {
    setInputValue(message);
    simulateSend(message);
  };

  const simulateSend = async (message) => {
    if (message.trim()) {
      const timestamp = new Date();
      const newChatLog = [...chatLog, { message, timestamp, type: "text" }];
      setChatLog(newChatLog);
      setInputValue("");
      setIsDisabled(true);
      setIsLoading(true);

      try {
        const response = await fetch(
          "https://handlemessage-cgn2j7f72a-uc.a.run.app",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ message }),
          }
        );

        const data = await response.json();
        const replyTimestamp = new Date();

        const newReplyLog = [
          ...newChatLog,
          { message: data.text, timestamp: replyTimestamp, type: "text" },
        ];

        if (data.quickReplies) {
          setQuickReplies(data.quickReplies);
        } else {
          setQuickReplies([]);
        }

        if (data.link) {
          newReplyLog.push({
            message: data.link.url,
            displayText: data.link.displayText,
            timestamp: replyTimestamp,
            type: "link",
          });
        }

        if (data.imageUrl) {
          newReplyLog.push({
            message: data.imageUrl,
            timestamp: replyTimestamp,
            type: "image",
          });
        }

        setChatLog(newReplyLog);
      } catch (error) {
        console.error("Error sending message:", error);
      }

      setIsDisabled(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (chatLogRef.current) {
      chatLogRef.current.scrollTop = chatLogRef.current.scrollHeight;
    }
  }, [chatLog]);

  useEffect(() => {
    if (!currentUser) {
      console.log("No user logged in!");
      setLoading(true);
      return;
    }

    const firestore = getFirestore();

    const getUserId = () => {
      if (
        currentUser.subUsers &&
        currentUser.subUsers.some(
          (subUser) =>
            subUser.email.toLowerCase() === currentUser.email.toLowerCase()
        )
      ) {
        return currentUser.mainUserId;
      } else {
        return currentUser.uid;
      }
    };

    const userId = getUserId();
    console.log("---> " + currentUser.uid);
    console.log(currentUser);
    const sessionCollection = collection(
      firestore,
      "users",
      userId,
      "sessions"
    );

    console.log("Setting up listener for sessions...");

    const updateLocalSessions = debounce(async (snapshot) => {
      const updatedSessions = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      console.log("Received sessions: ", updatedSessions);
      setLocalSessions(updatedSessions);

      // Group sessions by form ID
      const sessionsByForm = updatedSessions.reduce((acc, session) => {
        if (session.formID) {
          const formID = session.formID;
          if (!acc[formID]) {
            acc[formID] = [];
          }
          acc[formID].push(session);
        }
        return acc;
      }, {});

      // Fetch form names
      const formIds = Object.keys(sessionsByForm);

      const formPromises = formIds.map(async (formID) => {
        const formDoc = await getDoc(
          doc(firestore, "users", userId, "forms", formID)
        );
        if (!isLoadedComplete) return;
        return {
          formID,
          formName: formDoc.exists() ? formDoc.data().formName : "Unknown Form",
        };
      });

      const formsData = await Promise.all(formPromises);

      // Combine form data with sessions
      const allFormsData = forms.map((form) => ({
        id: form.id,
        name: form.formName,
        forms: [form],
        sessions: sessionsByForm[form.id] || [],
      }));

      const updatedSubFolders = [
        {
          id: "all-forms",
          name: "All Forms",
          forms: forms,
          sessions: updatedSessions,
        },
      ];

      setLocalSubFolders(updatedSubFolders);
      setLoading(false); // Set loading to false once data is fetched
      setIsLoadedComplete(true);
    }, 300); // 300ms debounce time

    const unsubscribe = onSnapshot(
      sessionCollection,
      updateLocalSessions,
      (error) => {
        console.error("Error fetching sessions: ", error);
        setLoading(false); // Set loading to false on error
        setIsLoadedComplete(false);
      }
    );
    if (!isLoadedComplete) return;
    return () => {
      console.log("Unsubscribing from sessions...");
      unsubscribe();
    };
  }, [currentUser, forms]);

  const formatTime = (timestamp) => {
    const year = timestamp.getFullYear();
    const month = timestamp.toLocaleString("default", { month: "short" });
    const day = timestamp.getDate().toString().padStart(2, "0");
    const hours = (timestamp.getHours() % 12 || 12).toString();
    const minutes = timestamp.getMinutes().toString().padStart(2, "0");

    const ampm = timestamp.getHours() >= 12 ? "PM" : "AM";
    return `${month} ${day}, ${year}, ${hours}:${minutes} ${ampm}`;
  };

  const formatFullDate = (timestamp) => {
    console.log("CHECK THIS ", timestamp);

    // Check if timestamp is a Firestore Timestamp object
    if (
      timestamp &&
      typeof timestamp.seconds === "number" &&
      typeof timestamp.nanoseconds === "number"
    ) {
      const date = new Date(
        timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000
      );

      const options = {
        year: "numeric",
        month: "long", // Full month name
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      };

      let formattedDate = date.toLocaleString("en-US", options);
      console.log("HERE --->" + formattedDate);
      formattedDate = formattedDate.replace(
        /(\d\d?):(\d\d)\s([AP]M)/,
        (match, p1, p2, p3) => {
          return `${parseInt(p1, 10)}:${p2} ${p3}`;
        }
      );

      return formattedDate.replace(", ", " at ");
    } else {
      // If the timestamp is not a Firestore Timestamp object, try to parse it as a date string
      const date = new Date(timestamp);
      if (!isNaN(date.getTime())) {
        const options = {
          year: "numeric",
          month: "long", // Full month name
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        };

        let formattedDate = date.toLocaleString("en-US", options);
        console.log("HERE --->" + formattedDate);
        formattedDate = formattedDate.replace(
          /(\d\d?):(\d\d)\s([AP]M)/,
          (match, p1, p2, p3) => {
            return `${parseInt(p1, 10)}:${p2} ${p3}`;
          }
        );

        return formattedDate;
      } else {
        return "Invalid Date";
      }
    }
  };

  const formatLocalDate = (timestamp) => {
    const date = timestamp.toDate();
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  const checkElementsAndRunTour = () => {
    const allElementsExist =
      document.querySelector(".content-box") &&
      document.querySelector(".toggle-sidebar-btn") &&
      document.querySelector(".subfolders-table") &&
      document.querySelector(".help-message");

    if (allElementsExist) {
      if (isFirstTimeTourActive && !firstTimeTourCompleted) {
        setIsFirstTimeTourActive(true);
      }

      if (startMainTour) {
        setStartMainTour(true);
      }

      // Clear the interval once the elements are found
      clearInterval(2000);
    }
  };

  const toggleFolder = (folderId) => {
    setCollapsedFolders((prevCollapsedFolders) => {
      const isCurrentlyOpen = prevCollapsedFolders[folderId];
      const newCollapsedFolders = {};

      // If the folder is already open, close it; otherwise, open it and close others
      if (!isCurrentlyOpen) {
        newCollapsedFolders[folderId] = true;
      }

      return newCollapsedFolders;
    });
  };

  const handleDeleteForm = (form) => {
    setFormToDelete(form);
    setShowDeleteModal(true);
  };

  const handleDeleteSubFolder = (subFolder) => {
    setSubFolderToDelete(subFolder);
    setShowDeleteModal(true);
  };

  const confirmDeleteForm = async () => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      const firestore = getFirestore();

      if (user && formToDelete) {
        const formRef = doc(
          firestore,
          "users",
          user.uid,
          "forms",
          formToDelete.id
        );

        // Get all sessions associated with the form
        const sessionCollection = collection(
          firestore,
          "users",
          user.uid,
          "sessions"
        );
        const sessionQuery = query(
          sessionCollection,
          where("formID", "==", formToDelete.id)
        );
        const sessionSnapshot = await getDocs(sessionQuery);

        const batch = writeBatch(firestore);

        // Update all sessions to set formID to null
        sessionSnapshot.docs.forEach((sessionDoc) => {
          const sessionRef = doc(
            firestore,
            "users",
            user.uid,
            "sessions",
            sessionDoc.id
          );
          batch.update(sessionRef, { formID: null });
        });

        // Delete the form
        batch.delete(formRef);

        // Commit the batch
        await batch.commit();

        toast.success("Form deleted successfully!");
        setShowDeleteModal(false);
        setLocalSubFolders((prev) =>
          prev.map((folder) =>
            folder.id === "all-forms"
              ? {
                  ...folder,
                  forms: folder.forms.filter((f) => f.id !== formToDelete.id),
                }
              : folder
          )
        );
      } else if (
        user &&
        subFolderToDelete &&
        subFolderToDelete.name !== "All Forms"
      ) {
        const subFolderRef = doc(
          firestore,
          "users",
          user.uid,
          "subFolders",
          subFolderToDelete.id
        );
        await deleteDoc(subFolderRef);
        toast.success("Subfolder deleted successfully!");
        setShowDeleteModal(false);
        onDeleteSubFolder(subFolderToDelete.id);
        setLocalSubFolders((prev) =>
          prev.filter((folder) => folder.id !== subFolderToDelete.id)
        );
      } else {
        toast.error("You must be logged in to delete the form or subfolder.");
      }
    } catch (error) {
      console.error("Error deleting form or subfolder: ", error);
      toast.error("Failed to delete the form or subfolder. Please try again.");
    }
  };

  const handleCopyToClipboard = async (sessionDetails, phoneNumber) => {
    if (navigator.clipboard && document.hasFocus()) {
      try {
        await navigator.clipboard.writeText(
          `Call ${formatPhoneNumber(phoneNumber)} and enter session PIN ${
            sessionDetails.sessionID
          } to start or continue.`
        );
        setCopyText("Copied!");
        setTimeout(() => {
          setCopyText("Copy");
        }, 3000); // Resets the text after 3 seconds
      } catch (err) {
        console.error("Failed to copy: ", err);
      }
    }
  };

  const checkFormStatus = async (sessionID) => {
    try {
      console.log("START");
      console.log(currentUser);
      const auth = getAuth();

      let user;

      const getUserId = () => {
        if (
          currentUser.subUsers &&
          currentUser.subUsers.some(
            (subUser) =>
              subUser.email.toLowerCase() === currentUser.email.toLowerCase()
          )
        ) {
          return currentUser.mainUserId;
        } else {
          console.log("this is a main user)" + JSON.stringify(auth));
          return auth.currentUser.uid;
        }
      };

      user = getUserId();

      if (!user) {
        console.error("User not authenticated");
        return { status: "incomplete" };
      }

      const firestore = getFirestore();
      const sessionRef = doc(firestore, "users", user, "sessions", sessionID);

      const sessionDoc = await getDoc(sessionRef);

      if (sessionDoc.exists()) {
        const sessionData = sessionDoc.data();
        console.log("Session data: ", sessionData);

        if (sessionData.formStatus === "complete") {
          const lastQuestion =
            sessionData.transcripts[sessionData.transcripts.length - 1];
          return {
            status: "complete",
            timestamp: formatFullDate(lastQuestion.timestamp),
          };
        } else {
          return { status: "incomplete" };
        }
      } else {
        console.error("Session document not found");
        return { status: "incomplete" };
      }
    } catch (error) {
      console.error("Error fetching session data: ", error);
      return { status: "incomplete" };
    }
  };

  const SessionDetailsModal = ({ sessionDetails, onClose }) => {
    if (!isLoadedComplete) return;

    return (
      <div className="modal-overlay">
        <div className="modal-content">
          <h2>Session Information</h2>
          <div className="success-message">
            <table className="rounded-table">
              <thead>
                <tr>
                  <th className="mobile">Session PIN</th>
                  <th className="mobile">Client ID</th>
                  <th className="mobile">Name</th>
                  <th className="mobile">Created On</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="mobile">{sessionDetails.sessionID}</td>
                  <td className="mobile">{sessionDetails.clientID}</td>
                  <td className="mobile">
                    {sessionDetails.firstName || sessionDetails.lastName
                      ? `${sessionDetails.firstName} ${sessionDetails.lastName}`.trim()
                      : ""}
                  </td>

                  <td className="mobile">
                    {new Date(
                      sessionDetails.createdAt.seconds * 1000
                    ).toLocaleDateString("en-US")}
                  </td>
                </tr>
              </tbody>
            </table>
            <p className="call-text">
              Call {formatPhoneNumber(phoneNumber)} and use the session PIN
              below to begin or continue the form:
            </p>
            <p>
              <div className="sessionId-container">
                <span className="sessionId-2">
                  {sessionDetails.sessionID}
                  <button
                    className="copy-icon"
                    onClick={() =>
                      handleCopyToClipboard(sessionDetails, phoneNumber)
                    }
                  >
                    <FontAwesomeIcon icon={faCopy} /> {copyText}
                  </button>
                </span>
              </div>
            </p>
          </div>
          <div className="button-group">
            <button
              onClick={onClose}
              className="close-btn"
              style={{ background: "red" }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    );
  };

  const refreshStatuses = async (formId) => {
    const sessionsToCheck = findSessionsForForm(formId);
    const newLoadingStatus = {};
    sessionsToCheck.forEach((session) => {
      newLoadingStatus[session.sessionID] = true;
    });
    setLoadingStatus(newLoadingStatus);

    for (const session of sessionsToCheck) {
      const result = await checkFormStatus(session.sessionID);
      setStatusData((prevState) => ({
        ...prevState,
        [session.sessionID]: result,
      }));
      setLoadingStatus((prevState) => ({
        ...prevState,
        [session.sessionID]: false,
      }));
    }
  };
  const archiveSession = async (sessionID) => {
    console.log("YOU CLICKED ARCHIVE");
    console.log(JSON.stringify(currentUser));
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      const firestore = getFirestore();

      if (!user) {
        console.error("User not authenticated");
        return;
      }

      // Determine the effective user ID to use
      const effectiveUserId = currentUser.mainUserId || user.uid;

      // Reference to the user-specific session
      const sessionRef = doc(
        firestore,
        "users",
        effectiveUserId, // Use effectiveUserId instead of user.uid
        "sessions",
        sessionID
      );

      // Reference to the global session
      const globalSessionRef = doc(firestore, "sessions", sessionID);

      // Update the local session to mark as archived
      await updateDoc(sessionRef, {
        formID: null,
        sessionID: sessionID + "-ARCHIVED",
        isArchived: true,
      });

      // Remove the session from the global sessions collection
      await deleteDoc(globalSessionRef);

      decrementActiveSessionCount(effectiveUserId); // Decrement active session count using effectiveUserId
      toast.success("Session archived successfully!");
    } catch (error) {
      console.error("Error archiving session: ", error);
      toast.error("Failed to archive the session. Please try again.");
    }
  };

  const incrementActiveSessionCount = async (userId) => {
    const firestore = getFirestore();
    const userRef = doc(firestore, "users", userId);
    await updateDoc(userRef, {
      activeSessionCount: increment(1),
    });
  };

  const decrementActiveSessionCount = async (userId) => {
    const firestore = getFirestore();
    const userRef = doc(firestore, "users", userId);
    await updateDoc(userRef, {
      activeSessionCount: increment(-1),
    });
  };

  const [showSessionDetailsModal, setShowSessionDetailsModal] = useState(false);
  const [currentSessionDetails, setCurrentSessionDetails] = useState(null);

  const handleViewSessionDetails = (session) => {
    setCurrentSessionDetails(session);
    setShowSessionDetailsModal(true);
  };

  const confirmDeleteArchiveSession = async () => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      const firestore = getFirestore();

      if (!user) {
        console.error("User not authenticated");
        return;
      }

      // Determine the effective user ID to use
      const effectiveUserId = currentUser.mainUserId || user.uid;

      if (archiveSessionToDelete) {
        const sessionsRef = collection(
          firestore,
          "users",
          effectiveUserId, // Use effectiveUserId instead of user.uid
          "sessions"
        );
        const queryRef = query(
          sessionsRef,
          where("sessionID", "==", archiveSessionToDelete.sessionID),
          where("createdAt", "==", archiveSessionToDelete.createdAt) // Ensure you format this timestamp as it is stored in Firestore
        );
        const querySnapshot = await getDocs(queryRef);

        if (!querySnapshot.empty) {
          const docRef = querySnapshot.docs[0].ref;
          await deleteDoc(docRef);
          toast.success("Session deleted successfully!");
          setShowArchiveModal(false);
        } else {
          toast.error("Session not found for deletion.");
        }
      } else {
        toast.error("No session selected for deletion.");
      }
    } catch (error) {
      console.error("Error deleting archived session: ", error);
      toast.error("Failed to delete the session. Please try again.");
    }
  };

  const renderSubDirs = () => {
    console.log("RENDER STEP");
    const handleFormSearchInputChange = (formId, value) => {
      setFormSearchInputs((prev) => ({
        ...prev,
        [formId]: value,
      }));
    };

    const handleFormSearch = (formId) => {
      const searchClientID = formSearchInputs[formId];
      const foundSessions = localSessions.filter(
        (session) =>
          session.clientID === searchClientID && session.formID === formId
      );
      setFormSearchResults((prev) => ({
        ...prev,
        [formId]: foundSessions.length > 0 ? foundSessions : "not-found",
      }));
    };

    const handleFormClearSearch = (formId) => {
      setFormSearchInputs((prev) => ({
        ...prev,
        [formId]: "",
      }));
      setFormSearchResults((prev) => ({
        ...prev,
        [formId]: null,
      }));
    };

    if (localSubFolders.length === 0) {
      if (!isLoadedComplete) return;

      return (
        <div>
          <table className="subfolders-table" ref={tableRef}>
            <thead>
              <tr>
                <th>Forms</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div
                    className="all-forms"
                    onClick={() => toggleFolder("all-forms")}
                  >
                    <FontAwesomeIcon
                      icon={
                        collapsedFolders["all-forms"]
                          ? faChevronUp
                          : faChevronDown
                      }
                    />
                    <FontAwesomeIcon icon={faFolderOpen} /> All Forms
                  </div>
                  {collapsedFolders["all-forms"] && (
                    <table className="inner-table">
                      <thead>
                        <tr>
                          <th>Form Name</th>
                          <th>Created On</th>
                        </tr>
                      </thead>
                      <tbody>
                        {forms.length > 0 ? (
                          forms
                            .slice()
                            .sort((a, b) => b.createdOn - a.createdOn)
                            .map((form) => (
                              <React.Fragment key={form.id}>
                                <tr
                                  onClick={() => handleRowClick(form.id)}
                                  className={
                                    activeRow === form.id ? "active-row" : ""
                                  }
                                >
                                  <td>
                                    <FontAwesomeIcon icon={faFileAlt} />{" "}
                                    {form.formName}
                                    <FontAwesomeIcon
                                      icon={
                                        activeRow === form.id
                                          ? faChevronUp
                                          : faChevronDown
                                      }
                                      className="toggle-icon"
                                      onClick={() => handleRowClick(form.id)}
                                    />
                                    <div className="form-icons">
                                      <FontAwesomeIcon
                                        icon={faEdit}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          onShowEditFormModal(form);
                                        }}
                                        className="edit-icon"
                                      />
                                      <FontAwesomeIcon
                                        icon={faTrashAlt}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleDeleteForm(form);
                                        }}
                                        className="delete-icon"
                                      />
                                    </div>
                                  </td>
                                  <td>{formatLocalDate(form.createdOn)}</td>
                                </tr>
                                {activeRow === form.id && (
                                  <tr className="accordion-content">
                                    <td colSpan="2">
                                      <div>
                                        <div className="search-container">
                                          <input
                                            type="text"
                                            placeholder="Filter by Client ID"
                                            value={
                                              formSearchInputs[form.id] || ""
                                            }
                                            onChange={(e) =>
                                              handleFormSearchInputChange(
                                                form.id,
                                                e.target.value
                                              )
                                            }
                                          />
                                          <button
                                            onClick={() =>
                                              handleFormSearch(form.id)
                                            }
                                          >
                                            Filter
                                          </button>
                                          <button
                                            onClick={() =>
                                              handleFormClearSearch(form.id)
                                            }
                                          >
                                            Clear
                                          </button>
                                        </div>
                                        <table className="inner-table">
                                          <thead>
                                            <tr>
                                              <th className="mobile">
                                                Session PIN
                                              </th>
                                              <th className="mobile">
                                                Client ID
                                              </th>
                                              <th className="mobile">Name</th>
                                              <th className="mobile">
                                                Created On
                                              </th>
                                              <th className="mobile">
                                                Status{" "}
                                                <FontAwesomeIcon
                                                  icon={faSyncAlt}
                                                  onClick={() =>
                                                    refreshStatuses(form.id)
                                                  }
                                                  style={{
                                                    cursor: "pointer",
                                                    marginLeft: "5px",
                                                  }}
                                                />
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {formSearchResults[form.id] &&
                                            formSearchResults[form.id] !==
                                              "not-found" ? (
                                              Array.isArray(
                                                formSearchResults[form.id]
                                              ) ? (
                                                formSearchResults[form.id].map(
                                                  (result) => (
                                                    <tr
                                                      key={result.sessionID}
                                                      className="pale-color"
                                                    >
                                                      <td className="mobile">
                                                        <button
                                                          className="session-id-button"
                                                          onClick={() =>
                                                            handleViewSessionDetails(
                                                              result
                                                            )
                                                          }
                                                          style={{
                                                            background: "none",
                                                            border: "none",
                                                            padding: 0,
                                                            color: "blue",
                                                            textDecoration:
                                                              "underline",
                                                            cursor: "pointer",
                                                          }}
                                                        >
                                                          {result.sessionID}
                                                        </button>
                                                        <br />
                                                        <button
                                                          className="archive-button"
                                                          onClick={() =>
                                                            archiveSession(
                                                              result.sessionID
                                                            )
                                                          }
                                                        >
                                                          <FontAwesomeIcon
                                                            icon={faArchive}
                                                          />{" "}
                                                          Archive
                                                        </button>
                                                      </td>

                                                      <td className="mobile">
                                                        {result.clientID}
                                                      </td>
                                                      <td className="mobile">
                                                        {result.firstName &&
                                                        result.lastName
                                                          ? `${result.firstName} ${result.lastName}`
                                                          : ""}
                                                      </td>
                                                      <td className="mobile">
                                                        {new Date(
                                                          result.createdAt
                                                            .seconds * 1000
                                                        ).toLocaleDateString(
                                                          "en-US"
                                                        )}
                                                      </td>
                                                      <td className="mobile">
                                                        {result.formStatus ===
                                                        "complete" ? (
                                                          <>
                                                            Completed:{" "}
                                                            {formatFullDate(
                                                              result.createdAt
                                                            )}
                                                            <br />
                                                            <DownloadDropdown
                                                              session={result}
                                                              generateCSV={
                                                                generateCSV
                                                              }
                                                              generatePDF={
                                                                generatePDF
                                                              }
                                                            />
                                                          </>
                                                        ) : (
                                                          "In Progress"
                                                        )}
                                                      </td>
                                                    </tr>
                                                  )
                                                )
                                              ) : (
                                                <tr className="pale-color">
                                                  <td>
                                                    <td className="mobile">
                                                      <button
                                                        className="session-id-button"
                                                        onClick={() =>
                                                          handleViewSessionDetails(
                                                            formSearchResults[
                                                              form.id
                                                            ]
                                                          )
                                                        }
                                                        style={{
                                                          background: "none",
                                                          border: "none",
                                                          padding: 0,
                                                          color: "blue",
                                                          textDecoration:
                                                            "underline",
                                                          cursor: "pointer",
                                                        }}
                                                      >
                                                        {
                                                          formSearchResults[
                                                            form.id
                                                          ].sessionID
                                                        }
                                                      </button>
                                                      <br />
                                                      <button
                                                        className="archive-button"
                                                        onClick={() =>
                                                          archiveSession(
                                                            formSearchResults[
                                                              form.id
                                                            ].sessionID
                                                          )
                                                        }
                                                      >
                                                        <FontAwesomeIcon
                                                          icon={faArchive}
                                                        />{" "}
                                                        Archive
                                                      </button>
                                                    </td>

                                                    <br />
                                                    <button
                                                      className="archive-button"
                                                      onClick={() =>
                                                        archiveSession(
                                                          formSearchResults[
                                                            form.id
                                                          ].sessionID
                                                        )
                                                      }
                                                    >
                                                      <FontAwesomeIcon
                                                        icon={faArchive}
                                                      />{" "}
                                                      Archive
                                                    </button>
                                                  </td>
                                                  <td className="mobile">
                                                    {
                                                      formSearchResults[form.id]
                                                        .clientID
                                                    }
                                                  </td>
                                                  <td className="mobile">
                                                    {formSearchResults[form.id]
                                                      .firstName &&
                                                    formSearchResults[form.id]
                                                      .lastName
                                                      ? `${
                                                          formSearchResults[
                                                            form.id
                                                          ].firstName
                                                        } ${
                                                          formSearchResults[
                                                            form.id
                                                          ].lastName
                                                        }`
                                                      : ""}
                                                  </td>
                                                  <td>
                                                    {new Date(
                                                      formSearchResults[form.id]
                                                        .createdAt.seconds *
                                                        1000
                                                    ).toLocaleDateString(
                                                      "en-US"
                                                    )}
                                                  </td>
                                                  <td className="mobile">
                                                    {formSearchResults[form.id]
                                                      .formStatus ===
                                                    "complete" ? (
                                                      <>
                                                        Completed:{" "}
                                                        {formatFullDate(
                                                          formSearchResults[
                                                            form.id
                                                          ].createdAt
                                                        )}
                                                        <br />
                                                        <DownloadDropdown
                                                          session={
                                                            formSearchResults[
                                                              form.id
                                                            ]
                                                          }
                                                          generateCSV={
                                                            generateCSV
                                                          }
                                                          generatePDF={
                                                            generatePDF
                                                          }
                                                        />
                                                      </>
                                                    ) : (
                                                      "In Progress"
                                                    )}
                                                  </td>
                                                </tr>
                                              )
                                            ) : formSearchResults[form.id] ===
                                              "not-found" ? (
                                              <tr>
                                                <td
                                                  colSpan="5"
                                                  style={{ color: "red" }}
                                                >
                                                  Client ID not found
                                                </td>
                                              </tr>
                                            ) : findSessionsForForm(form.id)
                                                .length > 0 ? (
                                              findSessionsForForm(form.id).map(
                                                (session) => (
                                                  <tr key={session.sessionID}>
                                                    <td className="mobile">
                                                      <td className="mobile">
                                                        <button
                                                          className="session-id-button"
                                                          onClick={() =>
                                                            handleViewSessionDetails(
                                                              session
                                                            )
                                                          }
                                                          style={{
                                                            background: "none",
                                                            border: "none",
                                                            padding: 0,
                                                            color: "blue",
                                                            textDecoration:
                                                              "underline",
                                                            cursor: "pointer",
                                                          }}
                                                        >
                                                          {session.sessionID}
                                                        </button>
                                                        <br />
                                                        <button
                                                          className="archive-button"
                                                          onClick={() =>
                                                            archiveSession(
                                                              session.sessionID
                                                            )
                                                          }
                                                        >
                                                          <FontAwesomeIcon
                                                            icon={faArchive}
                                                          />{" "}
                                                          Archive
                                                        </button>
                                                      </td>

                                                      <br />
                                                      <button
                                                        className="archive-button"
                                                        onClick={() =>
                                                          archiveSession(
                                                            session.sessionID
                                                          )
                                                        }
                                                      >
                                                        <FontAwesomeIcon
                                                          icon={faArchive}
                                                        />{" "}
                                                        Archive
                                                      </button>
                                                    </td>
                                                    <td className="mobile">
                                                      {session.clientID}
                                                    </td>
                                                    <td className="mobile">
                                                      {session.firstName &&
                                                      session.lastName
                                                        ? `${session.firstName} ${session.lastName}`
                                                        : ""}
                                                    </td>
                                                    <td className="mobile">
                                                      {new Date(
                                                        session.createdAt
                                                          .seconds * 1000
                                                      ).toLocaleDateString(
                                                        "en-US"
                                                      )}
                                                    </td>
                                                    <td className="mobile">
                                                      {loadingStatus[
                                                        session.sessionID
                                                      ] ? (
                                                        <Spinner />
                                                      ) : (
                                                        <>
                                                          {session.transcripts &&
                                                          session.transcripts
                                                            .length > 0 &&
                                                          session.formStatus !==
                                                            "complete" ? (
                                                            <>
                                                              In Progress
                                                              (Question #
                                                              {
                                                                session
                                                                  .transcripts
                                                                  .length
                                                              }
                                                              )
                                                              <br />
                                                              <DownloadDropdown
                                                                session={
                                                                  session
                                                                }
                                                                generateCSV={
                                                                  generateCSV
                                                                }
                                                                generatePDF={
                                                                  generatePDF
                                                                }
                                                              />
                                                            </>
                                                          ) : session.formStatus ===
                                                            "complete" ? (
                                                            <>
                                                              Completed
                                                              <br />
                                                              <DownloadDropdown
                                                                session={
                                                                  session
                                                                }
                                                                generateCSV={
                                                                  generateCSV
                                                                }
                                                                generatePDF={
                                                                  generatePDF
                                                                }
                                                              />
                                                            </>
                                                          ) : (
                                                            "Not Yet Started"
                                                          )}
                                                        </>
                                                      )}
                                                    </td>
                                                  </tr>
                                                )
                                              )
                                            ) : (
                                              <tr>
                                                <td colSpan="5">
                                                  No sessions available for this
                                                  form.{" "}
                                                  <button
                                                    onClick={() =>
                                                      setShowStartNewVoiceForm(
                                                        true
                                                      )
                                                    }
                                                    style={{
                                                      background: "none",
                                                      border: "none",
                                                      color: "blue",
                                                      cursor: "pointer",
                                                      padding: 0,
                                                      textDecoration:
                                                        "underline",
                                                    }}
                                                  >
                                                    Create one here.
                                                  </button>
                                                </td>
                                              </tr>
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </React.Fragment>
                            ))
                        ) : (
                          <tr>
                            <td colSpan="2">No forms available</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    }

    const sortedSubFolders = localSubFolders.sort((a, b) => {
      if (a.name === "All Forms") return -1;
      if (b.name === "All Forms") return 1;
      return a.name.localeCompare(b.name);
    });

    if (!isLoadedComplete) return;
    return (
      <>
        {selectedSubFolders.length > 0 && (
          <div className="subfolder-actions">
            {selectedSubFolders.length === 1 && (
              <button
                className="view-btn"
                onClick={() => toggleFolder(selectedSubFolders[0])}
              >
                <FontAwesomeIcon icon={faEye} /> View
              </button>
            )}
            <button
              className="delete-btn"
              onClick={() =>
                handleDeleteSubFolder(
                  localSubFolders.find(
                    (subFolder) => subFolder.id === selectedSubFolders[0]
                  )
                )
              }
              disabled={selectedSubFolders.includes("all-forms")}
            >
              <FontAwesomeIcon icon={faTrashAlt} /> Delete
            </button>
          </div>
        )}
        <table className="subfolders-table">
          <thead>
            <tr>
              <th>Forms</th>
            </tr>
          </thead>
          <tbody>
            {sortedSubFolders.map((subDir) => (
              <tr key={subDir.id}>
                <td>
                  <div onClick={() => toggleFolder(subDir.id)}>
                    <FontAwesomeIcon
                      icon={
                        collapsedFolders[subDir.id]
                          ? faChevronUp
                          : faChevronDown
                      }
                    />
                    <FontAwesomeIcon icon={faFolderOpen} /> {subDir.name}
                  </div>
                  {collapsedFolders[subDir.id] && (
                    <table className="inner-table">
                      <thead>
                        <tr>
                          <th>Form Name</th>
                          <th>Created On</th>
                        </tr>
                      </thead>
                      <tbody>
                        {subDir.forms.map((form) => (
                          <React.Fragment key={form.id}>
                            <tr
                              onClick={() => handleRowClick(form.id)}
                              className={
                                activeRow === form.id ? "active-row" : ""
                              }
                            >
                              <td>
                                <FontAwesomeIcon icon={faFileAlt} />{" "}
                                {form.formName}
                                <FontAwesomeIcon
                                  icon={
                                    activeRow === form.id
                                      ? faChevronUp
                                      : faChevronDown
                                  }
                                  className="toggle-icon"
                                  onClick={() => handleRowClick(form.id)}
                                />
                                <div className="form-icons">
                                  <FontAwesomeIcon
                                    icon={faEdit}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      onShowEditFormModal(form);
                                    }}
                                    className="edit-icon"
                                  />
                                  <FontAwesomeIcon
                                    icon={faTrashAlt}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleDeleteForm(form);
                                    }}
                                    className="delete-icon"
                                  />
                                </div>
                              </td>
                              <td>{formatLocalDate(form.createdOn)}</td>
                            </tr>
                            {activeRow === form.id && (
                              <tr className="accordion-content">
                                <td colSpan="2">
                                  <div>
                                    <div className="search-container">
                                      <input
                                        type="text"
                                        placeholder="Filter by Client ID"
                                        value={formSearchInputs[form.id] || ""}
                                        onChange={(e) =>
                                          handleFormSearchInputChange(
                                            form.id,
                                            e.target.value
                                          )
                                        }
                                      />
                                      <button
                                        onClick={() =>
                                          handleFormSearch(form.id)
                                        }
                                      >
                                        Filter
                                      </button>
                                      <button
                                        onClick={() =>
                                          handleFormClearSearch(form.id)
                                        }
                                      >
                                        Clear
                                      </button>
                                    </div>
                                    <table className="inner-table">
                                      <thead>
                                        <tr>
                                          <th className="mobile">
                                            Session PIN
                                          </th>
                                          <th className="mobile">Client ID</th>
                                          <th className="mobile">Name</th>
                                          <th className="mobile">Created On</th>
                                          <th className="mobile">
                                            Status{" "}
                                            <FontAwesomeIcon
                                              icon={faSyncAlt}
                                              onClick={() =>
                                                refreshStatuses(form.id)
                                              }
                                              style={{
                                                cursor: "pointer",
                                                marginLeft: "5px",
                                              }}
                                            />
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {formSearchResults[form.id] &&
                                        formSearchResults[form.id] !==
                                          "not-found" ? (
                                          Array.isArray(
                                            formSearchResults[form.id]
                                          ) ? (
                                            formSearchResults[form.id].map(
                                              (result) => (
                                                <tr
                                                  key={result.sessionID}
                                                  className="pale-color"
                                                >
                                                  <td className="mobile">
                                                    <button
                                                      className="session-id-button"
                                                      onClick={() =>
                                                        handleViewSessionDetails(
                                                          result
                                                        )
                                                      }
                                                      style={{
                                                        background: "none",
                                                        border: "none",
                                                        padding: 0,
                                                        color: "blue",
                                                        textDecoration:
                                                          "underline",
                                                        cursor: "pointer",
                                                      }}
                                                    >
                                                      {result.sessionID}
                                                    </button>

                                                    <br />
                                                    <button
                                                      className="archive-button"
                                                      onClick={() =>
                                                        archiveSession(
                                                          result.sessionID
                                                        )
                                                      }
                                                    >
                                                      <FontAwesomeIcon
                                                        icon={faArchive}
                                                      />{" "}
                                                      Archive
                                                    </button>
                                                  </td>
                                                  <td className="mobile">
                                                    {result.clientID}
                                                  </td>
                                                  <td className="mobile">
                                                    {result.firstName &&
                                                    result.lastName
                                                      ? `${result.firstName} ${result.lastName}`
                                                      : ""}
                                                  </td>
                                                  <td className="mobile">
                                                    {new Date(
                                                      result.createdAt.seconds *
                                                        1000
                                                    ).toLocaleDateString(
                                                      "en-US"
                                                    )}
                                                  </td>
                                                  <td className="mobile">
                                                    {result.formStatus ===
                                                    "complete" ? (
                                                      <>
                                                        Completed:{" "}
                                                        {formatFullDate(
                                                          result.createdAt
                                                        )}
                                                        <br />
                                                        <DownloadDropdown
                                                          session={result}
                                                          generateCSV={
                                                            generateCSV
                                                          }
                                                          generatePDF={
                                                            generatePDF
                                                          }
                                                        />
                                                      </>
                                                    ) : (
                                                      "In Progress"
                                                    )}
                                                  </td>
                                                </tr>
                                              )
                                            )
                                          ) : (
                                            <tr className="pale-color">
                                              <td className="mobile">
                                                {
                                                  formSearchResults[form.id]
                                                    .sessionID
                                                }

                                                <br />
                                                <button
                                                  className="archive-button"
                                                  onClick={() =>
                                                    archiveSession(
                                                      formSearchResults[form.id]
                                                        .sessionID
                                                    )
                                                  }
                                                >
                                                  <FontAwesomeIcon
                                                    icon={faArchive}
                                                  />{" "}
                                                  Archive
                                                </button>
                                              </td>
                                              <td className="mobile">
                                                {
                                                  formSearchResults[form.id]
                                                    .clientID
                                                }
                                              </td>
                                              <td className="mobile">
                                                {formSearchResults[form.id]
                                                  .firstName &&
                                                formSearchResults[form.id]
                                                  .lastName
                                                  ? `${
                                                      formSearchResults[form.id]
                                                        .firstName
                                                    } ${
                                                      formSearchResults[form.id]
                                                        .lastName
                                                    }`
                                                  : ""}
                                              </td>
                                              <td>
                                                {new Date(
                                                  formSearchResults[form.id]
                                                    .createdAt.seconds * 1000
                                                ).toLocaleDateString("en-US")}
                                              </td>
                                              <td className="mobile">
                                                {formSearchResults[form.id]
                                                  .formStatus === "complete" ? (
                                                  <>
                                                    Completed:{" "}
                                                    {formatFullDate(
                                                      formSearchResults[form.id]
                                                        .createdAt
                                                    )}
                                                    <br />
                                                    <DownloadDropdown
                                                      session={
                                                        formSearchResults[
                                                          form.id
                                                        ]
                                                      }
                                                      generateCSV={generateCSV}
                                                      generatePDF={generatePDF}
                                                    />
                                                  </>
                                                ) : (
                                                  "In Progress"
                                                )}
                                              </td>
                                            </tr>
                                          )
                                        ) : formSearchResults[form.id] ===
                                          "not-found" ? (
                                          <tr>
                                            <td
                                              colSpan="5"
                                              style={{ color: "red" }}
                                            >
                                              Client ID not found
                                            </td>
                                          </tr>
                                        ) : findSessionsForForm(form.id)
                                            .length > 0 ? (
                                          findSessionsForForm(form.id).map(
                                            (session) => (
                                              <tr key={session.sessionID}>
                                                <td>
                                                  <button
                                                    className="session-id-button"
                                                    onClick={() =>
                                                      handleViewSessionDetails(
                                                        session
                                                      )
                                                    }
                                                    style={{
                                                      background: "none",
                                                      border: "none",
                                                      padding: 0,
                                                      color: "blue",
                                                      textDecoration:
                                                        "underline",
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    {session.sessionID}
                                                  </button>
                                                  <br />
                                                  <button
                                                    className="archive-button"
                                                    onClick={() =>
                                                      archiveSession(
                                                        session.sessionID
                                                      )
                                                    }
                                                  >
                                                    <FontAwesomeIcon
                                                      icon={faArchive}
                                                    />{" "}
                                                    Archive
                                                  </button>
                                                </td>
                                                <td className="mobile">
                                                  {session.clientID}
                                                </td>
                                                <td className="mobile">
                                                  {session.firstName &&
                                                  session.lastName
                                                    ? `${session.firstName} ${session.lastName}`
                                                    : ""}
                                                </td>
                                                <td className="mobile">
                                                  {new Date(
                                                    session.createdAt.seconds *
                                                      1000
                                                  ).toLocaleDateString("en-US")}
                                                </td>
                                                <td className="mobile">
                                                  {loadingStatus[
                                                    session.sessionID
                                                  ] ? (
                                                    <Spinner />
                                                  ) : (
                                                    <>
                                                      {session.transcripts &&
                                                      session.transcripts
                                                        .length > 0 &&
                                                      session.formStatus !==
                                                        "complete" ? (
                                                        <>
                                                          In Progress (Question
                                                          #
                                                          {
                                                            session.transcripts
                                                              .length
                                                          }
                                                          )
                                                          <br />
                                                          <DownloadDropdown
                                                            session={session}
                                                            generateCSV={
                                                              generateCSV
                                                            }
                                                            generatePDF={
                                                              generatePDF
                                                            }
                                                          />
                                                        </>
                                                      ) : session.formStatus ===
                                                        "complete" ? (
                                                        <>
                                                          Completed
                                                          <br />
                                                          <DownloadDropdown
                                                            session={session}
                                                            generateCSV={
                                                              generateCSV
                                                            }
                                                            generatePDF={
                                                              generatePDF
                                                            }
                                                          />
                                                        </>
                                                      ) : (
                                                        "Not Yet Started"
                                                      )}
                                                    </>
                                                  )}
                                                </td>
                                              </tr>
                                            )
                                          )
                                        ) : (
                                          <tr>
                                            <td colSpan="5">
                                              No sessions available for this
                                              form.{" "}
                                              <button
                                                onClick={() =>
                                                  setShowStartNewVoiceForm(true)
                                                }
                                                style={{
                                                  background: "none",
                                                  border: "none",
                                                  color: "blue",
                                                  cursor: "pointer",
                                                  padding: 0,
                                                  textDecoration: "underline",
                                                  fontSize: "medium",
                                                }}
                                              >
                                                Create one here.
                                              </button>
                                            </td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </React.Fragment>
                        ))}
                      </tbody>
                    </table>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  };
  const renderArchive = () => {
    const archivedSessions = localSessions.filter(
      (session) => session.formID === null
    );

    const sortedArchivedSessions = [...archivedSessions].sort((a, b) => {
      if (sortConfig.key === "createdAt") {
        const dateA =
          a.createdAt.seconds * 1000 + a.createdAt.nanoseconds / 1000000;
        const dateB =
          b.createdAt.seconds * 1000 + b.createdAt.nanoseconds / 1000000;
        return sortConfig.direction === "ascending"
          ? dateA - dateB
          : dateB - dateA;
      } else if (sortConfig.key === "clientID") {
        return sortConfig.direction === "ascending"
          ? a.clientID.localeCompare(b.clientID)
          : b.clientID.localeCompare(a.clientID);
      }
      return 0;
    });

    const matchingSessions = sortedArchivedSessions.filter(
      (session) => session.clientID === searchClientID
    );
    if (!isLoadedComplete) return;

    return (
      <div>
        <table className="archive-table" ref={archiveRef}>
          <thead>
            <tr>
              <th>Archive</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div onClick={() => toggleFolder("archive")}>
                  <FontAwesomeIcon
                    icon={
                      collapsedFolders["archive"] ? faChevronUp : faChevronDown
                    }
                  />
                  <FontAwesomeIcon icon={faFolderOpen} /> Archive
                </div>
                {collapsedFolders["archive"] && (
                  <div>
                    <div className="sort-buttons">
                      <button onClick={() => handleSort("createdAt")}>
                        Sort by Date{" "}
                        {sortConfig.key === "createdAt" &&
                          (sortConfig.direction === "ascending" ? "▲" : "▼")}
                      </button>
                      <button onClick={() => handleSort("clientID")}>
                        Sort by Client ID{" "}
                        {sortConfig.key === "clientID" &&
                          (sortConfig.direction === "ascending" ? "▲" : "▼")}
                      </button>
                    </div>
                    <div className="search-container">
                      <input
                        type="text"
                        placeholder="Filter by Client ID"
                        value={searchClientID}
                        onChange={(e) => setSearchClientID(e.target.value)}
                      />
                      <button onClick={handleSearch}>Filter</button>
                      <button onClick={handleClearSearch}>Clear</button>
                    </div>
                    <table className="inner-table">
                      <thead>
                        <tr>
                          <th className="mobile">Session PIN</th>
                          <th className="mobile">Client ID</th>
                          <th className="mobile">Created On</th>
                          <th className="mobile">Status</th>
                          <th className="mobile">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {matchingSessions.length > 0 ? (
                          matchingSessions.map((session) => (
                            <tr key={session.sessionID} className="pale-color">
                              <td className="mobile">{session.sessionID}</td>
                              <td className="mobile">{session.clientID}</td>
                              <td className="mobile">
                                {new Date(
                                  session.createdAt.seconds * 1000
                                ).toLocaleDateString("en-US")}
                              </td>
                              <td className="mobile">
                                {loadingStatus[session.sessionID] ? (
                                  <Spinner />
                                ) : (
                                  <>
                                    {session.transcripts &&
                                    session.transcripts.length > 0 &&
                                    session.formStatus !== "complete" ? (
                                      <>
                                        In Progress (Question #
                                        {session.transcripts.length})
                                        <br />
                                        <DownloadDropdown
                                          session={session}
                                          generateCSV={generateCSV}
                                          generatePDF={generatePDF}
                                        />
                                      </>
                                    ) : session.formStatus === "complete" ? (
                                      <>
                                        Completed
                                        <br />
                                        <DownloadDropdown
                                          session={session}
                                          generateCSV={generateCSV}
                                          generatePDF={generatePDF}
                                        />
                                      </>
                                    ) : (
                                      "Not Yet Started"
                                    )}
                                  </>
                                )}
                              </td>

                              <td className="mobile">
                                <button
                                  onClick={() => {
                                    setArchiveSessionToDelete(session);
                                    setShowArchiveModal(true);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faTrashAlt} /> Delete
                                </button>
                              </td>
                            </tr>
                          ))
                        ) : searchResult === "not-found" ? (
                          <tr>
                            <td colSpan="5" style={{ color: "red" }}>
                              Client ID not found
                            </td>
                          </tr>
                        ) : (
                          sortedArchivedSessions.map((session) => (
                            <tr key={session.sessionID}>
                              <td>{session.sessionID}</td>
                              <td>{session.clientID}</td>
                              <td>
                                {new Date(
                                  session.createdAt.seconds * 1000
                                ).toLocaleDateString("en-US")}
                              </td>
                              <td>
                                {loadingStatus[session.sessionID] ? (
                                  <Spinner />
                                ) : (
                                  <>
                                    {session.transcripts &&
                                    session.transcripts.length > 0 &&
                                    session.formStatus !== "complete" ? (
                                      <>
                                        In Progress (Question #
                                        {session.transcripts.length})
                                        <br />
                                        <DownloadDropdown
                                          session={session}
                                          generateCSV={generateCSV}
                                          generatePDF={generatePDF}
                                        />
                                      </>
                                    ) : session.formStatus === "complete" ? (
                                      <>
                                        Completed
                                        <br />
                                        <DownloadDropdown
                                          session={session}
                                          generateCSV={generateCSV}
                                          generatePDF={generatePDF}
                                        />
                                      </>
                                    ) : (
                                      "Not Yet Started"
                                    )}
                                  </>
                                )}
                              </td>

                              <td>
                                <button
                                  onClick={() => {
                                    setArchiveSessionToDelete(session);
                                    setShowArchiveModal(true);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faTrashAlt} /> Delete
                                </button>
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };
  if (!isLoadedComplete) return;
  return (
    <div className={`chat-container ${isSidebarOpen ? "sidebar-open" : ""}`}>
      {loading ? (
        <div className="loading-message">Loading...</div>
      ) : (
        <>
          {!showAccountNameModal && phoneNumber && sessionPin ? (
            <>
              <div>
                <div>
                  <Joyride
                    continuous
                    run={isFirstTimeTourActive && !firstTimeTourCompleted}
                    steps={firstTimeStep}
                    locale={locale}
                    callback={handleFirstTimeTourCallback}
                    styles={{ options: { zIndex: 30000 } }}
                  />
                </div>
                <div>
                  <Joyride
                    continuous
                    run={startMainTour}
                    steps={steps}
                    callback={handleMainTourCallback}
                    styles={{ options: { zIndex: 1000 } }}
                  />
                </div>
              </div>
              <div>
                <>
                  {runTour && (
                    <Joyride
                      continuous
                      run={runTour}
                      steps={steps} // Make sure `steps` is properly defined
                      callback={handleMainTourCallback}
                      styles={{ options: { zIndex: 1000 } }}
                    />
                  )}
                </>
                {
                  // Check that all targeted elements by Joyride are present in the DOM
                  document.querySelector(".content-box") &&
                    document.querySelector(".toggle-sidebar-btn") &&
                    document.querySelector(".subfolders-table") &&
                    document.querySelector(".help-message") && (
                      <Joyride
                        continuous
                        run={startMainTour}
                        steps={steps}
                        callback={handleMainTourCallback}
                        styles={{ options: { zIndex: 1000 } }}
                      />
                    )
                }
              </div>
              <div
                className={`toggle-container ${
                  isSidebarOpen ? "sidebar-open" : ""
                }`}
              ></div>
              <div className="standard-mode-content">
                <div
                  className={`button-container ${
                    isSidebarOpen ? "sidebar-open" : ""
                  }`}
                >
                  <div
                    className={`content-box ${
                      isSidebarOpen ? "sidebar-open" : ""
                    }`}
                    onClick={onShowCreateFormModal}
                    style={{ backgroundColor: "#28a745", color: "#fff" }} // Green button
                  >
                    <FontAwesomeIcon
                      icon={faPlusSquare}
                      style={{ marginRight: "4px" }}
                    />
                    Setup New Form
                  </div>

                  <div
                    className={`content-box ${
                      isSidebarOpen ? "sidebar-open" : ""
                    }`}
                    onClick={() => setShowStartNewVoiceForm(true)}
                    style={{ backgroundColor: "#007bff", color: "#fff" }} // Blue button
                  >
                    <FontAwesomeIcon
                      icon={faMicrophone}
                      style={{ marginRight: "2px" }}
                    />
                    Create Form Session
                  </div>
                </div>

                <div
                  className={`content-section-chat-input ${
                    isSidebarOpen ? "sidebar-open" : ""
                  }`}
                >
                  {renderSubDirs()}
                </div>
                <div
                  className={`content-section-chat-input ${
                    isSidebarOpen ? "sidebar-open" : ""
                  }`}
                >
                  {renderArchive()}
                </div>
              </div>
              <div>
                {showDeleteModal && (
                  <ConfirmationModal
                    message={`Are you sure you want to delete ${
                      formToDelete ? "this form" : "this subfolder"
                    }?`}
                    additionalText="Sessions associated with this form will not be deleted and will be moved to Archive."
                    onClose={() => setShowDeleteModal(false)}
                    onConfirm={confirmDeleteForm}
                  />
                )}
              </div>

              {showSessionDetailsModal && (
                <SessionDetailsModal
                  sessionDetails={currentSessionDetails}
                  onClose={() => setShowSessionDetailsModal(false)}
                />
              )}
              {showStartNewVoiceForm && (
                <div className="modal-background">
                  <StartNewVoiceForm
                    onClose={() => setShowStartNewVoiceForm(false)}
                    forms={forms}
                    onSessionCreated={onSessionCreated}
                  />
                </div>
              )}
              {showDeleteModal && (
                <ConfirmationModal
                  message={`Are you sure you want to delete ${
                    formToDelete ? "this form" : "this subfolder"
                  }?`}
                  additionalText="Sessions associated with this form will not be deleted and will be moved to Archive."
                  onClose={() => setShowDeleteModal(false)}
                  onConfirm={confirmDeleteForm}
                />
              )}
              {showArchiveModal && archiveSessionToDelete && (
                <ConfirmationModal
                  message={`Are you sure you want to delete this archived session for Client ID: ${archiveSessionToDelete.clientID}?`}
                  additionalText="This action cannot be undone."
                  onClose={() => setShowArchiveModal(false)}
                  onConfirm={confirmDeleteArchiveSession}
                />
              )}
            </>
          ) : (
            <div className="loading-details">Creating services...</div>
          )}
        </>
      )}
    </div>
  );
};

export default ChatInput;
