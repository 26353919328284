import React from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import "./CheckoutForm.css";

const CheckoutForm = ({
  amount,
  customerId,
  userId,
  autoRefill,
  autoRefillAmount,
  saveCard,
  selectedCard,
  onPurchaseResult,
  setLoading, // Accept setLoading as a prop
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setLoading(true); // Start loading

    try {
      if (selectedCard) {
        // Handle payment with saved card
        const response = await fetch(
          "https://createcharge-cgn2j7f72a-uc.a.run.app",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              amount,
              customerId,
              userId,
              autoRefill,
              autoRefillAmount,
              selectedCardId: selectedCard.id, // Send the selected card ID
            }),
          }
        );

        const responseData = await response.json();
        if (!response.ok) {
          //  toast.error(`Payment failed: ${responseData.error}`, {
          //    autoClose: false,
          //  });
          console.error("Payment failed", responseData);
          onPurchaseResult({ success: false, message: responseData.error });
        } else {
          // toast.success("Payment successful", { autoClose: false });
          console.log("Payment successful", responseData);
          onPurchaseResult({ success: true });
        }
      } else {
        const cardElement = elements.getElement(CardElement);

        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: "card",
          card: cardElement,
        });

        if (error) {
          // toast.error(error.message, { autoClose: false });
          console.error(error);
          onPurchaseResult({ success: false, message: error.message });
        } else {
          const response = await fetch(
            "https://createcharge-cgn2j7f72a-uc.a.run.app",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                paymentMethodId: paymentMethod.id,
                amount,
                saveCard,
                customerId,
                userId,
                autoRefill,
                autoRefillAmount,
              }),
            }
          );

          const responseData = await response.json();
          if (!response.ok) {
            //  toast.error(`Payment failed: ${responseData.error}`, {
            //   autoClose: false,
            //  });
            console.error("Payment failed", responseData);
            onPurchaseResult({ success: false, message: responseData.error });
          } else {
            //  toast.success("Payment successful", { autoClose: false });
            console.log("Payment successful", responseData);
            onPurchaseResult({ success: true });
          }
        }
      }
    } catch (error) {
      let errorMessage = "An error occurred";
      try {
        const responseText = await error.response.text();
        errorMessage = responseText;
      } catch (parseError) {
        console.error("Error parsing response text", parseError);
      }
      // toast.error(`Payment failed: ${errorMessage}`, { autoClose: false });
      console.error("Payment failed", error);
      onPurchaseResult({ success: false, message: errorMessage });
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement className="StripeElement" />
      <button className="pay-button" type="submit" disabled={!stripe}>
        Pay ${amount}
      </button>
    </form>
  );
};

export default CheckoutForm;
