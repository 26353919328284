import React, { useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Button,
  Typography,
  Container,
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Slider,
  Card,
  CardMedia,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faBolt,
  faChevronUp,
  faCheckCircle,
  faArrowRight,
  faRandom,
  faExchangeAlt,
  faFileAlt,
  faUsers,
  faBriefcase,
  faMobileAlt,
  faCloudUploadAlt,
  faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons";

import { Menu as MenuIcon } from "@mui/icons-material";
import ReactPlayer from "react-player";
import Modal from "./LandingPageModal";
import logo from "../assets/logo.png";

import questionImage from "../assets/questions.png";
import womanOnPhoneImage from "../assets/on-phone.jpeg";
import phoneImage from "../assets/phone.png";
import hiringManager from "../assets/hiring-manager.jpeg";
import heroImage from "../assets/hero.jpg";

import image1 from "../assets/man-on-phone.png"; // Replace with your first image
import image2 from "../assets/superpower.png"; // Replace with your second image

import placeholderImage from "../assets/image001b.png";
import placeholderImage2 from "../assets/image002b.png";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const HrLandingPage = () => {
  const [activeStep, setActiveStep] = useState(0);
  const handleStepChange = (event, newValue) => {
    setActiveStep(newValue);
  };

  const [questions, setQuestions] = useState(5);
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [activeTab, setActiveTab] = useState("contact");
  const faqRef = useRef(null);
  const priceRef = useRef(null);
  const navigate = useNavigate();

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const openModal = (tab) => {
    setActiveTab(tab);
    setModalVisible(true);
  };

  const handleSignUpClick = () => {
    navigate("/signup", { state: { activeTab: "signUp" } });
  };

  const calculateMetrics = (numQuestions) => {
    const timePerForm = 4 + ((numQuestions - 10) / 20) * 3;
    const formsPerHour = Math.floor(60 / timePerForm);
    const costPerForm = (10 / formsPerHour).toFixed(2);

    return {
      timePerForm: `${timePerForm.toFixed(0)} minutes`,
      formsPerHour,
      costPerForm: `$${costPerForm}`,
    };
  };

  const { timePerForm, formsPerHour, costPerForm } =
    calculateMetrics(questions);

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
    closeMenu();
  };

  const faqData = [
    {
      question:
        "How does a candidate complete a pre-interview screening using the voice chatbot assistant?",
      answer:
        "Candidates complete the pre-interview screening over the phone by direct dialing or being transferred to the voice chatbot.",
    },
    {
      question: "How am I billed for each phone call the chatbot automates?",
      answer:
        "It varies based on the amount of questions in the interview and the length of the call.  For example a 5-question screening could take about 3-minutes and deduct $0.56 from your balance.  Call length is calculated in 30-second increments.  So a call lasting 3-minutes and 20 seconds would be billed as 3-minutes.  Whereas a call lasting 3-minutes and 45 seconds would be billed as 4-minutes.",
    },
    {
      question: "How exactly does the process work to automate interviews?",
      answer:
        "Start by creating a form, which contains your sequence of questions.  You can also include conditional logic to only ask certain questions based on the previous response.  The question sequences can be re-used for many applicants.  Then, create a unique session PIN for each applicant.  Give the access phone number and PIN either by email or another means.  When the applicant calls, the voice chatbot conducts the interview and gives you a text transcript to review at your convenience.",
    },
    {
      question:
        "Can candidates pause and resume their screenings later? What happens if a screening is interrupted?",
      answer:
        "Yes, they can pause and resume later. If interrupted, candidates simply call back, enter their session PIN, and pick up right where they left off.",
    },
    {
      question: "How can I review the screening data?",
      answer:
        "Real-time transcripts in CSV and PDF formats are available instantly in your dashboard, allowing immediate review and action.",
    },
    {
      question:
        "What types of job screening forms are best suited for this tool?",
      answer:
        "Ideal for pre-interview screenings, skill assessments, and more.",
    },
    {
      question: "How can I integrate this tool with my existing systems?",
      answer:
        "Currently, we offer integration with Google Drive, making it easy to store and access candidate data securely.  For custom integrations please contact sales.",
    },
    {
      question: "Is my data secure?",
      answer:
        "Absolutely. We prioritize your data security with industry-standard encryption and HIPAA compliance, ensuring your information is safe.",
    },
  ];

  return (
    <Box>
      <AppBar position="static">
        <Toolbar>
          <Box
            component="img"
            src={logo}
            alt="FormVox Logo"
            sx={{
              height: { xs: "25px", md: "50px" }, // 25px on mobile, 50px on larger screens
            }}
          />

          <Box sx={{ flexGrow: 1 }} />
          <Button color="inherit" onClick={() => navigate("/login")}>
            Sign In
          </Button>
          <Button variant="contained" onClick={handleSignUpClick}>
            Get Started Free
          </Button>
          <IconButton
            color="inherit"
            edge="end"
            onClick={openMenu}
            aria-controls="simple-menu"
            aria-haspopup="true"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={closeMenu}
          >
            <MenuItem onClick={() => openModal("demo")}>Book a Demo</MenuItem>
            <MenuItem onClick={() => scrollToSection(priceRef)}>
              Pricing
            </MenuItem>
            <MenuItem onClick={() => scrollToSection(faqRef)}>FAQ</MenuItem>
            <MenuItem onClick={() => openModal("contact")}>Contact Us</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>

      <Box
        sx={{
          bgcolor: "#fff",
          py: { xs: 8, sm: 16, md: 24 },
          display: "flex",
          justifyContent: "center", // Centers the grid container
          position: "relative",
        }}
      >
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="center" // Centers the items within the grid
          sx={{ maxWidth: "1200px" }} // Optional: to limit the max width
        >
          {/* Left side - Text content */}
          <Grid item xs={12} md={6}>
            <Box sx={{ textAlign: { xs: "center", md: "left" } }}>
              <Typography variant="h2" gutterBottom>
                <FontAwesomeIcon
                  icon={faBolt}
                  style={{ marginRight: "5px", color: "#1976d2" }}
                />
                Hiring Superpowers
              </Typography>
              <Typography variant="h4" gutterBottom>
                Automate Candidate Screening with a Voice Chatbot Assistant
              </Typography>
              <Typography variant="body1" paragraph>
                Make data-driven decisions with interview transcripts at your
                fingertips and save time from phone call overload.{" "}
                <span style={{ fontWeight: "bold" }}>
                  <FontAwesomeIcon
                    icon={faBolt}
                    style={{ marginRight: "5px", color: "#1976d2" }}
                  />
                  Powered by Google Speech-to-Text AI and Microsoft Azure Speech
                  AI.
                </span>
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: { xs: "center", md: "flex-start" }, // Center on mobile, align left on desktop
                  py: { xs: 4, md: 2 }, // Add more margin-top on mobile for spacing
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={handleSignUpClick}
                >
                  Get Started Free
                </Button>
              </Box>
            </Box>
          </Grid>

          {/* Right side - Images */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" }, // Stack vertically on mobile, horizontally on desktop
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                position="relative"
                sx={{
                  width: { xs: "80%", md: "50%" }, // Adjust width for mobile and desktop
                  maxWidth: "100%", // Ensure it takes full width available
                  mb: { xs: 3, md: 0 }, // Add margin bottom for mobile view
                  mr: { md: 2 }, // Add margin right for desktop view
                }}
              >
                <img
                  src={image1}
                  alt="Stressed man on phone"
                  style={{
                    width: "100%",
                    borderRadius: "10px",
                  }}
                />
                {/* Yellow narration box */}
                <Typography
                  sx={{
                    position: "absolute",
                    top: "-20px",
                    left: "10px",

                    backgroundColor: "yellow",
                    padding: { xs: "8px", md: "6px" },
                    borderRadius: "5px",
                    fontFamily: "'Arial', sans-serif",
                    fontWeight: "bold",
                    fontSize: { xs: "16px", md: "14px" },
                  }}
                >
                  Phone call after phone call. Trying to find the right
                  candidate for the role….
                </Typography>
                {/* Thought bubble */}
                <Typography
                  sx={{
                    position: "absolute",
                    bottom: "-10px",
                    right: "10px",
                    backgroundColor: "white",
                    padding: { xs: "10px", md: "8px" },
                    borderRadius: "50%",
                    border: "2px solid black",
                    fontFamily: "'Arial', sans-serif",
                    fontStyle: "italic",
                    fontSize: { xs: "18px", md: "16px" },
                    padding: "30px",
                  }}
                >
                  But there’s no other way!
                </Typography>
              </Box>

              <Box
                position="relative"
                sx={{
                  width: { xs: "80%", md: "50%" }, // Adjust width for mobile and desktop
                  maxWidth: "100%", // Ensure it takes full width available
                }}
              >
                <img
                  src={image2}
                  alt="Superhero with clock"
                  style={{
                    width: "100%",
                    borderRadius: "10px",
                  }}
                />
                {/* Speech bubble */}
                <Typography
                  sx={{
                    position: "absolute",
                    bottom: "-20px",
                    right: "-20px",
                    backgroundColor: "white",
                    padding: { xs: "10px", md: "8px" },
                    borderRadius: "15px",
                    border: "2px solid black",
                    fontFamily: "'Arial', sans-serif",
                    fontWeight: "bold",
                    fontSize: { xs: "18px", md: "16px" },
                  }}
                >
                  You{" "}
                  <span
                    style={{
                      fontWeight: "900",
                      color: "red",
                      fontSize: "1.1em",
                      fontStyle: "italic",
                    }}
                  >
                    can
                  </span>{" "}
                  get your valuable time back and make data-driven decisions!
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Box
          sx={{
            position: "absolute",
            bottom: "90px", // Adjusts position from the bottom
            display: { xs: "none", md: "flex" },
            justifyContent: "center",
            width: "100%",
            cursor: "pointer", // Makes the arrow look clickable
          }}
          onClick={() => {
            window.scrollTo({
              top: window.innerHeight * 1.1, // Scrolls 75% down the page
              behavior: "smooth", // Smooth scrolling effect
            });
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#1976d2"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-arrow-down"
          >
            <line x1="12" y1="5" x2="12" y2="19"></line>
            <polyline points="19 12 12 19 5 12"></polyline>
          </svg>
        </Box>
      </Box>

      {/* New Section with the provided content */}
      <Box
        sx={{
          bgcolor: "#f0f8ff", // Light grey background color
          py: 4, // Padding on the top and bottom
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="stretch">
            <Grid container item xs={12} md={6} spacing={2}>
              <Grid item xs={12}>
                <Grid item xs={12} md={12}>
                  <Typography variant="h4" gutterBottom textAlign="center">
                    Sometimes it gets awkward...
                  </Typography>
                </Grid>
                <CardMedia
                  component="img"
                  image={womanOnPhoneImage}
                  alt="Woman on Phone"
                  sx={{
                    width: "100%",
                    borderRadius: "10px",
                    mb: { xs: 4, md: 0 }, // Add margin bottom for mobile view
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" paragraph>
                  What if you had the ability to screen all applicants by phone
                  in a single day? It’s possible with a voice chatbot assistant
                  from FormVox.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" component="blockquote" paragraph>
                  Relying solely on resume screening software often falls short.
                </Typography>
                <Typography variant="body1" paragraph>
                  While nothing can replace the human touch of a live one-on-one
                  interview, how do you manage filtering through hundreds or
                  even thousands of applicants for a single role? Relying solely
                  on resume screening software often falls short. Wouldn’t it be
                  better to cast a wider net, to hear from as many applicants as
                  possible, diversify the talent pool, and find the best fit?
                </Typography>
              </Grid>
            </Grid>

            <Grid container item xs={12} md={6} spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h4" gutterBottom textAlign="center">
                  Focus on what counts
                </Typography>
                <CardMedia
                  component="img"
                  image={hiringManager}
                  alt="Hiring Manager"
                  sx={{
                    width: "100%",
                    borderRadius: "10px",
                    mb: { xs: 4, md: 0 }, // Add margin bottom for mobile view
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" paragraph>
                  You’ll get a full text transcript of the interview, right
                  alongside their resume or application helping you to focus on
                  what counts: making data-driven, informed hiring decisions
                  without having spent 10-20 hours on the phone to collect the
                  preliminary data.
                </Typography>
                <Typography variant="h5" component="blockquote" paragraph>
                  We prioritize your data security
                </Typography>
                <Typography variant="body1" paragraph>
                  We prioritize your data security with industry-standard
                  encryption and HIPAA compliance, ensuring your information is
                  safe.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <CardMedia
              component="img"
              alt="Job Screening Creation"
              image={questionImage}
              sx={{
                width: "100%",
                height: "auto",
                objectFit: "contain",
                borderRadius: "10px", // Adds rounded corners
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Adds drop shadow
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Box>
              <Typography variant="h4" gutterBottom>
                Reliable, Controlled Chatbot Interactions
              </Typography>
              <Typography variant="body1" paragraph>
                A rules-based sequence provides you with complete control over
                the chatbot. Unlike generative AI, which can sometimes produce
                unexpected results, this approach ensures consistent and
                predictable interactions.{" "}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        {/* <Grid container spacing={4} sx={{ mt: 4 }}>
          <Grid
            item
            xs={12}
            md={6}
            order={{ xs: 2, md: 1 }}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Box>
              <Typography variant="h4" gutterBottom>
                Let Candidates Start Anytime with Voice Chatbot
              </Typography>
              <Typography variant="body1" paragraph>
                Candidates can initiate their screening by calling or being
                transferred to our voice chatbot. They can pause and resume as
                needed, making it flexible for all participants.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
            <CardMedia
              component="img"
              alt="Call Transfers"
              image={placeholderImage2}
              sx={{
                width: "100%",
                height: "auto",
                objectFit: "contain",
              }}
            />
          </Grid>
        </Grid>  */}
      </Container>

      <Container
        maxWidth="md"
        sx={{ py: 4, textAlign: "center", bgcolor: "#f0f8ff" }}
      >
        <Typography variant="h3" gutterBottom>
          How It Works
        </Typography>
        <Tabs
          value={activeStep}
          onChange={handleStepChange}
          variant="scrollable"
          scrollButtons="auto"
          indicatorColor="primary"
          textColor="primary"
          sx={{
            overflowX: "auto",
            whiteSpace: "nowrap",
          }}
        >
          <Tab label="1. Add Your Questions" />
          <Tab label="2. Generate a Session PIN" />
          <Tab label="3. Start the Process" />
          <Tab label="4. View Responses" />
        </Tabs>
        <TabPanel value={activeStep} index={0}>
          <Typography variant="body1">
            Quickly add and customize your questions directly in our intuitive
            dashboard. Tailor questions to fit your exact needs with ease.
          </Typography>
        </TabPanel>
        <TabPanel value={activeStep} index={1}>
          <Typography variant="body1">
            Generate a secure, unique session PIN for each candidate. This
            ensures personalized, uninterrupted access to the screening process.
          </Typography>
        </TabPanel>
        <TabPanel value={activeStep} index={2}>
          <Typography variant="body1">
            Candidates dial in and enter their PIN to begin the voice-powered
            screening, providing you with valuable insights instantly.
          </Typography>
        </TabPanel>
        <TabPanel value={activeStep} index={3}>
          <Typography variant="body1">
            Access and review detailed responses in real-time. All data is
            available in both CSV and PDF formats for easy integration and
            analysis.
          </Typography>
        </TabPanel>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            my: 4,
          }}
        >
          <Box
            sx={{
              position: "relative",
              width: "80%",
              paddingTop: "45%",
            }}
          >
            <ReactPlayer
              url="https://youtu.be/eGLSKyzT4Yg"
              controls={true}
              width="100%"
              height="100%"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
              }}
            />
          </Box>
        </Box>
      </Container>

      <Container
        maxWidth="md"
        sx={{ pt: 4, pb: 8, textAlign: "center" }}
        ref={priceRef}
      >
        <Typography variant="h3" gutterBottom>
          Pricing Options
        </Typography>
        <Typography variant="h6" color="textSecondary" paragraph>
          Select the plan that fits your business
        </Typography>

        <Grid container spacing={4}>
          {/* Pay-as-you-go Plan */}
          <Grid item xs={12} md={6} py={7}>
            <Card
              sx={{
                p: 3,
                borderRadius: "15px",
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                position: "relative", // Added to position the "Popular" banner
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                minHeight: "100%", // Ensure equal height
              }}
            >
              {/* Popular Banner */}
              <Box
                sx={{
                  position: "absolute",
                  top: 50,
                  left: -20,
                  backgroundColor: "red",
                  color: "white",
                  padding: "8px 16px",
                  fontSize: "0.875rem",
                  fontWeight: "bold",
                  transform: "rotate(-45deg)",
                  transformOrigin: "top left",
                  width: "70px",
                  textAlign: "center",
                  zIndex: 1,
                }}
              >
                Popular
              </Box>

              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" color="secondary" paragraph>
                  Pay-as-you-go Plan
                </Typography>
                <Typography variant="h5" color="primary" paragraph>
                  $10 per hour of Voice Chatbot Time
                </Typography>
                <Typography variant="body1" paragraph>
                  Includes Google Drive Integration
                </Typography>
                <Box sx={{ mb: 2 }}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item sx={{ textAlign: "left" }}>
                      <FontAwesomeIcon
                        icon={faXmarkCircle}
                        color="primary"
                        style={{ fontSize: "1.5rem" }}
                      />
                    </Grid>
                    <Grid item xs={10} sx={{ textAlign: "left" }}>
                      <Typography variant="h6" color="primary">
                        No subscription required!
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Typography
                  variant="body1"
                  paragraph
                  sx={{ textAlign: "left" }}
                >
                  Adjust the slider to estimate costs based on the number of
                  questions per interview:
                </Typography>
                <Slider
                  value={questions}
                  onChange={(e, val) => setQuestions(val)}
                  min={5}
                  max={50}
                  step={5}
                  sx={{ mb: 2 }}
                />
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Number of Questions per Interview: {questions}
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: 3,
                    width: "100%",
                  }}
                >
                  <table
                    style={{
                      borderCollapse: "separate",
                      borderSpacing: "0 10px",
                      width: "100%",
                      maxWidth: "500px",
                      textAlign: "left",
                    }}
                  >
                    <thead>
                      <tr>
                        <th
                          style={{
                            padding: "12px",
                            backgroundColor: "#f9f9f9",
                          }}
                        >
                          Estimated Voice Chatbot Time
                        </th>
                        <th
                          style={{
                            padding: "12px",
                            backgroundColor: "#f9f9f9",
                          }}
                        >
                          Cost per Completion
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          style={{ padding: "12px", backgroundColor: "#fff" }}
                        >
                          {timePerForm}
                        </td>
                        <td
                          style={{ padding: "12px", backgroundColor: "#fff" }}
                        >
                          {costPerForm}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Box>

                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ mt: 2 }}
                >
                  *Estimates only. Actual call length may vary.
                </Typography>
              </Box>

              {/* Get Started Free Button */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mt: 4,
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={handleSignUpClick}
                  sx={{
                    px: 4, // Extra padding for a more prominent button
                    py: 1.5,
                    borderRadius: "5px", // Button rounded corners
                    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // More pronounced shadow for the button
                  }}
                >
                  Get Started Free
                </Button>
              </Box>
            </Card>
          </Grid>

          {/* Enterprise Plan */}
          <Grid item xs={12} md={6} py={7}>
            <Card
              sx={{
                p: 3,
                borderRadius: "15px",
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                minHeight: "100%", // Ensure equal height
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" color="secondary" paragraph>
                  Enterprise Plan
                </Typography>
                <Typography variant="h5" color="primary" paragraph>
                  $699/month
                  <Typography variant="body1" color="primary" paragraph>
                    *billed annually
                  </Typography>
                </Typography>
                <Typography variant="body1" paragraph>
                  Includes:
                </Typography>

                <List sx={{ mb: 2 }}>
                  <ListItem>
                    <ListItemText primary="100 hours per month of Voice Chatbot Time" />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="$8/hour additional time" />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Custom Integration with Your CRM" />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Personalized Setup and Onboarding" />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Priority Support" />
                  </ListItem>
                </List>

                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ mt: 2 }}
                >
                  Contact us for more details about our enterprise solutions.
                </Typography>
              </Box>

              {/* Contact Sales Button */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mt: 4,
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => openModal("contact")}
                  sx={{
                    px: 4, // Extra padding for a more prominent button
                    py: 1.5,
                    borderRadius: "5px", // Button rounded corners
                    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // More pronounced shadow for the button
                  }}
                >
                  Contact Sales
                </Button>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="lg" sx={{ py: 6 }}>
        <Typography variant="h3" align="center" gutterBottom>
          What Our Clients Are Saying
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Card
              sx={{
                p: 3,
                borderRadius: "15px",
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                height: "100%",
              }}
            >
              <Typography variant="body1" paragraph>
                "FormVox has completely transformed our hiring process, making
                it faster and more efficient."
              </Typography>
              <Typography variant="h6" color="primary">
                - HR Manager, Mid-Sized Company
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card
              sx={{
                p: 3,
                borderRadius: "15px",
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                height: "100%",
              }}
            >
              <Typography variant="body1" paragraph>
                "We're able to screen hundreds of applicants in record time.
                It's a game-changer!"
              </Typography>
              <Typography variant="h6" color="primary">
                - Operations Director, Growing Startup
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card
              sx={{
                p: 3,
                borderRadius: "15px",
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                height: "100%",
              }}
            >
              <Typography variant="body1" paragraph>
                "Our recruitment process is more focused and efficient thanks to
                FormVox's voice chatbot."
              </Typography>
              <Typography variant="h6" color="primary">
                - Talent Acquisition Specialist, Tech Company
              </Typography>
            </Card>
          </Grid>
        </Grid>
      </Container>

      <Container
        maxWidth="lg"
        sx={{
          py: 4,
          textAlign: "center", // Centers the text horizontally
          display: "flex", // Makes the container a flexbox
          flexDirection: "column", // Aligns items vertically
          alignItems: "center", // Centers items horizontally within the flexbox
          border: "2px solid #f7faff", // Light blue border
          borderRadius: "10px", // Rounded corners
          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Subtle shadow
          backgroundColor: "#1976d2", // Dark blue background
          color: "white", // White text color for contrast
          mt: 4, // Top margin to separate it from the previous section
        }}
        ref={faqRef}
      >
        <Typography variant="h4" paragraph sx={{ fontWeight: "bold" }}>
          Ready to give it a try?
        </Typography>
        <Typography variant="body1" paragraph>
          Sign up now and start for free—no credit card required!
        </Typography>
        <Button
          variant="contained"
          color="secondary" // Change to a lighter button for contrast
          size="large"
          onClick={handleSignUpClick}
          sx={{
            mt: 2,
            px: 4, // Extra padding for a more prominent button
            py: 1.5,
            borderRadius: "5px", // Button rounded corners
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // More pronounced shadow for the button
          }}
        >
          Get Started Free
        </Button>
      </Container>

      <Container maxWidth="lg" sx={{ py: 4, bgcolor: "#f0f8ff" }} ref={faqRef}>
        <Grid container spacing={4}>
          {faqData.map((faq, index) => (
            <Grid item xs={12} key={index}>
              <Typography
                variant="h5"
                gutterBottom
                sx={{
                  cursor: "pointer",
                  color: "primary.main",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                onClick={() =>
                  setActiveTab((prev) => (prev === index ? null : index))
                }
              >
                {faq.question}
                <FontAwesomeIcon
                  icon={activeTab === index ? faChevronUp : faChevronDown}
                />
              </Typography>
              {activeTab === index && (
                <Typography variant="body1" paragraph>
                  {faq.answer}
                </Typography>
              )}
            </Grid>
          ))}
        </Grid>
      </Container>

      <Container
        maxWidth="lg"
        sx={{
          py: 4,
          textAlign: "center", // Centers the text horizontally
          display: "flex", // Makes the container a flexbox
          flexDirection: "column", // Aligns items vertically
          alignItems: "center", // Centers items horizontally within the flexbox
          border: "2px solid #1976d2", // Blue border
          borderRadius: "10px", // Rounded corners
          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Subtle shadow
          backgroundColor: "#f7faff", // Light background color to make it stand out
          mt: 4, // Top margin to separate it from the previous section
        }}
        ref={faqRef}
      >
        <Typography variant="h4" paragraph sx={{ fontWeight: "bold" }}>
          Prefer to Speak with Us?
        </Typography>
        <Typography variant="body1" paragraph>
          Schedule a free demo & consultation call to learn more.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => openModal("demo")} // Updated onClick function
          sx={{
            mt: 2,
            px: 4, // Extra padding for a more prominent button
            py: 1.5,
            borderRadius: "5px", // Button rounded corners
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // More pronounced shadow for the button
          }}
        >
          Schedule Demo
        </Button>
      </Container>

      <Modal
        show={modalVisible}
        onClose={() => setModalVisible(false)}
        initialTab={activeTab}
      />

      <Box
        sx={{ textAlign: "center", py: 2, bgcolor: "grey.900", color: "white" }}
      >
        <Typography variant="body2">
          &copy; 2024 FormVox LLC. All rights reserved.
        </Typography>
        <Typography variant="body2">
          30 N Gould St Ste R, Sheridan, WY 82801
        </Typography>
        <Typography variant="body2">
          Contact:{" "}
          <a
            href="mailto:contact@formvox.com"
            style={{
              textDecoration: "none",
              color: "white",
            }}
          >
            contact@formvox.com
          </a>
        </Typography>

        <Typography variant="body2">
          <a
            href="/privacy_policy.html"
            target="_blank"
            style={{
              textDecoration: "none",
              color: "white",
            }}
          >
            Privacy Policy
          </a>
        </Typography>
      </Box>
    </Box>
  );
};

export default HrLandingPage;
