import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faDownload } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./RecentActivityModal.css";

const RecentActivityModal = ({ onClose, downloadAuditLog }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const predefinedRanges = [
    {
      label: "This Month",
      start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      end: new Date(),
    },
    {
      label: "Last Month",
      start: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
      end: new Date(new Date().getFullYear(), new Date().getMonth(), 0),
    },
    {
      label: "Last 3 Months",
      start: new Date(new Date().getFullYear(), new Date().getMonth() - 2, 1),
      end: new Date(),
    },
    {
      label: "This Year",
      start: new Date(new Date().getFullYear(), 0, 1),
      end: new Date(),
    },
    {
      label: "Last Year",
      start: new Date(new Date().getFullYear() - 1, 0, 1),
      end: new Date(new Date().getFullYear() - 1, 11, 31),
    },
  ];

  const handleRangeSelect = (range) => {
    setStartDate(range.start);
    setEndDate(range.end);
  };

  return (
    <div className="activity-modal-overlay">
      <div className="activity-modal-content">
        <div className="activity-modal-header">
          <h2>Recent Activity</h2>
          <button className="activity-close-btn" onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        <div className="activity-modal-body">
          <p className="activity-description">
            View user activity such as downloads, uploads to integrated
            services, and other relevant actions. This data helps ensure
            compliance with HIPAA regulations by tracking and recording access
            and use of PHI & PII.
          </p>
          <p>Enter a date range or choose an option below:</p>
          <div className="activity-date-picker">
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              dateFormat="MMMM yyyy"
              showMonthYearPicker
            />
            to
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              dateFormat="MMMM yyyy"
              showMonthYearPicker
            />
          </div>
          <div className="activity-predefined-ranges">
            {predefinedRanges.map((range) => (
              <button
                key={range.label}
                onClick={() => handleRangeSelect(range)}
                className="activity-range-btn"
              >
                {range.label}
              </button>
            ))}
          </div>
          <button
            className="activity-download-btn"
            onClick={() => downloadAuditLog(startDate, endDate)}
          >
            {" "}
            <FontAwesomeIcon icon={faDownload} /> Download Audit Log
          </button>
        </div>
      </div>
    </div>
  );
};

export default RecentActivityModal;
