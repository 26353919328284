import React, { useState, useEffect } from "react";
import "./StartNewVoiceForm.css";

import Modal from "./Modal";

import {
  getFirestore,
  addDoc,
  collection,
  doc,
  getDoc,
  setDoc,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCopy,
  faQuestionCircle,
  faEye,
} from "@fortawesome/free-solid-svg-icons";

const StartNewVoiceForm = ({ onClose, forms, onSessionCreated }) => {
  const [showModal, setShowModal] = useState(false); // State to control the modal visibility

  const [selectedForm, setSelectedForm] = useState("");
  const [clientID, setClientID] = useState("");
  const [sessionID, setSessionID] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [invalidChars, setInvalidChars] = useState(false);
  const [isCreated, setIsCreated] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [copyStatus, setCopyStatus] = useState("Copy");
  const [expiryTime, setExpiryTime] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  const [clientIDToReuse, setClientIDToReuse] = useState("");
  const [userId, setUserId] = useState(""); // New state to handle userId

  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return "";
    const country = phoneNumber.substring(0, 1);
    const area = phoneNumber.substring(1, 4);
    const prefix = phoneNumber.substring(4, 7);
    const line = phoneNumber.substring(7, 11);
    return `${country} (${area}) ${prefix}-${line}`;
  };

  const handleClientIDChange = (e) => {
    const value = e.target.value;
    const isValid = /^[a-zA-Z0-9.\-]*$/.test(value); // Regex to allow letters, numbers, '.', and '-'
    if (isValid) {
      setClientID(value);
      setInvalidChars(false); // Reset the invalid character warning
    } else {
      setInvalidChars(true); // Set invalid character warning
    }
  };

  const howToUseMessage = `How to Use:

Start the session by calling the provided number, entering the session PIN, and transferring the participant. There is 10-seconds of hold music to facilitate the transfer.

- OR -

Directly provide the session PIN to the participant for self-initiated calling.

Please note:

1. Participants can reconnect and resume the session if they get disconnected.

2. Transcript Access and External Integration:

Access both partial and complete transcripts via the FormVox dashboard to track or review sessions. For transcripts to be sent to connected services like Google Drive, the session must be fully completed—participants need to answer all questions. Ensure participants understand the completion requirement to facilitate external transfers.
`;

  useEffect(() => {
    const fetchUserData = async () => {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        const firestore = getFirestore();
        const userDocRef = doc(firestore, "users", user.uid);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();

          if (userData.role === "sub-user" && userData.mainUserId) {
            console.log("BLAH BLAH BLAH");
            setUserId(userData.mainUserId); // Use mainUserId for sub-users
            const mainUserDocRef = doc(firestore, "users", userData.mainUserId);
            const mainUserDocSnap = await getDoc(mainUserDocRef);
            if (mainUserDocSnap.exists()) {
              setPhoneNumber(
                mainUserDocSnap.data().accessPhone || "+18009992222"
              );
            }
          } else {
            console.log("BLAH BLAH BLAH 2");
            setUserId(user.uid); // Use the current user's ID for main users
            setPhoneNumber(userData.accessPhone || "+18009992222");
          }
        }
      }
    };
    fetchUserData();
  }, []);

  const formatDate = (date) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };
    return new Intl.DateTimeFormat("en-US", options).format(date);
  };

  const generateRandomID = () => {
    return Math.floor(10000 + Math.random() * 90000).toString();
  };

  const checkClientIDExists = async (clientID) => {
    const firestore = getFirestore();

    if (userId) {
      const q = query(
        collection(firestore, "users", userId, "sessions"),
        where("clientID", "==", clientID)
      );
      const querySnapshot = await getDocs(q);
      return !querySnapshot.empty;
    }
    return false;
  };

  const generateUniqueClientID = async () => {
    let uniqueClientID = "";
    let exists = true;
    while (exists) {
      uniqueClientID = generateRandomID();
      exists = await checkClientIDExists(uniqueClientID);
    }
    return uniqueClientID;
  };

  const generateClientID = async () => {
    const newClientID = await generateUniqueClientID();
    setClientID(newClientID);
  };

  const countActiveSessions = async (userId) => {
    const firestore = getFirestore();
    const userDocRef = doc(firestore, "users", userId); // Corrected to point to the user document
    const userDocSnapshot = await getDoc(userDocRef); // Correct function to get a document
    const activeSessionsCount = userDocSnapshot.data().activeSessionCount || 0; // Access the data and then the field
    return activeSessionsCount;
  };

  const handleCreate = async () => {
    const firestore = getFirestore();

    if (userId && selectedForm && clientID) {
      try {
        setLoading(true); // Start loading

        const sessionCount = await countActiveSessions(userId);
        console.log("THIS IS SESSION COUNT " + sessionCount);

        if (sessionCount >= 50) {
          setErrorMessage(
            "Maximum of 50 active sessions has been reached. Archive active sessions to create more."
          );
          setLoading(false);
          return;
        }

        const clientIDExists = await checkClientIDExists(clientID);

        if (clientIDExists && !showConfirm) {
          setClientIDToReuse(clientID);
          setShowConfirm(true);
          setLoading(false);
          return;
        }
        console.log("post now " + userId);
        const response = await fetch(
          "https://handlesessions-cgn2j7f72a-uc.a.run.app",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              userID: userId, // Send user ID as part of request
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        const sessionID = data.sessionID;
        const currentTime = new Date();
        const expiryDate = new Date(
          currentTime.getTime() + 72 * 60 * 60 * 1000
        );
        expiryDate.setMinutes(0);
        expiryDate.setSeconds(0);

        setExpiryTime(formatDate(expiryDate));

        const selectedFormName = forms.find(
          (form) => form.id === selectedForm
        )?.formName;

        const newSession = {
          formID: selectedForm,
          formName: selectedFormName, // Add formName here
          clientID,
          sessionID: sessionID, // Assuming sessionID is generated here
          firstName,
          lastName,
          createdAt: currentTime,
        };

        const userDocRef = doc(firestore, "users", userId);
        const sessionDocRef = doc(userDocRef, "sessions", sessionID); // Use doc to explicitly specify the document ID
        await setDoc(sessionDocRef, newSession);

        const globalSessionDocRef = doc(firestore, "sessions", sessionID); // Specify the document ID for global sessions
        await setDoc(globalSessionDocRef, {
          sessionID,
          createdAt: currentTime,
          clientID,
          userID: userId,
          formID: selectedForm,
        });

        setSessionID(sessionID);

        if (onSessionCreated) {
          onSessionCreated(newSession);
        }

        setSuccessMessage("New Voice Form Session is Ready!");
        setIsCreated(true);
      } catch (error) {
        console.error("Error creating session: ", error);
        setErrorMessage("Failed to create session. Please try again.");
      } finally {
        setLoading(false); // Stop loading
      }
    } else {
      console.error("Form or client ID is missing");
      setErrorMessage("Please select a form and client ID.");
    }
  };

  const handleConfirmReuse = (confirm) => {
    if (confirm) {
      setShowConfirm(false);
      handleCreate();
    } else {
      setShowConfirm(false);
      setClientID("");
    }
  };

  const handleCreateAnother = () => {
    setSelectedForm("");
    setClientID("");
    setSessionID("");
    setSuccessMessage("");
    setErrorMessage("");
    setFirstName("");
    setLastName("");
    setIsCreated(false);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(
      `Call ${phoneNumber} and enter session ID ${sessionID} to begin the form.` // This session ID will expire on ${expiryTime}.
    );
    setCopyStatus("Copied!");
    setTimeout(() => {
      setCopyStatus("Copy");
    }, 3000);
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        {loading && (
          <div className="loading-spinner">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </div>
        )}
        {!loading && (
          <>
            {isCreated ? (
              <div className="success-message">
                <p>{successMessage}</p>
                <table className="rounded-table">
                  <thead>
                    <tr>
                      <th className="client-id-text">Form to be Used</th>
                      <th className="client-id-text">Client ID</th>
                      {firstName || lastName ? <th>Name</th> : null}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="client-id-text">
                        {
                          forms.find((form) => form.id === selectedForm)
                            ?.formName
                        }
                      </td>
                      <td className="client-id-text">{clientID}</td>
                      {firstName || lastName ? (
                        <td>{`${firstName} ${lastName}`.trim()}</td>
                      ) : null}
                    </tr>
                  </tbody>
                </table>
                <div className="session-id-box">
                  <a href="#" onClick={() => setShowModal(true)}>
                    How to Use
                  </a>

                  {showModal && (
                    <Modal
                      message={howToUseMessage}
                      onClose={() => setShowModal(false)}
                    />
                  )}
                  <p className="call-text">
                    Call {formatPhoneNumber(phoneNumber)} and use the session
                    PIN below to begin the form:
                  </p>
                  <p>
                    <span className="sessionId">{sessionID}</span>
                  </p>

                  {/*  <p>This session ID will expire on {expiryTime}.</p>
                  <p>
                    If you do not begin the form within 72 hours, it will
                    expire.
                  </p>*/}
                  <span
                    className="copy-icon"
                    onClick={copyToClipboard}
                    title="Copy"
                  >
                    <span
                      className={`copy-text ${
                        copyStatus === "Copied!" ? "copied" : ""
                      }`}
                    >
                      {copyStatus}
                    </span>
                    <FontAwesomeIcon icon={faCopy} />
                  </span>
                </div>
              </div>
            ) : (
              <>
                {showConfirm ? (
                  <div className="confirmation">
                    <p>
                      Client ID {clientIDToReuse} already exists. Do you want to
                      re-use this client ID?
                    </p>
                    <button
                      onClick={() => handleConfirmReuse(true)}
                      className="confirm-btn"
                    >
                      Yes, continue to create
                    </button>
                    <button
                      onClick={() => handleConfirmReuse(false)}
                      className="cancel-btn"
                    >
                      No
                    </button>
                  </div>
                ) : (
                  <>
                    <div className="form-group">
                      <h3>Create New Voice Chatbot Session</h3>
                      <label htmlFor="selectForm">Select Form</label>
                      <select
                        id="selectForm"
                        value={selectedForm}
                        onChange={(e) => setSelectedForm(e.target.value)}
                        disabled={isCreated}
                      >
                        <option value="">Choose Form</option>
                        {forms.map((form) => (
                          <option key={form.id} value={form.id}>
                            {form.formName}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="clientID">Client ID</label>
                      <div className="client-id-with-button">
                        <input
                          id="clientID"
                          type="text"
                          value={clientID}
                          onChange={handleClientIDChange}
                          disabled={isCreated}
                          title={
                            invalidChars
                              ? "Only letters, numbers, '.', and '-' are allowed."
                              : ""
                          }
                        />
                        <button
                          onClick={generateClientID}
                          className="generate-btn"
                          disabled={isCreated}
                        >
                          Auto Generate
                        </button>
                      </div>
                      {invalidChars && (
                        <p className="error-message-client-id">
                          Invalid character used. Only letters, numbers, '.',
                          and '-' are allowed.
                        </p>
                      )}
                    </div>

                    <div className="form-group">
                      <label htmlFor="firstName">First Name (optional)</label>
                      <input
                        id="firstName"
                        type="text"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        disabled={isCreated}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="lastName">Last Name (optional)</label>
                      <input
                        id="lastName"
                        type="text"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        disabled={isCreated}
                      />
                    </div>
                    <button
                      onClick={handleCreate}
                      className="create-btn"
                      style={{ background: "green" }}
                    >
                      Create
                    </button>
                  </>
                )}
              </>
            )}
            {!isCreated && errorMessage && (
              <div className="error-message">{errorMessage}</div>
            )}
            <div className="button-group-start-voiceform">
              {isCreated && (
                <button
                  onClick={handleCreateAnother}
                  className="create-btn"
                  style={{ background: "green" }}
                >
                  Create Another Session
                </button>
              )}
              <button
                onClick={onClose}
                className="close-btn-start-voiceform"
                style={{ background: "red" }}
              >
                Close
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default StartNewVoiceForm;
