import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle, faMicrosoft } from "@fortawesome/free-brands-svg-icons";
import { getAuth, sendPasswordResetEmail } from "firebase/auth"; // Import Firebase auth functions
import logo from "../assets/logo.png"; // Update the path as needed
import "./Login.css";
import { ToastContainer, toast } from "react-toastify";

const Login = ({
  signUpWithEmail,
  signInWithEmail,
  signInWithGoogle,
  signInWithMicrosoft,
  setEmail,
  setPassword,
  email,
  password,
  error,
}) => {
  const [isSignUp, setIsSignUp] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [emailSent, setEmailSent] = useState(false); // State to track if the reset email has been sent

  const handleResetPassword = () => {
    const auth = getAuth();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        toast.success("Reset password link sent to your email.");
        setEmailSent(true); // Set emailSent to true to show the additional text
        setResetPassword(false); // Reset the state to show normal login after sending email
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const showResetPasswordForm = () => {
    setResetPassword(true);
    setEmailSent(false); // Ensure emailSent is reset when showing reset form
    setEmail("");
    setPassword("");
  };

  if (resetPassword) {
    return (
      <div className="login-auth-container">
        <div className="login-header">
          <Link to="/" className="login-logo-link">
            <img src={logo} alt="FormVox Logo" className="login-logo" />
          </Link>
        </div>
        <div className="login-auth-box">
          <div className="login-auth-section">
            <p>Reset Password</p>
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button onClick={handleResetPassword} className="send-email-button">
              Send Verification Email
            </button>
            <button onClick={() => setResetPassword(false)}>
              Back to Login
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="login-auth-container">
      <div className="login-header">
        <Link to="/" className="login-logo-link">
          <img src={logo} alt="FormVox Logo" className="login-logo" />
        </Link>
      </div>
      <div className="login-auth-box">
        <div className="login-auth-section">
          <p>Login to FormVox</p>
          {emailSent && (
            <p>Check your email for password reset instructions.</p>
          )}
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button onClick={isSignUp ? signUpWithEmail : signInWithEmail}>
            {isSignUp ? "Sign Up with Email" : "Sign In with Email"}
          </button>
          {!isSignUp && (
            <a href="#" onClick={showResetPasswordForm}>
              Forgot Password?
            </a>
          )}
          <div className="login-toggle-auth">
            {isSignUp ? (
              <span>
                Already have an account?{" "}
                <a href="#" onClick={() => setIsSignUp(false)}>
                  Sign in
                </a>
              </span>
            ) : (
              <span>
                Don't have an account? <Link to="/signup">Sign up</Link>
              </span>
            )}
          </div>
        </div>

        <div className="login-auth-divider">
          <div className="line"></div>
          <span>OR</span>
          <div className="line"></div>
        </div>

        <div className="login-auth-item" onClick={signInWithGoogle}>
          <FontAwesomeIcon icon={faGoogle} /> Continue with Google
        </div>
        <div className="login-auth-item" onClick={signInWithMicrosoft}>
          <FontAwesomeIcon icon={faMicrosoft} /> Continue with Microsoft
        </div>
        {error && <div className="error-message">{error}</div>}
      </div>
    </div>
  );
};

export default Login;
