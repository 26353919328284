import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import "./DownloadDropdown.css"; // Import the CSS file for styling

const DownloadDropdown = ({ session, generateCSV, generatePDF }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const handleGenerateCSV = async (e) => {
    e.preventDefault();
    await generateCSV(session);
    setShowDropdown(false);
  };

  const handleGeneratePDF = async (e) => {
    e.preventDefault();
    await generatePDF(session);
    setShowDropdown(false);
  };

  return (
    <div className="dropdown">
      <button
        className="dropbtn"
        onClick={() => setShowDropdown(!showDropdown)}
      >
        <FontAwesomeIcon icon={faDownload} /> View Transcript
      </button>
      {showDropdown && (
        <div className="dropdown-content">
          <a href="#" onClick={handleGenerateCSV}>
            Download CSV
          </a>
          <a href="#" onClick={handleGeneratePDF}>
            Download PDF
          </a>
        </div>
      )}
    </div>
  );
};

export default DownloadDropdown;
