import React, { useState, useEffect } from "react";
import "./AccountNameModal.css"; // Make sure to update CSS for the new modal style

import { getFirestore, doc, getDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
const AccountNameModal = ({
  accountName,
  setAccountName,
  onSubmit,
  validationError,
  currentUser,
}) => {
  const [agree, setAgree] = useState(false);
  const [isSubUserState, setIsSubUserState] = useState(null);
  const [mainUserId, setMainUserId] = useState(null);
  const [showErrorModal, setShowErrorModal] = useState(false);

  useEffect(() => {
    const checkUserRole = async () => {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        const firestore = getFirestore();
        const userRef = doc(firestore, "users", user.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setIsSubUserState(userData.role === "sub-user");
          setMainUserId(userData.mainUserId || null);
        } else {
          setIsSubUserState(false);
        }
      } else {
        setIsSubUserState(false);
      }
    };

    checkUserRole();
  }, [currentUser]);

  useEffect(() => {
    if (validationError) {
      setShowErrorModal(true); // Show modal when there's a validation error
      setTimeout(() => setShowErrorModal(false), 3000); // Auto-close modal after 3 seconds
    }
  }, [validationError]);

  // Function to handle changes to the agree checkbox
  const handleAgreeChange = (event) => {
    setAgree(event.target.checked);
  };

  const resetErrorModal = () => {
    setShowErrorModal(false);
    setTimeout(() => {
      setShowErrorModal(true);
    }, 100); // Short delay to reset the state effectively
  };

  const handleFormSubmit = () => {
    // Only check the account name if it's not a sub-user.
    if (!isSubUserState) {
      if (!accountName.trim()) {
        setShowErrorModal(true); // Show the modal directly if the account name is empty.
        setTimeout(() => setShowErrorModal(false), 3000); // Auto-close modal after 3 seconds
        return; // Stop further execution to wait for user correction.
      }
    }
    // Proceed with submission if it's a sub-user or the account name is not empty.
    onSubmit(isSubUserState, mainUserId);
  };

  return (
    <div className="modal-content">
      {showErrorModal && !isSubUserState && (
        <div className="validation-modal">
          <div className="validation-modal-content">
            <h4>Account name cannot be empty</h4>
            <p>
              Please enter your Name or Company Name at the top of this page
            </p>
            <button onClick={() => setShowErrorModal(false)}>OK</button>
          </div>
        </div>
      )}
      {!isSubUserState && (
        <>
          <h2>Please Provide Your Name or Company Name</h2>
          <input
            type="text"
            placeholder="Name or Company Name"
            value={accountName}
            onChange={(e) => setAccountName(e.target.value)}
            className="inputBox"
          />
        </>
      )}
      <div className="terms-container">
        <div className="toc">
          <ul>
            <li>
              <a href="#terms">Terms and Conditions</a>
            </li>
            <li>
              <a href="#baa">Business Associate Agreement (BAA)</a>
            </li>
          </ul>
        </div>
        <div className="terms-content">
          <h2 id="terms">Terms and Conditions</h2>
          <p>
            Welcome to FormVox. By using our services, you agree to comply with
            and be bound by the following terms and conditions. Please read them
            carefully.
          </p>

          <h3>HIPAA Compliance</h3>
          <p>
            FormVox adheres to the Health Insurance Portability and
            Accountability Act (HIPAA) standards to ensure the confidentiality,
            integrity, and security of Protected Health Information (PHI). We
            take all necessary measures to comply with HIPAA regulations and
            maintain a Business Associate Agreement (BAA) to cover the data
            managed directly within our platform.
          </p>

          <h3>
            Storage and Security of Personally Identifiable Information (PII)
          </h3>
          <p>
            We prioritize the security of your Personally Identifiable
            Information (PII). All PII is stored securely using
            industry-standard security measures. We do not share, sell, or
            disclose your data to any third parties, except as required by law
            or as necessary to provide our services.
          </p>

          <h3>Data Usage and Audio Recordings</h3>
          <p>
            Audio recordings of calls are used solely for the purpose of
            transcription. These recordings are not saved or stored after the
            transcription is completed. Your data privacy is of utmost
            importance to us.
          </p>

          <h3>Encryption</h3>
          <p>
            All data handled by FormVox is encrypted both at rest and in
            transit. This means that your data is securely stored on our servers
            and securely transmitted between your device and our servers.
          </p>

          <h3>User Responsibilities</h3>
          <p>
            As a user of FormVox, you are responsible for maintaining your own
            best practices for security. This includes, but is not limited to,
            using strong passwords, regularly updating your software, and being
            cautious about sharing your login credentials.
          </p>
          <p>
            If you are using the Google Drive integration, it is your
            responsibility to ensure that you use a Google account that complies
            with HIPAA standards. This typically requires a paid Google
            Workspace account. FormVox is not responsible for any data breaches
            or non-compliance issues that arise from using a non-compliant
            Google Drive account.
          </p>

          <h3>Data Retention and Deletion</h3>

          <p>
            FormVox retains your data only for as long as necessary to provide
            our services or as required by law. You may request the deletion of
            your data at any time by contacting our support team. Upon such a
            request, we will promptly delete your data from our systems, except
            for any data we are required to retain by law.
          </p>

          <h3>Compliance with Laws</h3>
          <p>
            By using FormVox, you agree to comply with all applicable local,
            state, national, and international laws and regulations. It is your
            responsibility to ensure that your use of our services does not
            violate any laws or regulations.
          </p>

          <h3>Limitation of Liability</h3>
          <p>
            FormVox shall not be liable for any direct, indirect, incidental,
            special, consequential, or punitive damages arising out of your use
            of our services. This includes, but is not limited to, any errors or
            omissions in the content provided, loss of data, or any unauthorized
            access to or alteration of your data.
          </p>

          <h3>Modifications to Terms and Conditions</h3>
          <p>
            FormVox reserves the right to modify these terms and conditions at
            any time. Any changes will be posted on our website, and it is your
            responsibility to review these terms regularly. Continued use of our
            services after any changes constitutes your acceptance of the new
            terms and conditions.
          </p>

          <h3>Governing Law</h3>
          <p>
            These terms and conditions shall be governed by and construed in
            accordance with the laws of the jurisdiction in which FormVox
            operates, without regard to its conflict of law principles.
          </p>

          <h3>Contact Information</h3>
          <p>
            If you have any questions or concerns about these terms and
            conditions, please contact us at contact@formvox.com.
          </p>
          <p>
            By using our services, you acknowledge that you have read,
            understood, and agree to be bound by these terms and conditions.
          </p>
          <p>
            For more details on our privacy practices, please review our{" "}
            <a
              href="/privacy_policy.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
            .
          </p>

          <h2 id="baa">Business Associate Agreement (BAA)</h2>
          <p>
            This Business Associate Agreement ("Agreement") is entered into by
            and between FormVox ("Business Associate") and you, the user
            ("Covered Entity"). This Agreement is effective as of the date you
            agree to the Terms and Conditions and use FormVox's services.
          </p>

          <h3>Definitions</h3>
          <p>
            Terms used but not otherwise defined in this Agreement shall have
            the same meaning as those terms in the HIPAA Rules.
          </p>
          <p>
            (a) Business Associate: FormVox, providing services involving the
            use and disclosure of PHI.
          </p>
          <p>
            (b) Covered Entity: You, the user, using FormVox's services that
            involve the use and disclosure of PHI.
          </p>

          <h3>Obligations and Activities of Business Associate</h3>
          <p>
            (a) Business Associate agrees to not use or disclose PHI other than
            as permitted or required by the Agreement or as required by law.
          </p>
          <p>
            (b) Business Associate agrees to use appropriate safeguards to
            prevent use or disclosure of PHI other than as provided for by the
            Agreement.
          </p>
          <p>
            (c) Business Associate agrees to mitigate, to the extent
            practicable, any harmful effect that is known to Business Associate
            of a use or disclosure of PHI by Business Associate in violation of
            the requirements of this Agreement.
          </p>
          <p>
            (d) Business Associate agrees to report to Covered Entity any use or
            disclosure of PHI not provided for by the Agreement of which it
            becomes aware, including breaches of unsecured PHI.
          </p>
          <p>
            (e) Business Associate agrees to ensure that any agent, including a
            subcontractor, to whom it provides PHI received from, or created or
            received by Business Associate on behalf of Covered Entity agrees to
            the same restrictions and conditions that apply through this
            Agreement to Business Associate with respect to such information.
          </p>
          <p>
            (f) Business Associate agrees to make available PHI in a designated
            record set to Covered Entity as necessary to satisfy Covered
            Entity's obligations under 45 CFR 164.524.
          </p>
          <p>
            (g) Business Associate agrees to make any amendment(s) to PHI in a
            designated record set as directed or agreed to by Covered Entity
            pursuant to 45 CFR 164.526, or take other measures as necessary to
            satisfy Covered Entity's obligations under 45 CFR 164.526.
          </p>
          <p>
            (h) Business Associate agrees to maintain and make available the
            information required to provide an accounting of disclosures to
            Covered Entity as necessary to satisfy Covered Entity's obligations
            under 45 CFR 164.528.
          </p>
          <p>
            (i) Business Associate agrees to comply with the requirements of 45
            CFR 164.502(e)(1)(ii) and 164.308(b)(2) if applicable to ensure that
            any subcontractors that create, receive, maintain, or transmit
            electronic protected health information on behalf of the Business
            Associate agree to comply with the applicable requirements of the
            HIPAA Rules by entering into a contract or other arrangement that
            complies with the HIPAA Rules.
          </p>

          <h3>Permitted Uses and Disclosures by Business Associate</h3>
          <p>
            (a) Business Associate may only use or disclose PHI as necessary to
            perform the services set forth in the Terms and Conditions.
          </p>
          <p>
            (b) Business Associate may use or disclose PHI as required by law.
          </p>
          <p>
            (c) Business Associate agrees to make uses and disclosures and
            requests for PHI consistent with Covered Entity's minimum necessary
            policies and procedures.
          </p>
          <p>
            (d) Business Associate may not use or disclose PHI in a manner that
            would violate Subpart E of 45 CFR Part 164 if done by Covered Entity
            except for the specific uses and disclosures set forth above.
          </p>

          <h3>Term and Termination</h3>
          <p>
            (a) Term. The Term of this Agreement shall commence as of the
            effective date and shall terminate when all of the PHI provided by
            Covered Entity to Business Associate, or created or received by
            Business Associate on behalf of Covered Entity, is destroyed or
            returned to Covered Entity, or, if it is infeasible to return or
            destroy PHI, protections are extended to such information, in
            accordance with the termination provisions in this Section.
          </p>
          <p>
            (b) Termination for Cause. Business Associate authorizes termination
            of this Agreement by Covered Entity, if Covered Entity determines
            Business Associate has violated a material term of the Agreement.
          </p>
          <p>
            (c) Obligations of Business Associate Upon Termination. Upon
            termination of this Agreement, for any reason, Business Associate
            shall return or destroy all PHI received from Covered Entity, or
            created or received by Business Associate on behalf of Covered
            Entity that Business Associate still maintains in any form. Business
            Associate shall retain no copies of the PHI. If return or
            destruction of the PHI is infeasible, Business Associate shall
            extend the protections of this Agreement to such information and
            limit further uses and disclosures to those purposes that make the
            return or destruction infeasible, for so long as Business Associate
            maintains such PHI.
          </p>

          <h3>Miscellaneous</h3>
          <p>
            (a) Regulatory References. A reference in this Agreement to a
            section in the HIPAA Rules means the section as in effect or as
            amended.
          </p>
          <p>
            (b) Amendment. The Parties agree to take such action as is necessary
            to amend this Agreement from time to time as is necessary for
            Covered Entity to comply with the requirements of the HIPAA Rules
            and any other applicable law.
          </p>
          <p>
            (c) Interpretation. Any ambiguity in this Agreement shall be
            interpreted to permit compliance with the HIPAA Rules.
          </p>
          <p className="terms-bottom">
            By agreeing to the Terms and Conditions and using our services, you
            are also entering into this Business Associate Agreement with
            FormVox.
          </p>
        </div>
        <div className="a-scroll-stop"></div>
      </div>
      <div className="a-bottom">
        <label className="agree-checkbox">
          <input type="checkbox" checked={agree} onChange={handleAgreeChange} />
          I Agree
        </label>
        <button onClick={handleFormSubmit} disabled={!agree}>
          Agree and Close
        </button>
      </div>
    </div>
  );
};

export default AccountNameModal;
