import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer, toast } from "react-toastify";

import {
  faEdit,
  faTrash,
  faInfoCircle,
  faChevronDown,
  faChevronUp,
  faCircle,
  faDownload,
  faAdd,
} from "@fortawesome/free-solid-svg-icons";
import ConfirmationModal from "./ConfirmationModal";
import "./CreateFormModal.css";
import {
  getFirestore,
  collection,
  addDoc,
  updateDoc,
  doc,
  deleteDoc,
  getDoc,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import googleDriveLogo from "../assets/logo_drive_2020q4_color_2x_web_64dp.png";
import oneDriveLogo from "../assets/128px-Microsoft_Office_OneDrive_2019–present.png";

const CreateFormModal = ({ onClose, editForm, phoneNumber, currentUser }) => {
  console.log("HERE IS PHONE NUMBER NOW **** " + phoneNumber);

  const [isAddingQuestion, setIsAddingQuestion] = useState(false);

  const [isConnectedToGoogleDrive, setIsConnectedToGoogleDrive] =
    useState(false);

  const [isManualInput, setIsManualInput] = useState(false);
  const [formName, setFormName] = useState(editForm ? editForm.formName : "");
  const [formNameLocked, setFormNameLocked] = useState(!!editForm);
  const fixedWelcomeMessagePart = `Please press the pound or hash key after you are done answering each question. Each of your responses should be less than 1-minute long. Are you ready to begin?`;

  const removeFixedPart = (message) => {
    return message.replace(fixedWelcomeMessagePart, "").trim();
  };

  const [welcomeMessageEditable, setWelcomeMessageEditable] = useState(
    editForm
      ? removeFixedPart(editForm.welcomeMessage)
      : `Great let's get started. I am a chatbot. We’ve found that this is the best way to get all the information we need. Any information you provide is confidential. If you get disconnected at anytime just let us know and we will reconnect you.  Or if you dialed this number yourself you can simply call back again and pick up where you left off.\n\nPlease answer all the questions that I will be asking as completely as possible. If you do not know or remember the answers, please just tell me that.`
  );

  const [isEditingWelcomeMessage, setIsEditingWelcomeMessage] = useState(false);
  const [isWelcomeMessageExpanded, setIsWelcomeMessageExpanded] =
    useState(false);
  const [conclusionMessageEditable, setConclusionMessageEditable] = useState(
    editForm
      ? editForm.conclusionMessage
      : `Thank you very much for the information you’ve provided, we will be calling you to go through your answers and to get additional information if needed.`
  );
  const [isEditingConclusionMessage, setIsEditingConclusionMessage] =
    useState(false);
  const [isConclusionMessageExpanded, setIsConclusionMessageExpanded] =
    useState(false);
  const [questions, setQuestions] = useState(
    editForm
      ? editForm.questions
      : [{ text: "", type: "", condition: { yes: "", no: "" }, locked: false }]
  );
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [autoScroll, setAutoScroll] = useState(true);

  const [deleteQuestionIndex, setDeleteQuestionIndex] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [formToDelete, setFormToDelete] = useState(null);
  const [errorMessages, setErrorMessages] = useState(
    editForm
      ? editForm.questions.map((q) => ({
          text: !q.text,
          type: !q.type,
          customYes: q.condition.yes === "custom" && !q.condition.customYes,
          customNo: q.condition.no === "custom" && !q.condition.customNo,
        }))
      : [{ text: false, type: false, customYes: false, customNo: false }]
  );
  const [formNameError, setFormNameError] = useState(false);

  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return "";
    const country = phoneNumber.substring(0, 1);
    const area = phoneNumber.substring(1, 4);
    const prefix = phoneNumber.substring(4, 7);
    const line = phoneNumber.substring(7, 11);
    return `${country} (${area}) ${prefix}-${line}`;
  };

  const modalContentRef = useRef(null);

  useEffect(() => {
    console.log("HELLO " + JSON.stringify(currentUser));
    const fetchUserDetails = async () => {
      const auth = getAuth();
      const user = auth.currentUser;

      const actualUser =
        currentUser.mainUserId && currentUser.mainUserId.trim() !== ""
          ? currentUser.mainUserId
          : user.uid;

      if (user) {
        const firestore = getFirestore();
        const userRef = doc(firestore, "users", actualUser);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists() && userSnap.data().googleDriveTokens) {
          setIsConnectedToGoogleDrive(true);
        }
      }
    };

    fetchUserDetails();
  }, []);

  useEffect(() => {
    if (editForm) {
      setIsManualInput(true);
      setIsWelcomeMessageExpanded(true);
      setIsConclusionMessageExpanded(true);
    }
  }, [editForm]);

  useEffect(() => {
    if (autoScroll && modalContentRef.current) {
      modalContentRef.current.scrollTo({
        top: modalContentRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [
    questions,
    isWelcomeMessageExpanded,
    isConclusionMessageExpanded,
    autoScroll,
  ]);

  const handleConclusionMessageEdit = () => {
    setIsEditingConclusionMessage(true);
  };

  const handleConclusionMessageSave = () => {
    setIsEditingConclusionMessage(false);
  };

  const handleConclusionMessageChange = (e) => {
    setConclusionMessageEditable(e.target.value);
  };

  const handleUploadCSV = () => {
    // Functionality to handle CSV upload
    alert("CSV upload functionality coming soon!");
  };

  const handleManualInput = () => {
    setIsManualInput(true);
  };

  const handleFormNameSave = () => {
    if (!formName) {
      setFormNameError(true);
      return;
    }
    setFormNameLocked(true);
    setFormNameError(false);
  };

  const handleFormNameEdit = () => {
    setFormNameLocked(false);
  };

  const handleWelcomeMessageEdit = () => {
    setIsEditingWelcomeMessage(true);
  };

  const handleWelcomeMessageSave = () => {
    setIsEditingWelcomeMessage(false);
  };

  const handleWelcomeMessageChange = (e) => {
    setWelcomeMessageEditable(e.target.value);
  };

  const handleQuestionChange = (index, field, value) => {
    const newQuestions = [...questions];
    newQuestions[index][field] = value;
    setQuestions(newQuestions);

    const newErrorMessages = [...errorMessages];
    newErrorMessages[index] = {
      ...newErrorMessages[index],
      [field]: !value,
    };
    setErrorMessages(newErrorMessages);
  };

  const handleConditionChange = (index, response, value) => {
    const newQuestions = [...questions];
    newQuestions[index].condition[response] = value || "";
    setQuestions(newQuestions);

    const newErrorMessages = [...errorMessages];
    newErrorMessages[index] = {
      ...newErrorMessages[index],
      [`custom${response.charAt(0).toUpperCase() + response.slice(1)}`]: !value,
    };
    setErrorMessages(newErrorMessages);
  };

  const handleCancelAddQuestion = () => {
    if (questions.length > 0) {
      setQuestions(questions.slice(0, -1)); // Remove the last question
    }
    setIsAddingQuestion(false);
  };

  const handleAddQuestion = () => {
    setIsAddingQuestion(true);
    const hasEmptyFields = questions.some(
      (q) =>
        !q.text ||
        !q.type ||
        (q.condition.yes === "custom" && !q.condition.customYes) ||
        (q.condition.no === "custom" && !q.condition.customNo)
    );
    if (hasEmptyFields) {
      const newErrorMessages = questions.map((q) => ({
        text: !q.text,
        type: !q.type,
        customYes: q.condition.yes === "custom" && !q.condition.customYes,
        customNo: q.condition.no === "custom" && !q.condition.customNo,
      }));
      setErrorMessages(newErrorMessages);
      return;
    }

    setQuestions([
      ...questions,
      {
        text: "",
        type: "",
        condition: { yes: "", no: "" },
        locked: false,
      },
    ]);
    setErrorMessages([
      ...errorMessages,
      { text: false, type: false, customYes: false, customNo: false },
    ]);

    if (modalContentRef.current) {
      modalContentRef.current.scrollTo({
        top: modalContentRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  const handleSave = async () => {
    if (
      !allFieldsFilled() ||
      !welcomeMessageEditable.trim() ||
      !conclusionMessageEditable.trim()
    ) {
      const newErrorMessages = questions.map((q) => ({
        text: !q.text,
        type: !q.type,
        customYes: q.condition.yes === "custom" && !q.condition.customYes,
        customNo: q.condition.no === "custom" && !q.condition.customNo,
      }));
      setErrorMessages(newErrorMessages);

      if (!formName) setFormNameError(true);
      if (!welcomeMessageEditable.trim())
        toast.error("Welcome message cannot be empty");
      if (!conclusionMessageEditable.trim())
        toast.error("Conclusion message cannot be empty");
      return;
    }

    // Clean up the welcome message
    let cleanedWelcomeMessage = removeFixedPart(welcomeMessageEditable).trim();

    // Ensure there are no leading or trailing spaces in the lines
    cleanedWelcomeMessage = cleanedWelcomeMessage
      .split("\n")
      .map((line) => line.trim())
      .join("\n");

    const formData = {
      formName,
      welcomeMessage: cleanedWelcomeMessage + "\n\n" + fixedWelcomeMessagePart,
      conclusionMessage: conclusionMessageEditable,
      questions,
      createdOn: new Date(),
    };

    try {
      const auth = getAuth();
      const user = auth.currentUser;
      const firestore = getFirestore();

      let userId = user.uid;
      const userDocRef = doc(firestore, "users", userId);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        if (userData.role === "sub-user" && userData.mainUserId) {
          userId = userData.mainUserId;
        }
      }

      if (user) {
        if (editForm) {
          // Update existing form
          const formRef = doc(firestore, "users", userId, "forms", editForm.id);
          await updateDoc(formRef, formData);
          toast.success("Form updated successfully!");
        } else {
          // Add new form
          await addDoc(
            collection(firestore, "users", userId, "forms"),
            formData
          );
          toast.success("Form saved successfully!");
        }
        onClose();
      } else {
        toast.error("You must be logged in to save the form.");
      }
    } catch (error) {
      console.error("Error saving form: ", error);
      toast.error("Failed to save the form. Please try again.");
    }
  };

  const handleDelete = (form) => {
    setFormToDelete(form);
    setShowDeleteModal(true);
  };

  const confirmDeleteForm = async () => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      const firestore = getFirestore();

      let userId = user.uid;
      const userDocRef = doc(firestore, "users", userId);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        if (userData.role === "sub-user" && userData.mainUserId) {
          userId = userData.mainUserId;
        }
      }

      if (user && formToDelete) {
        const formRef = doc(
          firestore,
          "users",
          userId,
          "forms",
          formToDelete.id
        );
        await deleteDoc(formRef);
        toast.success("Form deleted successfully!");
        setShowDeleteModal(false);
        onClose();
      } else {
        toast.error("You must be logged in to delete the form.");
      }
    } catch (error) {
      console.error("Error deleting form: ", error);
      toast.error("Failed to delete the form. Please try again.");
    }
  };

  const handleCancel = () => {
    setShowCancelModal(true);
  };

  const confirmCancel = () => {
    setShowCancelModal(false);
    onClose();
  };

  const now = new Date();
  const options = { month: "long", year: "numeric" };
  const formattedDate = now.toLocaleDateString("en-US", options);

  const handleLockQuestion = (index) => {
    const question = questions[index];
    if (
      !question.text ||
      !question.type ||
      (question.condition.yes === "custom" && !question.condition.customYes) ||
      (question.condition.no === "custom" && !question.condition.customNo)
    ) {
      const newErrorMessages = [...errorMessages];
      newErrorMessages[index] = {
        text: !question.text,
        type: !question.type,
        customYes:
          question.condition.yes === "custom" && !question.condition.customYes,
        customNo:
          question.condition.no === "custom" && !question.condition.customNo,
      };
      setErrorMessages(newErrorMessages);
      return;
    }
    const newQuestions = [...questions];
    newQuestions[index].locked = !newQuestions[index].locked;
    setQuestions(newQuestions);
  };

  const handleDeleteQuestion = (index) => {
    setDeleteQuestionIndex(index);
  };

  const confirmDeleteQuestion = () => {
    const newQuestions = questions.filter(
      (_, qIndex) => qIndex !== deleteQuestionIndex
    );
    setQuestions(newQuestions);
    setErrorMessages(
      errorMessages.filter((_, qIndex) => qIndex !== deleteQuestionIndex)
    );
    setDeleteQuestionIndex(null);
  };

  const allFieldsFilled = () => {
    if (!formName) return false;
    if (!welcomeMessageEditable.trim()) return false;
    if (!conclusionMessageEditable.trim()) return false;

    for (let i = 0; i < questions.length; i++) {
      const question = questions[i];
      if (
        !question.text ||
        !question.type ||
        (question.condition.yes === "custom" &&
          !question.condition.customYes) ||
        (question.condition.no === "custom" && !question.condition.customNo)
      ) {
        return false;
      }
    }
    return true;
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content" ref={modalContentRef}>
        <h2 className="top">
          {editForm ? "Edit Client Form" : "Create New Client Form"}
        </h2>
        {!formNameLocked ? (
          <div className="form-name-input-container">
            <input
              type="text"
              placeholder="Enter name for client form"
              value={formName}
              onChange={(e) => setFormName(e.target.value)}
              className="form-name-input"
            />
            <button onClick={handleFormNameSave}>Save</button>
            {formNameError && (
              <p className="error-message">Please enter a form name</p>
            )}
          </div>
        ) : (
          <div className="saved-form-name">
            <span>{formName}</span>
            <FontAwesomeIcon
              icon={faEdit}
              onClick={handleFormNameEdit}
              className="edit-icon"
            />
          </div>
        )}
        <div className="welcome-message-container">
          <p className="left">
            <span className="numbers">Step 1.</span> When you transfer
            respondent or have them call {formatPhoneNumber(phoneNumber)} the
            following welcome message plays:
          </p>
          <div className="welcome-message-dropdown">
            <div
              className="welcome-message-header"
              onClick={() =>
                setIsWelcomeMessageExpanded(!isWelcomeMessageExpanded)
              }
            >
              <label>Welcome Message</label>
              <FontAwesomeIcon
                icon={isWelcomeMessageExpanded ? faChevronUp : faChevronDown}
              />
            </div>
            {isWelcomeMessageExpanded && (
              <div className="welcome-message-content">
                {isEditingWelcomeMessage ? (
                  <>
                    <div className="welcome-message-editable-container">
                      <textarea
                        className="welcome-message-input"
                        value={welcomeMessageEditable}
                        onChange={handleWelcomeMessageChange}
                      />
                      <div className="fixed-message">
                        {fixedWelcomeMessagePart}
                      </div>
                    </div>
                    <button onClick={handleWelcomeMessageSave}>Save</button>
                  </>
                ) : (
                  <>
                    <p className="welcome-message">
                      <span className="welcomeMessageInto">
                        <div className="info-box">
                          <ul className="info-list">
                            <li>
                              <FontAwesomeIcon
                                icon={faInfoCircle}
                                className="info-icon"
                              />
                              The section in bold prompts the user to begin and
                              cannot be modified or deleted.
                            </li>
                          </ul>
                        </div>
                      </span>
                      <br />
                      <br />
                      {welcomeMessageEditable.split("\n").map((line, index) => (
                        <React.Fragment key={index}>
                          {line}
                          <br />
                        </React.Fragment>
                      ))}
                      <br />
                      <b>{fixedWelcomeMessagePart}</b>
                    </p>
                    <div
                      onClick={handleWelcomeMessageEdit}
                      className="edit-icon-container"
                    >
                      <FontAwesomeIcon icon={faEdit} className="edit-icon" />
                      Edit Welcome Message
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </div>

        {!isManualInput && (
          <>
            <p className="left">
              <span className="numbers">Step 2.</span>
              After the respondent confirms they are ready the following
              question(s) begin:
            </p>
            {/*<p>How do you want to provide the questions?</p>*/}
            <div className="button-group">
              {/*   <button onClick={handleUploadCSV}>Upload CSV</button>  */}
              <button onClick={handleManualInput}>
                {" "}
                <FontAwesomeIcon icon={faAdd} /> Add Questions
              </button>
            </div>
          </>
        )}
        {isManualInput && (
          <div className="manual-input-section">
            <p className="left">
              After the respondent confirms they are ready the following
              question(s) begin:
            </p>
            {questions.map((question, index) => (
              <div key={index} className="question-input">
                {!question.locked ? (
                  <>
                    <input
                      type="text"
                      placeholder={`Enter question #${index + 1}`}
                      value={question.text}
                      onChange={(e) =>
                        handleQuestionChange(index, "text", e.target.value)
                      }
                    />
                    {errorMessages[index].text && (
                      <p className="error-message">
                        This field cannot be blank
                      </p>
                    )}
                    <select
                      value={question.type}
                      onChange={(e) =>
                        handleQuestionChange(index, "type", e.target.value)
                      }
                      required
                    >
                      <option value="" disabled hidden>
                        Choose Question Type
                      </option>
                      <option value="Firstname">
                        First name (confirms spelling)
                      </option>
                      <option value="Lastname">
                        Last name (confirms spelling)
                      </option>

                      <option value="Yes/No">
                        Yes/No (Conditional Follow-ups)
                      </option>
                      <option value="Open">
                        Open-Ended (Use this for all other question types)
                      </option>
                    </select>
                    {errorMessages[index].type && (
                      <p className="error-message">Please make a selection</p>
                    )}
                    {question.type === "Yes/No" && (
                      <div className="conditional-questions">
                        <p>If client responds "Yes":</p>
                        <select
                          value={question.condition.yes}
                          onChange={(e) =>
                            handleConditionChange(index, "yes", e.target.value)
                          }
                        >
                          <option value="next">Proceed to next question</option>
                          <option value="custom">
                            Ask this question (specify)
                          </option>
                        </select>
                        {question.condition.yes === "custom" && (
                          <>
                            <input
                              className="followUpQuestion"
                              type="text"
                              placeholder="Enter follow-up question"
                              value={question.condition.customYes || ""}
                              onChange={(e) =>
                                handleConditionChange(
                                  index,
                                  "customYes",
                                  e.target.value
                                )
                              }
                            />
                            {errorMessages[index].customYes && (
                              <p className="error-message">
                                This field cannot be blank
                              </p>
                            )}
                          </>
                        )}
                        <p>If client responds "No":</p>
                        <select
                          value={question.condition.no}
                          onChange={(e) =>
                            handleConditionChange(index, "no", e.target.value)
                          }
                        >
                          <option value="next">Proceed to next question</option>
                          <option value="custom">
                            Ask this question (specify)
                          </option>
                        </select>
                        {question.condition.no === "custom" && (
                          <>
                            <input
                              className="followUpQuestion"
                              type="text"
                              placeholder="Enter follow-up question"
                              value={question.condition.customNo || ""}
                              onChange={(e) =>
                                handleConditionChange(
                                  index,
                                  "customNo",
                                  e.target.value
                                )
                              }
                            />
                            {errorMessages[index].customNo && (
                              <p className="error-message">
                                This field cannot be blank
                              </p>
                            )}
                          </>
                        )}
                      </div>
                    )}
                  </>
                ) : (
                  <div className="saved-question">
                    <div className="question-text">
                      <p>{`Question #${index + 1}: ${question.text}`}</p>
                      <p>{`Type: ${question.type}`}</p>
                      {question.type === "Yes/No" && (
                        <div className="conditional-summary">
                          <p>{`If Yes: ${
                            question.condition.yes === "custom"
                              ? question.condition.customYes
                              : "Proceed to next question"
                          }`}</p>
                          <p>{`If No: ${
                            question.condition.no === "custom"
                              ? question.condition.customNo
                              : "Proceed to next question"
                          }`}</p>
                        </div>
                      )}
                    </div>
                    <div className="question-icons">
                      <FontAwesomeIcon
                        icon={faEdit}
                        onClick={() => handleLockQuestion(index)}
                        className="edit-icon"
                      />
                      <FontAwesomeIcon
                        icon={faTrash}
                        onClick={() => handleDeleteQuestion(index)}
                        className="delete-icon"
                      />
                    </div>
                  </div>
                )}
                {!question.locked && (
                  <button onClick={() => handleLockQuestion(index)}>
                    Save
                  </button>
                )}
              </div>
            ))}
            <button
              onClick={() => {
                handleAddQuestion();
                setIsAddingQuestion(true);
              }}
            >
              Add New Question
            </button>
            {isAddingQuestion && (
              <button
                className="remove-question"
                onClick={handleCancelAddQuestion}
              >
                Remove Last Question
              </button>
            )}
          </div>
        )}

        <div className="conclusion-message-container">
          <p className="left">
            <span className="numbers">Step 3.</span>After all questions are
            answered the following outgoing message is played:
          </p>
          <div className="conclusion-message-dropdown">
            <div
              className="conclusion-message-header"
              onClick={() =>
                setIsConclusionMessageExpanded(!isConclusionMessageExpanded)
              }
            >
              <label>Conclusion Message</label>
              <FontAwesomeIcon
                icon={isConclusionMessageExpanded ? faChevronUp : faChevronDown}
              />
            </div>

            {isConclusionMessageExpanded && (
              <div className="conclusion-message-content">
                {isEditingConclusionMessage ? (
                  <>
                    <textarea
                      className="conclusion-message-input"
                      value={conclusionMessageEditable}
                      onChange={handleConclusionMessageChange}
                    />
                    <button onClick={handleConclusionMessageSave}>Save</button>
                  </>
                ) : (
                  <>
                    <p className="conclusion-message">
                      {conclusionMessageEditable
                        .split("\n")
                        .map((line, index) => (
                          <React.Fragment key={index}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))}
                    </p>
                    <div
                      onClick={handleConclusionMessageEdit}
                      className="edit-icon-container"
                    >
                      <FontAwesomeIcon icon={faEdit} className="edit-icon" />
                      Edit Conclusion Message
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </div>

        <div className="transcript-handling-container">
          <p className="left">
            <span className="numbers">Step 4.</span> Once the form is completed,
            the transcript will be available for download:
          </p>
          <div className="transcript-options">
            <div className="transcript-option">
              <FontAwesomeIcon icon={faDownload} className="option-icon" />
              <p>Download directly from FormVox.</p>
            </div>
            {isConnectedToGoogleDrive ? (
              <div className="transcript-option">
                <img
                  src={googleDriveLogo}
                  alt="Google Drive"
                  className="option-icon"
                />
                <p>Google Drive folder "FormVox {formattedDate}".</p>
              </div>
            ) : (
              <div className="transcript-option">
                <img
                  src={googleDriveLogo}
                  alt="Google Drive"
                  className="option-icon"
                />
                <p>Go to Integrations to connect Google Drive.</p>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="bottom-buttons">
        <div className="auto-scroll">
          <label>
            <input
              type="checkbox"
              checked={autoScroll}
              onChange={() => setAutoScroll(!autoScroll)}
            />
            Enable Auto-Scroll
          </label>
        </div>
        <button className="save-complete-btn" onClick={handleSave}>
          {editForm ? "Update Form" : "Save and Complete Form"}
        </button>
        <button className="close-btn" onClick={handleCancel}>
          {isManualInput ? "Cancel" : "Close"}
        </button>
      </div>
      {showCancelModal && (
        <ConfirmationModal
          message="Are you sure you want to cancel? All changes will be lost."
          onClose={() => setShowCancelModal(false)}
          onConfirm={confirmCancel}
        />
      )}
      {showDeleteModal && (
        <ConfirmationModal
          message="Are you sure you want to delete this form?"
          onClose={() => setShowDeleteModal(false)}
          onConfirm={confirmDeleteForm}
        />
      )}
      {deleteQuestionIndex !== null && (
        <ConfirmationModal
          message="Are you sure you want to delete this question?"
          onClose={() => setDeleteQuestionIndex(null)}
          onConfirm={confirmDeleteQuestion}
        />
      )}
    </div>
  );
};

export default CreateFormModal;
