import React, { useState, useRef } from "react";
import { motion } from "framer-motion";

import { Link, useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Button,
  Typography,
  Container,
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Slider,
  Card,
  CardMedia,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

import CallIcon from "@mui/icons-material/Call";
import GavelIcon from "@mui/icons-material/Gavel";

import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import ChatIcon from "@mui/icons-material/Chat";
import DescriptionIcon from "@mui/icons-material/Description";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote"; // For the testimonial

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faBolt,
  faChevronUp,
  faBalanceScale,
  faCheckCircle,
  faArrowRight,
  faRandom,
  faExchangeAlt,
  faFileAlt,
  faUsers,
  faBriefcase,
  faMobileAlt,
  faCloudUploadAlt,
  faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons";

import { Menu as MenuIcon } from "@mui/icons-material";
import ReactPlayer from "react-player";
import Modal from "./LandingPageModal";
import logo from "../assets/logo.png";

import questionImage from "../assets/questions.png";
import questionImage2 from "../assets/questions2.png";
import workflowImage from "../assets/workflow.png";
import womanOnPhoneImage from "../assets/on-phone.jpeg";
import inmateImage from "../assets/inmate.png";
import phoneImage from "../assets/phone.png";
import hiringManager from "../assets/hiring-manager.jpeg";
import heroImage from "../assets/hero.jpg";

import image1 from "../assets/man-on-phone.png"; // Replace with your first image
import image2 from "../assets/superpower.png"; // Replace with your second image

import placeholderImage from "../assets/image001b.png";
import placeholderImage2 from "../assets/image002b.png";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const HrLandingPage = () => {
  const [activeStep, setActiveStep] = useState(0);
  const handleStepChange = (event, newValue) => {
    setActiveStep(newValue);
  };

  const [questions, setQuestions] = useState(5);
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [activeTab, setActiveTab] = useState("contact");
  const faqRef = useRef(null);
  const priceRef = useRef(null);
  const navigate = useNavigate();

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const openModal = (tab) => {
    setActiveTab(tab);
    setModalVisible(true);
  };

  const handleSignUpClick = () => {
    navigate("/signup", { state: { activeTab: "signUp" } });
  };

  const calculateMetrics = (numQuestions) => {
    const timePerForm = 4 + ((numQuestions - 10) / 20) * 3;
    const formsPerHour = Math.floor(60 / timePerForm);
    const costPerForm = (10 / formsPerHour).toFixed(2);

    return {
      timePerForm: `${timePerForm.toFixed(0)} minutes`,
      formsPerHour,
      costPerForm: `$${costPerForm}`,
    };
  };

  const { timePerForm, formsPerHour, costPerForm } =
    calculateMetrics(questions);

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
    closeMenu();
  };
  const faqData = [
    {
      question:
        "How does the voice chatbot assist with inmate case evaluations and forms?",
      answer:
        "The voice chatbot automates the process of gathering case information and completing client forms by conducting phone interviews with inmates. When an inmate calls your office, you can transfer the call to the chatbot, which will guide them through your predefined questions and transcribe their responses.",
    },
    {
      question: "How do I set up custom questionnaires for the chatbot?",
      answer:
        "Using our intuitive dashboard, you can easily create and customize questionnaires or forms. You have full control over the conversation flow, including adding conditional logic and spelling confirmations to ensure accurate data collection.",
    },
    {
      question:
        "Is the data collected secure and compliant with legal standards?",
      answer:
        "Absolutely. We prioritize data security with industry-standard encryption and HIPAA compliance, ensuring all client information is protected and confidential.",
    },
    {
      question:
        "Can the chatbot handle conditional questions based on inmate responses?",
      answer:
        "Yes. Our platform supports conditional logic, allowing the chatbot to ask follow-up questions based on the inmate's previous answers. This ensures a personalized and efficient data-gathering process.",
    },
    {
      question:
        "What if the inmate needs to pause or gets disconnected during the call?",
      answer:
        "If the call is interrupted or the inmate needs to pause, they can call back and resume the interview from where they left off using a secure session PIN. This ensures no information is lost and the process is convenient for both parties.",
    },
    {
      question: "How can I access the information collected by the chatbot?",
      answer:
        "You can access detailed transcripts and completed forms in real-time through your dashboard. The data is available in formats like CSV and PDF for easy review and integration into your case management systems.",
    },
    {
      question: "How is billing calculated for the voice chatbot service?",
      answer:
        "Billing is based on the duration of the calls and the number of questions in the interview. For example, a 5-question interview might take about 3 minutes and cost approximately $0.56. Calls are billed in 30-second increments. You can monitor your usage and billing details in your account dashboard.",
    },
    {
      question: "Can I integrate this tool with my existing legal software?",
      answer:
        "Currently, we offer integration with Google Drive for secure data storage and easy access. For custom integrations with your specific legal software, please contact our support team to discuss your requirements.",
    },
    {
      question:
        "Do I need any special equipment or software to use this service?",
      answer:
        "No special equipment is needed. The voice chatbot operates over the phone, and you can manage everything through our web-based dashboard accessible from any device with internet access.",
    },
    {
      question: "Is there a free trial available?",
      answer:
        "Yes! We offer a free trial with no credit card required. Sign up today to experience how our platform can streamline your inmate communications and enhance your legal practice.",
    },
    {
      question:
        "Can the chatbot confirm the spelling of names and important details?",
      answer:
        "Yes. The chatbot can be configured to include spelling confirmations for first and last names, ensuring that all critical information is captured accurately.",
    },
    {
      question:
        "How does the chatbot ensure compliance with legal and ethical standards?",
      answer:
        "Our platform is designed with legal professionals in mind. We adhere to all relevant legal and ethical standards, including data privacy laws and attorney-client privilege considerations. All interactions are secure and confidential.",
    },
  ];

  return (
    <Box>
      <AppBar position="static">
        <Toolbar>
          <Box
            component="img"
            src={logo}
            alt="FormVox Logo"
            sx={{
              height: { xs: "25px", md: "50px" }, // 25px on mobile, 50px on larger screens
            }}
          />

          <Box sx={{ flexGrow: 1 }} />
          <Button color="inherit" onClick={() => navigate("/login")}>
            Sign In
          </Button>
          <Button variant="contained" onClick={handleSignUpClick}>
            Get Started Free
          </Button>
          <IconButton
            color="inherit"
            edge="end"
            onClick={openMenu}
            aria-controls="simple-menu"
            aria-haspopup="true"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={closeMenu}
          >
            <MenuItem onClick={() => openModal("demo")}>Book a Demo</MenuItem>
            <MenuItem onClick={() => scrollToSection(priceRef)}>
              Pricing
            </MenuItem>
            <MenuItem onClick={() => scrollToSection(faqRef)}>FAQ</MenuItem>
            <MenuItem onClick={() => openModal("contact")}>Contact Us</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>

      <Box
        sx={{
          bgcolor: "#fff",
          py: { xs: 8, sm: 8, md: 8 },
          display: "flex",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <Grid
          container
          spacing={4}
          alignItems="center"
          justifyContent="center"
          sx={{ maxWidth: "1200px" }}
        >
          {/* Text content */}
          <Grid item xs={12} md={8}>
            <Box sx={{ textAlign: { xs: "center", md: "center" } }}>
              <Typography variant="h2" gutterBottom>
                Automate Inmate Interviews with AI Voice Chatbots
              </Typography>
              <Typography variant="h5" gutterBottom>
                Save Time and Securely Gather Case Information Over the Phone
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ textAlign: { xs: "center", md: "center" } }}
              >
                Transform your workflow by automating inmate communication:
              </Typography>

              {/* Icons with labels */}
              <Grid
                container
                spacing={2}
                justifyContent="center"
                sx={{ mt: 4 }}
              >
                {/* Step 1 */}
                <Grid item xs={12} sm={3}>
                  <Box sx={{ textAlign: "center" }}>
                    <CallIcon sx={{ fontSize: 60, color: "primary.main" }} />
                    <Typography variant="subtitle1" sx={{ mt: 1 }}>
                      1. Inmate Calls
                    </Typography>
                  </Box>
                </Grid>
                {/* Step 2 */}
                <Grid item xs={12} sm={3}>
                  <Box sx={{ textAlign: "center" }}>
                    <TransferWithinAStationIcon
                      sx={{ fontSize: 60, color: "primary.main" }}
                    />
                    <Typography variant="subtitle1" sx={{ mt: 1 }}>
                      2. Transfer Call
                    </Typography>
                  </Box>
                </Grid>
                {/* Step 3 */}
                <Grid item xs={12} sm={3}>
                  <Box sx={{ textAlign: "center" }}>
                    <ChatIcon sx={{ fontSize: 60, color: "primary.main" }} />
                    <Typography variant="subtitle1" sx={{ mt: 1 }}>
                      3. Chatbot Interview
                    </Typography>
                  </Box>
                </Grid>
                {/* Step 4 */}
                <Grid item xs={12} sm={3}>
                  <Box sx={{ textAlign: "center" }}>
                    <DescriptionIcon
                      sx={{ fontSize: 60, color: "primary.main" }}
                    />
                    <Typography variant="subtitle1" sx={{ mt: 1 }}>
                      4. Receive Data
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              {/* Call to Action Button */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  py: { xs: 4, md: 2 },
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={handleSignUpClick}
                >
                  Get Started Free
                </Button>
              </Box>
            </Box>
          </Grid>
          {/* Testimonial Section */}
          <Grid item xs={12} md={8}>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true, amount: 0.2 }}
              transition={{ duration: 0.8 }}
            >
              <Box
                sx={{
                  mt: 6,
                  bgcolor: "#f9f9f9",
                  p: 4,
                  borderRadius: 2,
                  textAlign: "center",
                }}
              >
                <FormatQuoteIcon sx={{ fontSize: 40, color: "primary.main" }} />
                <Typography variant="h6" sx={{ mt: 2 }}>
                  This platform has revolutionized how we handle inmate
                  communications. It's a game-changer for our firm."
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{ mt: 2, fontStyle: "italic" }}
                >
                  — Civil Rights Lawyer
                </Typography>
              </Box>
            </motion.div>
          </Grid>
        </Grid>
      </Box>

      {/* Updated Section */}
      <Box
        sx={{
          bgcolor: "#f9fafb", // Slightly different light background
          py: 6,
          px: { xs: 2, md: 4 },
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={6} alignItems="flex-start">
            {/* Left Side (Problem) */}
            <Grid item xs={12} md={6}>
              <motion.div
                initial={{ opacity: 0, x: -50 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true, amount: 0.2 }}
                transition={{ duration: 0.8 }}
              >
                <Typography variant="h4" gutterBottom textAlign="center">
                  Overcoming the Barriers to Inmate Communication
                </Typography>
                <CardMedia
                  component="img"
                  image={inmateImage}
                  alt="Inmate in a cell"
                  sx={{
                    width: "100%",
                    borderRadius: "10px",
                    mb: 4,
                  }}
                />
                <Typography variant="body1" paragraph>
                  <strong>The Challenge:</strong> Connecting with incarcerated
                  clients is time-consuming and inefficient.
                </Typography>
                <Typography variant="body1" paragraph>
                  • <strong>Long Waits:</strong> In-person visits require
                  scheduling and travel.
                  <br />• <strong>Slow Communication:</strong> Mailing documents
                  leads to delays.
                  <br />• <strong>Limited Access:</strong> Phone calls are brief
                  and often interrupted.
                </Typography>
                <Typography variant="h6" sx={{ fontStyle: "italic", mt: 2 }}>
                  "Every moment spent waiting is a moment that could be used to
                  build a stronger case for your client."
                </Typography>
              </motion.div>
            </Grid>

            {/* Right Side (Solution) */}
            {/* Right Side (Solution) */}
            <Grid item xs={12} md={6}>
              <motion.div
                initial={{ opacity: 0, x: 50 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true, amount: 0.2 }}
                transition={{ duration: 0.8 }}
              >
                <Typography variant="h4" gutterBottom textAlign="center">
                  Revolutionize Your Legal Practice with Secure Automation
                </Typography>
                <CardMedia
                  component="img"
                  image={workflowImage}
                  alt="Lawyer using secure automation tools"
                  sx={{
                    width: "100%",
                    borderRadius: "10px",
                    mb: 4,
                  }}
                />
                <Typography variant="body1" paragraph>
                  <strong>Our Solution:</strong> A secure voice chatbot that
                  automates information gathering.
                </Typography>
                <Typography variant="body1" paragraph>
                  • <strong>Efficiency:</strong> Collect detailed case
                  information over the phone without manual effort.
                  <br />• <strong>Accuracy:</strong> Receive complete
                  transcripts and completed forms instantly.
                  <br />• <strong>Security:</strong> Protected with
                  industry-standard encryption and HIPAA compliance.
                </Typography>
                <Typography variant="h6" sx={{ fontStyle: "italic", mt: 2 }}>
                  "Focus on advocating for your clients while we handle the
                  paperwork."
                </Typography>
                {/* Call-to-Action Button */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: 4,
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={handleSignUpClick}
                  >
                    Try For Free
                  </Button>
                </Box>
              </motion.div>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Grid container spacing={4}>
          {/* Image and Caption */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CardMedia
              component="img"
              alt="Chatbot Interface with Rules-Based Control"
              image={questionImage2} // Your screenshot file
              sx={{
                width: "100%",
                height: "auto",
                objectFit: "contain",
                borderRadius: "10px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              }}
            />
            <Typography
              variant="body1"
              paragraph
              sx={{ mt: 2, textAlign: "center", px: 2 }}
            >
              <em>
                See how easy it is to set up your custom chatbot scripts,
                including conditional logic and spelling confirmations, in our
                intuitive interface.
              </em>
            </Typography>
            {/* Call-to-Action Button */}
            <Box sx={{ mt: 2 }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={handleSignUpClick}
              >
                Get Started Free
              </Button>
            </Box>
          </Grid>

          {/* Text Content */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Box>
              <Typography variant="h4" gutterBottom>
                Total Control with Rules-Based Chatbot Conversations
              </Typography>
              <Typography variant="body1" paragraph>
                Design your chatbot interactions exactly as you need them. Our
                platform's rules-based approach gives you complete control over
                the conversation flow, ensuring consistent and predictable
                interactions every time. Unlike AI systems that generate
                unpredictable responses, our solution follows your predefined
                scripts meticulously, so you gather accurate and relevant
                information without surprises.
              </Typography>
              <Typography variant="body1" component="div" paragraph>
                <ul style={{ paddingLeft: "1.2em", margin: 0 }}>
                  <li>
                    <strong>Full Control Over Dialogue:</strong> Customize every
                    question and response, including implementing decision trees
                    and spelling confirmations to fit your specific needs.
                  </li>
                  <li>
                    <strong>Advanced Conversation Flows:</strong> Utilize
                    decision trees to guide clients through personalized paths
                    based on their answers.
                  </li>
                  <li>
                    <strong>Accurate Data Capture:</strong> Ensure precise
                    information by confirming critical details like names
                    through spelling confirmation prompts.
                  </li>
                  <li>
                    <strong>Consistency and Predictability:</strong> Guarantee
                    that every client interaction follows the same reliable
                    script, providing a professional and dependable experience.
                  </li>
                  <li>
                    <strong>Enhanced Efficiency:</strong> Streamline data
                    collection, saving time for both you and your clients.
                  </li>
                </ul>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Container
        maxWidth="md"
        sx={{ py: 4, textAlign: "center", bgcolor: "#f0f8ff" }}
      >
        <Typography variant="h3" gutterBottom>
          How It Works
        </Typography>
        <Tabs
          value={activeStep}
          onChange={handleStepChange}
          variant="scrollable"
          scrollButtons="auto"
          indicatorColor="primary"
          textColor="primary"
          sx={{
            overflowX: "auto",
            whiteSpace: "nowrap",
          }}
        >
          <Tab label="1. Inmate Calls Your Office" />
          <Tab label="2. Transfer to Chatbot" />
          <Tab label="3. Chatbot Conducts Interview" />
          <Tab label="4. Receive and Review Data" />
        </Tabs>

        <TabPanel value={activeStep} index={0}>
          <Typography variant="body1">
            The inmate initiates a call to your office seeking legal assistance
            or case evaluation.
          </Typography>
        </TabPanel>

        <TabPanel value={activeStep} index={1}>
          <Typography variant="body1">
            You seamlessly transfer the call to our secure voice chatbot, which
            takes over the conversation.
          </Typography>
        </TabPanel>

        <TabPanel value={activeStep} index={2}>
          <Typography variant="body1">
            The chatbot asks your predefined questions, gathering accurate and
            relevant information from the inmate.
          </Typography>
        </TabPanel>

        <TabPanel value={activeStep} index={3}>
          <Typography variant="body1">
            Access detailed transcripts and completed forms in real-time,
            allowing you to proceed with the case efficiently.
          </Typography>
        </TabPanel>

        <Box
          sx={{
            my: 4,
          }}
        >
          <Typography variant="h5" gutterBottom>
            Watch a Quick Demo
          </Typography>
          <Typography variant="body1" paragraph>
            See how our platform works in action with this short video
            walkthrough.
          </Typography>
          <Box
            sx={{
              position: "relative",
              width: "100%",
              paddingTop: "56.25%", // 16:9 aspect ratio
            }}
          >
            <ReactPlayer
              url="https://youtu.be/eGLSKyzT4Yg"
              controls={true}
              width="100%"
              height="100%"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
              }}
            />
          </Box>
        </Box>
      </Container>

      <Container
        maxWidth="md"
        sx={{ pt: 4, pb: 8, textAlign: "center" }}
        ref={priceRef}
      >
        <Typography variant="h3" gutterBottom>
          Pricing Options
        </Typography>
        <Typography variant="h6" color="textSecondary" paragraph>
          Select the plan that fits your business
        </Typography>

        <Grid container spacing={4}>
          {/* Pay-as-you-go Plan */}
          <Grid item xs={12} md={6} py={7}>
            <Card
              sx={{
                p: 3,
                borderRadius: "15px",
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                position: "relative", // Added to position the "Popular" banner
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                minHeight: "100%", // Ensure equal height
              }}
            >
              {/* Popular Banner */}
              <Box
                sx={{
                  position: "absolute",
                  top: 50,
                  left: -20,
                  backgroundColor: "red",
                  color: "white",
                  padding: "8px 16px",
                  fontSize: "0.875rem",
                  fontWeight: "bold",
                  transform: "rotate(-45deg)",
                  transformOrigin: "top left",
                  width: "70px",
                  textAlign: "center",
                  zIndex: 1,
                }}
              >
                Popular
              </Box>

              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" color="secondary" paragraph>
                  Pay-as-you-go Plan
                </Typography>
                <Typography variant="h5" color="primary" paragraph>
                  $10 per hour of Voice Chatbot Talk Time
                </Typography>
                <Typography variant="body1" paragraph>
                  Includes Google Drive Integration
                </Typography>
                <Box sx={{ mb: 2 }}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item sx={{ textAlign: "left" }}>
                      <FontAwesomeIcon
                        icon={faXmarkCircle}
                        color="primary"
                        style={{ fontSize: "1.5rem" }}
                      />
                    </Grid>
                    <Grid item xs={10} sx={{ textAlign: "left" }}>
                      <Typography variant="h6" color="primary">
                        No subscription required!
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Typography
                  variant="body1"
                  paragraph
                  sx={{ textAlign: "left" }}
                >
                  Adjust the slider to estimate costs based on the number of
                  questions per form:
                </Typography>
                <Slider
                  value={questions}
                  onChange={(e, val) => setQuestions(val)}
                  min={10}
                  max={50}
                  step={10}
                  sx={{ mb: 2 }}
                />
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Number of Questions per Interview: {questions}
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: 3,
                    width: "100%",
                  }}
                >
                  <table
                    style={{
                      borderCollapse: "separate",
                      borderSpacing: "0 10px",
                      width: "100%",
                      maxWidth: "500px",
                      textAlign: "left",
                    }}
                  >
                    <thead>
                      <tr>
                        <th
                          style={{
                            padding: "12px",
                            backgroundColor: "#f9f9f9",
                          }}
                        >
                          Estimated Voice Chatbot Talk Time
                        </th>
                        <th
                          style={{
                            padding: "12px",
                            backgroundColor: "#f9f9f9",
                          }}
                        >
                          Cost per Completion
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          style={{ padding: "12px", backgroundColor: "#fff" }}
                        >
                          {timePerForm}
                        </td>
                        <td
                          style={{ padding: "12px", backgroundColor: "#fff" }}
                        >
                          {costPerForm}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Box>

                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ mt: 2 }}
                >
                  *Estimates only. Actual call length may vary.
                </Typography>
              </Box>

              {/* Get Started Free Button */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mt: 4,
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={handleSignUpClick}
                  sx={{
                    px: 4, // Extra padding for a more prominent button
                    py: 1.5,
                    borderRadius: "5px", // Button rounded corners
                    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // More pronounced shadow for the button
                  }}
                >
                  Try For Free
                </Button>
              </Box>
            </Card>
          </Grid>

          {/* Enterprise Plan */}
          <Grid item xs={12} md={6} py={7}>
            <Card
              sx={{
                p: 3,
                borderRadius: "15px",
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                minHeight: "100%", // Ensure equal height
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" color="secondary" paragraph>
                  Enterprise Plan
                </Typography>
                <Typography variant="h5" color="primary" paragraph>
                  $699/month
                  <Typography variant="body1" color="primary" paragraph>
                    *billed annually
                  </Typography>
                </Typography>
                <Typography variant="body1" paragraph>
                  Includes:
                </Typography>

                <List sx={{ mb: 2 }}>
                  <ListItem>
                    <ListItemText primary="100 hours per month of Voice Chatbot Talk Time" />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="$8/hour additional time" />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Custom Integration with Your CRM" />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Personalized Setup and Onboarding" />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Priority Support" />
                  </ListItem>
                </List>

                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ mt: 2 }}
                >
                  Contact us for more details about our enterprise solutions.
                </Typography>
              </Box>

              {/* Contact Sales Button */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mt: 4,
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => openModal("contact")}
                  sx={{
                    px: 4, // Extra padding for a more prominent button
                    py: 1.5,
                    borderRadius: "5px", // Button rounded corners
                    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // More pronounced shadow for the button
                  }}
                >
                  Contact Sales
                </Button>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Container>

      {/* 
      <Container maxWidth="lg" sx={{ py: 6 }}>
        <Typography variant="h3" align="center" gutterBottom>
          What Our Clients Are Saying
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Card
              sx={{
                p: 3,
                borderRadius: "15px",
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                height: "100%",
              }}
            >
              <Typography variant="body1" paragraph>
                "FormVox has completely transformed our hiring process, making
                it faster and more efficient."
              </Typography>
              <Typography variant="h6" color="primary">
                - HR Manager, Mid-Sized Company
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card
              sx={{
                p: 3,
                borderRadius: "15px",
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                height: "100%",
              }}
            >
              <Typography variant="body1" paragraph>
                "We're able to screen hundreds of applicants in record time.
                It's a game-changer!"
              </Typography>
              <Typography variant="h6" color="primary">
                - Operations Director, Growing Startup
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card
              sx={{
                p: 3,
                borderRadius: "15px",
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                height: "100%",
              }}
            >
              <Typography variant="body1" paragraph>
                "Our recruitment process is more focused and efficient thanks to
                FormVox's voice chatbot."
              </Typography>
              <Typography variant="h6" color="primary">
                - Talent Acquisition Specialist, Tech Company
              </Typography>
            </Card>
          </Grid>
        </Grid>
      </Container>
 */}
      <Container
        maxWidth="lg"
        sx={{
          py: 6,
          mb: 6, // Add this line to increase bottom margin
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          borderRadius: "10px",

          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#f5f5f5",
          color: "#000",
          mt: 4,
        }}
        ref={faqRef}
      >
        <Box sx={{ mb: 2 }}>
          <GavelIcon sx={{ fontSize: 60, color: "primary.main" }} />
        </Box>
        <Typography variant="h4" paragraph sx={{ fontWeight: "bold" }}>
          Ready to Transform Your Legal Practice?
        </Typography>
        <Typography variant="body1" paragraph>
          Start automating inmate communications today—no credit card required!
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleSignUpClick}
          sx={{
            mt: 2,
            px: 5,
            py: 1.5,
            borderRadius: "5px",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
          }}
        >
          Get Started Free
        </Button>
        <Typography
          variant="body2"
          paragraph
          sx={{ mt: 3, fontStyle: "italic" }}
        >
          "This platform has revolutionized how we handle inmate communications.
          It's a game-changer for our firm." — Civil Rights Lawyer
        </Typography>
      </Container>

      <Container maxWidth="lg" sx={{ py: 4, bgcolor: "#f0f8ff" }} ref={faqRef}>
        <Grid container spacing={4}>
          {faqData.map((faq, index) => (
            <Grid item xs={12} key={index}>
              <Typography
                variant="h5"
                gutterBottom
                sx={{
                  cursor: "pointer",
                  color: "primary.main",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                onClick={() =>
                  setActiveTab((prev) => (prev === index ? null : index))
                }
              >
                {faq.question}
                <FontAwesomeIcon
                  icon={activeTab === index ? faChevronUp : faChevronDown}
                />
              </Typography>
              {activeTab === index && (
                <Typography variant="body1" paragraph>
                  {faq.answer}
                </Typography>
              )}
            </Grid>
          ))}
        </Grid>
      </Container>

      <Container
        maxWidth="lg"
        sx={{
          py: 4,
          mb: 4,
          textAlign: "center", // Centers the text horizontally
          display: "flex", // Makes the container a flexbox
          flexDirection: "column", // Aligns items vertically
          alignItems: "center", // Centers items horizontally within the flexbox
          border: "2px solid #1976d2", // Blue border
          borderRadius: "10px", // Rounded corners
          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Subtle shadow
          backgroundColor: "#f7faff", // Light background color to make it stand out
          mt: 4, // Top margin to separate it from the previous section
        }}
        ref={faqRef}
      >
        <Typography variant="h4" paragraph sx={{ fontWeight: "bold" }}>
          Want a Personalized Walkthrough?
        </Typography>
        <Typography variant="body1" paragraph>
          Schedule a free demo to see how our platform can revolutionize your
          inmate communications.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => openModal("demo")} // Updated onClick function
          sx={{
            mt: 2,
            px: 4, // Extra padding for a more prominent button
            py: 1.5,
            borderRadius: "5px", // Button rounded corners
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // More pronounced shadow for the button
          }}
        >
          Schedule Demo
        </Button>
      </Container>

      <Modal
        show={modalVisible}
        onClose={() => setModalVisible(false)}
        initialTab={activeTab}
      />

      <Box
        sx={{ textAlign: "center", py: 2, bgcolor: "grey.900", color: "white" }}
      >
        <Typography variant="body2">
          &copy; 2024 FormVox LLC. All rights reserved.
        </Typography>
        <Typography variant="body2">
          30 N Gould St Ste R, Sheridan, WY 82801
        </Typography>
        <Typography variant="body2">
          Contact:{" "}
          <a
            href="mailto:contact@formvox.com"
            style={{
              textDecoration: "none",
              color: "white",
            }}
          >
            contact@formvox.com
          </a>
        </Typography>

        <Typography variant="body2">
          <a
            href="/privacy_policy.html"
            target="_blank"
            style={{
              textDecoration: "none",
              color: "white",
            }}
          >
            Privacy Policy
          </a>
        </Typography>
      </Box>
    </Box>
  );
};

export default HrLandingPage;
