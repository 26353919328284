import React from "react";
import "./ConfirmationModal.css";

const ConfirmationModal = ({ message, onClose, onConfirm }) => {
  // Split message into lines and render with <br> tags
  const messageLines = message.split("\n").map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));

  return (
    <div className="confirmation-modal-overlay-hippa">
      <div className="confirmation-modal-content-hippa">
        <p>{messageLines}</p>
        <button onClick={onClose}>No</button>
        <button onClick={onConfirm}>Yes</button>
      </div>
    </div>
  );
};

export default ConfirmationModal;
