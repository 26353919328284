// Sidebar.js

// Import necessary dependencies
import ReactDOM from "react-dom";
import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignOutAlt,
  faSquarePlus,
  faAngleDoubleDown,
  faPlay,
  faTrash,
  faCaretDown,
  faCaretRight,
} from "@fortawesome/free-solid-svg-icons";
import { faGoogle, faMicrosoft } from "@fortawesome/free-brands-svg-icons";
import {
  getFirestore,
  doc,
  getDoc,
  onSnapshot,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import "./Sidebar.css";
import AddCreditsModal from "./AddCreditsModal";
import ConfirmationModal from "./ConfirmationModal";
import IntegrationModal from "./IntegrationModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ethanVoice from "../assets/ethan.mp3";
import liamVoice from "../assets/liam.mp3";
import aidenVoice from "../assets/aiden.mp3";
import avaVoice from "../assets/ava.mp3";
import sophiaVoice from "../assets/sophia.mp3";
import miaVoice from "../assets/mia.mp3";

const Sidebar = ({
  isLoggedIn,
  currentUser,
  signInWithGoogle,
  signInWithMicrosoft,
  signOutUser,
  signUpWithEmail,
  signInWithEmail,
  setEmail,
  setPassword,
  email,
  password,
  error,
  accountName,
  creditsAvailable,
  recentActivity = [],
  onShowCreateFormModal,
  onShowAddCreditsModal,
  onAddSubUser,
  subUsers = [],
  onRemoveSubUser,
  handleEditPermissions,
  onShowRecentActivityModal,
  onShowIntegrationModal,
}) => {
  console.log("***");

  const [isSignUp, setIsSignUp] = useState(false);
  const [selectedVoice, setSelectedVoice] = useState("");
  const [initialLoad, setInitialLoad] = useState(true);
  const [realTimeCredits, setRealTimeCredits] = useState(creditsAvailable);
  const [newSubUserEmail, setNewSubUserEmail] = useState("");
  const [newSubUserPermission, setNewSubUserPermission] = useState("full");
  const [editingPermissions, setEditingPermissions] = useState(null);
  const [permissionsEditingValue, setPermissionsEditingValue] = useState("");
  const [showSubUsers, setShowSubUsers] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [subUserToRemove, setSubUserToRemove] = useState(null);
  const [hasBilling, setHasBilling] = useState(true);

  const sidebarRef = useRef(null);
  const firestore = getFirestore();

  useEffect(() => {
    const loadVoiceSelection = async () => {
      if (currentUser) {
        const userId = currentUser.mainUserId || currentUser.uid;
        const userDocRef = doc(firestore, "users", userId);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists() && userDoc.data().selectedVoice) {
          const voice = Object.keys(voiceMap).find(
            (key) => voiceMap[key] === userDoc.data().selectedVoice
          );
          setSelectedVoice(voice);
        }
      }
      setInitialLoad(false);
    };

    loadVoiceSelection();
  }, [currentUser, firestore]);

  useEffect(() => {
    if (currentUser) {
      const userId = currentUser.mainUserId || currentUser.uid;
      const userDocRef = doc(firestore, "users", userId);
      const unsubscribe = onSnapshot(userDocRef, (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          setRealTimeCredits(data.creditsAvailable);
        }
      });

      return () => unsubscribe();
    }
  }, [currentUser, firestore]);

  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return "";
    const country = phoneNumber.substring(0, 1);
    const area = phoneNumber.substring(1, 4);
    const prefix = phoneNumber.substring(4, 7);
    const line = phoneNumber.substring(7, 11);
    return `${country} (${area}) ${prefix}-${line}`;
  };

  const handleScrollToBottom = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollTo({
        top: sidebarRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  const voices = [
    { name: "Ava", sample: avaVoice },
    { name: "Sophia", sample: sophiaVoice },
    { name: "Mia", sample: miaVoice },
    { name: "Ethan", sample: ethanVoice },
    { name: "Liam", sample: liamVoice },
    { name: "Aiden", sample: aidenVoice },
  ];

  const voiceMap = {
    Mia: "en-US-JaneNeural",
    Sophia: "en-US-EmmaNeural",
    Aiden: "en-US-JasonNeural",
    Liam: "en-US-ChristopherNeural",
    Ethan: "en-US-AndrewNeural",
    Ava: "en-US-AvaNeural",
  };

  const isSubUser = async () => {
    if (currentUser) {
      if (
        currentUser.subUsers &&
        currentUser.subUsers.some(
          (subUser) =>
            subUser.email.toLowerCase() === currentUser.email.toLowerCase()
        )
      ) {
        const subUser = currentUser.subUsers.find(
          (subUser) =>
            subUser.email.toLowerCase() === currentUser.email.toLowerCase()
        );

        if (subUser) {
          const firestore = getFirestore();
          const subUserDocRef = doc(firestore, "users", subUser.id);
          const subUserDoc = await getDoc(subUserDocRef);

          if (subUserDoc.exists()) {
            const subUserData = subUserDoc.data();
            if (subUserData.permission === "full") {
              setHasBilling(true);
              return true;
            }

            if (subUserData.permission === "no-billing") {
              setHasBilling(false);
              return false;
            }
          }
        }
      }
    }
  };
  isSubUser();

  const handleVoiceChange = async (e) => {
    const userId = currentUser.mainUserId || currentUser.uid;

    const newVoice = e.target.value;
    const newVoiceParams = voiceMap[newVoice];
    setSelectedVoice(newVoice);
    if (currentUser) {
      const userDocRef = doc(firestore, "users", userId);
      await setDoc(
        userDocRef,
        { selectedVoice: newVoiceParams },
        { merge: true }
      );
    }
  };

  const playSample = () => {
    const selectedVoiceData = voices.find(
      (voice) => voice.name === selectedVoice
    );
    if (selectedVoiceData) {
      const audio = new Audio(selectedVoiceData.sample);
      audio.play();
    }
  };

  const handleAddSubUser = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (
      newSubUserEmail.trim() !== "" &&
      emailRegex.test(newSubUserEmail.trim())
    ) {
      const normalizedEmail = newSubUserEmail.trim().toLowerCase();
      const existingSubUser = subUsers.find(
        (subUser) => subUser.email.toLowerCase() === normalizedEmail
      );

      if (!existingSubUser) {
        onAddSubUser(normalizedEmail, newSubUserPermission);
        setNewSubUserEmail("");
        setNewSubUserPermission("full");
        toast.success("Invite Email Sent to User");
      } else {
        toast.error("This sub-user email already exists.");
      }
    } else {
      toast.error("Please enter a valid email address.");
    }
  };

  const handleSavePermissions = async (subUserId) => {
    if (permissionsEditingValue) {
      await handleEditPermissions(subUserId, permissionsEditingValue);
      setEditingPermissions(null);
      setPermissionsEditingValue("");
    }
  };

  const handleRemoveSubUser = (subUserId) => {
    setSubUserToRemove(subUserId);
    setShowConfirmationModal(true);
  };

  const confirmRemoveSubUser = () => {
    if (subUserToRemove) {
      onRemoveSubUser(subUserToRemove);
      setShowConfirmationModal(false);
      setSubUserToRemove(null);
    }
  };

  if (!currentUser) {
    return null;
  }

  const sidebarContent = (
    <div className="sidebar" ref={sidebarRef}>
      <p className="tagline">Settings</p>
      <ul className="menu">
        {isLoggedIn ? (
          <>
            <li className="profile">{accountName}</li>
            <li className="profile-email">
              {currentUser && currentUser.email}
            </li>

            <li className="create-form">
              <button
                className="create-main-btn"
                onClick={onShowCreateFormModal}
              >
                <FontAwesomeIcon icon={faSquarePlus} /> Setup New Form
              </button>
            </li>
            <li className="credits-available">
              <span className="credits-label">Voice Chatbot Time:</span>
              <span
                className={`credits-value ${
                  realTimeCredits < 0 ? "credits-negative" : ""
                }`}
              >
                {realTimeCredits} Minutes
              </span>
              {hasBilling && (
                <span
                  className="add-credits-btn"
                  onClick={onShowAddCreditsModal}
                >
                  Add Credits / View Usage
                </span>
              )}
            </li>

            <li className="credits-available">
              <span className="">Your FormVox Access #</span>
              <span className="credits-value">
                {currentUser && currentUser.accessPhone
                  ? formatPhoneNumber(currentUser.accessPhone)
                  : "Provisioning your phone number..."}
              </span>
            </li>
            <li>
              <h3>Your Chatbot Voice:</h3>
              <div className="voice-selection">
                <select
                  value={initialLoad ? "" : selectedVoice}
                  onChange={handleVoiceChange}
                  disabled={initialLoad}
                >
                  <option value="" disabled>
                    {initialLoad ? "Loading..." : "Select Voice"}
                  </option>
                  {voices.map((voice, index) => (
                    <option key={index} value={voice.name}>
                      {voice.name}
                    </option>
                  ))}
                </select>
                <button
                  onClick={playSample}
                  className="play-sample-btn-sidebar"
                  disabled={!selectedVoice || initialLoad}
                >
                  <FontAwesomeIcon icon={faPlay} /> Play Sample
                </button>
              </div>
            </li>

            <li className="menu-item" onClick={onShowIntegrationModal}>
              <h3>
                Integrations <FontAwesomeIcon icon={faCaretRight} />
              </h3>
            </li>

            {hasBilling && (
              <li className="credits-available" onClick={onShowAddCreditsModal}>
                <h3>
                  Manage Billing <FontAwesomeIcon icon={faCaretRight} />
                </h3>
              </li>
            )}
            {!currentUser.mainUserId && (
              <li>
                <h3 onClick={() => setShowSubUsers(!showSubUsers)}>
                  Add Users{" "}
                  <FontAwesomeIcon
                    icon={showSubUsers ? faCaretDown : faCaretRight}
                  />
                </h3>
                {showSubUsers && (
                  <div className="sub-users">
                    <ul>
                      {subUsers.map((subUser, index) => (
                        <li key={index}>
                          <div className="sub-user-email">{subUser.email} </div>
                          <span>access: {subUser.permission}</span>
                          <div className="sub-user-buttons">
                            <button
                              onClick={() => handleRemoveSubUser(subUser.id)}
                            >
                              <FontAwesomeIcon icon={faTrash} /> Remove
                            </button>
                            {editingPermissions === subUser.id ? (
                              <>
                                <select
                                  value={permissionsEditingValue}
                                  onChange={(e) =>
                                    setPermissionsEditingValue(e.target.value)
                                  }
                                >
                                  <option value="" hidden>
                                    Choose Access Level
                                  </option>
                                  <option value="full">Full Access</option>
                                  <option value="no-billing">
                                    Without Billing Access
                                  </option>
                                </select>
                                <button
                                  onClick={() =>
                                    handleSavePermissions(subUser.id)
                                  }
                                >
                                  Save
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  onClick={() => {
                                    setEditingPermissions(subUser.id);
                                    setPermissionsEditingValue(
                                      subUser.permission
                                    );
                                  }}
                                >
                                  Edit
                                </button>
                              </>
                            )}
                          </div>
                        </li>
                      ))}
                    </ul>

                    {subUsers.length < 5 && (
                      <div className="add-sub-user">
                        <input
                          type="email"
                          value={newSubUserEmail}
                          onChange={(e) => setNewSubUserEmail(e.target.value)}
                          placeholder="New user email"
                        />
                        <select
                          value={newSubUserPermission}
                          onChange={(e) =>
                            setNewSubUserPermission(e.target.value)
                          }
                        >
                          <option value="full">Full Access</option>
                          <option value="no-billing">
                            Without Billing Access
                          </option>
                        </select>
                        <button onClick={handleAddSubUser}>Add</button>
                      </div>
                    )}
                  </div>
                )}
              </li>
            )}
            <li onClick={() => onShowRecentActivityModal()}>
              <h3>
                Recent Activity <FontAwesomeIcon icon={faCaretRight} />
              </h3>
              <ul>
                {recentActivity.map((activity, index) => (
                  <li key={index}>{activity}</li>
                ))}
              </ul>
            </li>
            {/* <li>
              <h3>HIPAA Liability Protection</h3>
              <a
                href="/path/to/baa.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Click to download/view the BAA with VoxQuery
              </a>
            </li>  */}
            <li className="sign-out" onClick={signOutUser}>
              <FontAwesomeIcon icon={faSignOutAlt} /> Sign Out
            </li>
            <div className="scroll-to-bottom" onClick={handleScrollToBottom}>
              <FontAwesomeIcon icon={faAngleDoubleDown} />
            </div>
          </>
        ) : (
          <>
            <div className="auth-section">
              <div className="auth-item">
                <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button onClick={isSignUp ? signUpWithEmail : signInWithEmail}>
                  {isSignUp ? "Sign Up with Email" : "Sign In with Email"}
                </button>
                <div className="toggle-auth">
                  {isSignUp ? (
                    <span>
                      Already have an account?{" "}
                      <a href="#" onClick={() => setIsSignUp(false)}>
                        Sign in
                      </a>
                    </span>
                  ) : (
                    <span>
                      Don't have an account?{" "}
                      <a href="#" onClick={() => setIsSignUp(true)}>
                        Sign up
                      </a>
                    </span>
                  )}
                </div>
              </div>
              <div className="auth-item" onClick={signInWithGoogle}>
                <FontAwesomeIcon icon={faGoogle} /> Continue with Google
              </div>
              <div className="auth-item" onClick={signInWithMicrosoft}>
                <FontAwesomeIcon icon={faMicrosoft} /> Continue with Microsoft
              </div>
              {error && <div className="error-message">{error}</div>}
            </div>
          </>
        )}
      </ul>
      {showConfirmationModal && (
        <ConfirmationModal
          message="Are you sure you want to remove this sub-user?"
          onClose={() => setShowConfirmationModal(false)}
          onConfirm={confirmRemoveSubUser}
        />
      )}
    </div>
  );

  return sidebarContent;
};

export default Sidebar;
