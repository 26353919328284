import React, { useState, useEffect } from "react";

import ReactPixel from "react-facebook-pixel";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";
import CustomerSupportChat from "./components/CustomerSupportChat";

import { Link } from "react-router-dom";

import HelpPage from "./components/HelpPage";

import LandingPageGeneral from "./components/LandingPageGeneral";
import LandingPageLegal from "./components/LandingPageLegal";
import LandingPageProfessionals from "./components/LandingPageProfessionals";
import LandingPageHr from "./components/LandingPageHr";
import LandingPageHr2 from "./components/LandingPageHr2";

import { TutorialProvider } from "./components/TutorialContext";

import AccountSettingsModal from "./components/AccountSettingsModal";
import Signup from "./components/Signup";
import Login from "./components/Login"; // Import the Login component
import IntegrationModal from "./components/IntegrationModal"; // Make sure the path is correct
import GoogleDriveCallback from "./components/GoogleDriveCallback";
import SubUserSignUp from "./components/SubUserSignUp";
import RecentActivityModal from "./components/RecentActivityModal";
import Sidebar from "./components/Sidebar";
import ChatInput from "./components/ChatInput";
import AccountNameModal from "./components/AccountNameModal";
import { ThreeDots } from "react-loader-spinner";
import CreateFormModal from "./components/CreateFormModal";
import CreateQuestionnaireModal from "./components/CreateQuestionnaireModal";
import StartNewVoiceForm from "./components/StartNewVoiceForm";
import "./App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faCaretDown, faLock } from "@fortawesome/free-solid-svg-icons";
import { faGoogle, faMicrosoft } from "@fortawesome/free-brands-svg-icons";
import logo from "./assets/logo.png";
import AddCreditsModal from "./components/AddCreditsModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { initializeApp } from "firebase/app";
import {
  getAuth,
  deleteUser,
  onAuthStateChanged,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
  signOut,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import {
  getFirestore,
  doc,
  setDoc,
  getDoc,
  collection,
  addDoc,
  deleteDoc,
  onSnapshot,
  getDocs,
  updateDoc,
  query,
  where,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);

console.log("123");
const provisionPhoneNumber = async () => {
  try {
    const phoneNumbersRef = collection(firestore, "availablePhoneNumbers");
    const querySnapshot = await getDocs(phoneNumbersRef);

    if (!querySnapshot.empty) {
      const phoneNumberDoc = querySnapshot.docs[0];
      const phoneNumber = phoneNumberDoc.data().number;

      await addDoc(collection(firestore, "assignedPhoneNumbers"), {
        number: phoneNumber,
        assignedTo: auth.currentUser.uid,
        assignedAt: new Date(),
      });

      // await deleteDoc(phoneNumberDoc.ref);

      return phoneNumber;
    } else {
      return "Provisioning your number...";
    }
  } catch (error) {
    console.error("Error provisioning phone number: ", error);
    return "Provisioning your number...";
  }
};

function App() {
  const [exampleFormCreated, setExampleFormCreated] = useState(false);

  const [showChatInputModal, setShowChatInputModal] = useState(true);
  const [showRecentActivityModal, setShowRecentActivityModal] = useState(false);

  const [subUsers, setSubUsers] = useState([]);
  const [onSignUpPage, setOnSignUpPage] = useState(false);
  const [modalKey, setModalKey] = useState(0);
  const [sessions, setSessions] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth >= 768);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [mainUserId, setMainUserId] = useState(null); // Add state to store mainUserId
  const [currentState, setCurrentState] = useState("start");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [accountName, setAccountName] = useState("");
  const [error, setError] = useState("");
  const [validationError, setValidationError] = useState("");
  const [showAccountNameModal, setShowAccountNameModal] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [hoursSaved, setHoursSaved] = useState(0);
  const [estimatedHoursSaved, setEstimatedHoursSaved] = useState(0);
  const [recentActivity, setRecentActivity] = useState([]);
  const [forms, setForms] = useState([]);
  const [subFolders, setSubFolders] = useState([]);
  const [questionnaires, setQuestionnaires] = useState([]);
  const [showCreateFormModal, setShowCreateFormModal] = useState(false);
  const [showCreateQuestionnaireModal, setShowCreateQuestionnaireModal] =
    useState(false);
  const [showStartNewVoiceForm, setShowStartNewVoiceForm] = useState(false);
  const [creditsAvailable, setCreditsAvailable] = useState(0);
  const [editForm, setEditForm] = useState(null);
  const [isSignUp, setIsSignUp] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [sessionPin, setSessionPin] = useState("");
  const [showAddCreditsModal, setShowAddCreditsModal] = useState(false);
  const [isSubUserSignUpActive, setIsSubUserSignUpActive] = useState(false);
  const [showIntegrationModal, setShowIntegrationModal] = useState(false);

  const [showAccountSettingsModal, setShowAccountSettingsModal] =
    useState(false);

  const createExampleForm = async (userId) => {
    const firestore = getFirestore(); // Assuming getFirestore() is available globally or imported
    const formsRef = collection(firestore, "users", userId, "forms");

    const exampleForm = {
      formName: "Example Form",
      welcomeMessage:
        "Hello and welcome to our quick demo!  You are now speaking to a voice chatbot that can conduct forms or questionnaires conveniently over the phone and then produces a text transcript.  I will just ask a few quick questions to demonstrate.  Then you can view the transcript in your dashboard.  Please press the pound or hash key after you are done answering each question. Are you ready to begin?",
      questions: [
        {
          text: "Do you currently handle case evaluations for people who are currently incarcerated?",
          type: "Open",
          condition: { yes: "", no: "" },
        },
        {
          text: "Do you spend more than 5-hours a week with inmate case evaluations?",
          type: "Yes/No",
          condition: {
            yes: "custom",
            customYes:
              "Would you be interesting in automating some of this with a voice chatbot?",
            no: "custom",
            customNo:
              "Approximately how many hours per week do you spend on case evaluations?",
          },
        },
      ],
      conclusionMessage:
        "Thank you for participating! We hope you enjoyed this quick demo. Feel free to create and customize your own forms, interviews, and questionnaires whenever you like.",
    };
    try {
      await addDoc(formsRef, {
        ...exampleForm,
        createdOn: new Date(),
      });
      console.log("Example form created successfully");
    } catch (error) {
      console.error("Error creating example form: ", error);
    }
  };

  const handleOpenAccountSettingsModal = () => {
    setShowAccountSettingsModal(true);
    setIsDropdownOpen(false); // Optionally close the dropdown when opening the modal
  };
  // Utility function to generate a random numeric ID
  const generateRandomID = () => {
    return Math.floor(1000 + Math.random() * 9000).toString();
  };

  // Fetch a new session ID including the account PIN from the server
  const fetchSessionID = async (userID) => {
    const response = await fetch(
      "https://handlesessions-cgn2j7f72a-uc.a.run.app",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ userID }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch session ID: " + response.statusText);
    }

    return response.json();
  };

  // Generate a unique client ID that doesn't exist in Firestore under the specific user
  const generateUniqueClientID = async (userId, firestore) => {
    let uniqueClientID;
    let exists;
    do {
      uniqueClientID = generateRandomID();
      const q = query(
        collection(firestore, "users", userId, "clients"),
        where("clientID", "==", uniqueClientID)
      );
      const snapshot = await getDocs(q);
      exists = !snapshot.empty;
    } while (exists);

    return uniqueClientID;
  };

  const sendWelcomeEmail = async (email, displayName) => {
    const emailData = {
      email: email,
      name: displayName || "Customer",
      from: "FormVox Support <contact@formvox.com>",
      subject: "Welcome to FormVox",
      text: `Dear ${
        displayName || "Customer"
      },\n\nWelcome to FormVox! Thank you for signing up. To get started, log in and follow our tutorials to create your first form, or watch our quick start video here: https://player.vimeo.com/video/991446894\n\nBest regards,\nFormVox Team\n\nFormVox LLC\n30 N Gould St Ste R\nSheridan, WY 82801\ncontact@formvox.com`,
      html: `<p>Dear ${
        displayName || "Customer"
      },</p><p>Welcome to FormVox! Thank you for signing up. To get started, log in and follow our tutorials to create your first form, or watch our quick start video here:</p><p><a href="https://player.vimeo.com/video/991446894">Quick Start Video</a></p><p>Best regards,<br>FormVox Team</p><p>FormVox LLC<br>30 N Gould St Ste R<br>Sheridan, WY 82801</p><p><a href="mailto:contact@formvox.com">contact@formvox.com</a></p>`,
    };

    try {
      await fetch("https://sendemail-cgn2j7f72a-uc.a.run.app", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(emailData),
      });
      console.log("Welcome email sent successfully");
    } catch (error) {
      console.error("Error sending welcome email: ", error);
    }
  };

  // This function may be used to initialize a default session when a new form is created
  const createInitialSession = async (userId) => {
    const firestore = getFirestore();
    console.log("*** IN createInitialSession");
    try {
      const clientID = await generateUniqueClientID(userId, firestore);
      const formsSnapshot = await getDocs(
        collection(firestore, "users", userId, "forms")
      );

      if (!formsSnapshot.empty) {
        const firstFormSnapshot = formsSnapshot.docs[0];
        const firstFormId = firstFormSnapshot.id;
        console.log("HERE NOW " + firstFormId);
        const { sessionID } = await fetchSessionID(userId); // Fetch a session ID for the new session
        const currentTime = new Date();

        const newSession = {
          formID: firstFormId,
          clientID,
          sessionID,
          createdAt: currentTime,
        };

        // Firestore document references
        const sessionUserDocRef = doc(
          firestore,
          "users",
          userId,
          "sessions",
          sessionID
        );
        const sessionGlobalDocRef = doc(firestore, "sessions", sessionID); // Assuming 'global_sessions' is your global collection

        // Save the session in the user's sessions collection
        await setDoc(sessionUserDocRef, newSession);

        // Also save the session in the global sessions collection
        await setDoc(sessionGlobalDocRef, {
          ...newSession,
          userID: userId, // Add userID to differentiate in global sessions
        });

        console.log("Initial session created successfully:", sessionID);
      } else {
        console.error("No forms available to link the session.");
      }
    } catch (error) {
      console.error("Error creating initial session: ", error);
    }
  };

  const toggleSidebarVisibility = () => {
    // Always log the expanded state when attempting to toggle visibility
    setIsSidebarOpen((prevState) => !prevState);
  };

  const refreshSidebar = async (mainUserId) => {
    console.log("refresh --?", mainUserId);
    const userId = mainUserId || (currentUser ? currentUser.uid : null);
    if (userId) {
      const userDoc = await getDoc(doc(firestore, "users", userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setCreditsAvailable(userData.creditsAvailable || 0);
        setSubUsers(userData.subUsers || []);
      }
    }
  };

  const downloadAuditLog = async (startDate, endDate) => {
    setShowSpinner(true); // Activate the loading spinner

    try {
      const startMonth = startDate.getMonth() + 1; // Months are zero-based in JavaScript
      const startYear = startDate.getFullYear();
      const endMonth = endDate.getMonth() + 1;
      const endYear = endDate.getFullYear();

      const response = await fetch(
        "https://handleauditlog-cgn2j7f72a-uc.a.run.app",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${currentUser.token}`, // Assuming you handle authentication
          },
          body: JSON.stringify({
            startMonth: startMonth.toString().padStart(2, "0"),
            startYear: startYear.toString(),
            endMonth: endMonth.toString().padStart(2, "0"),
            endYear: endYear.toString(),
            userId: currentUser.mainUserId || currentUser.uid,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `audit-log-${startYear}-${startMonth
        .toString()
        .padStart(2, "0")}_to_${endYear}-${endMonth
        .toString()
        .padStart(2, "0")}.pdf`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);

      toast.success("Your file download has begun.", { autoClose: 8000 }); // Show success notification
    } catch (error) {
      console.error("Failed to download audit log:", error);
      toast.error("Failed to download audit log. Please try again.", {
        autoClose: 8000,
      });
    } finally {
      setShowSpinner(false); // Deactivate the loading spinner
    }
  };

  const calculateEstimatedHoursSaved = () => {
    const currentDate = new Date();
    const daysInMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    ).getDate();
    const daysLeft = daysInMonth - currentDate.getDate();
    const estimatedHours = (10 / daysInMonth) * daysLeft;
    setEstimatedHoursSaved(estimatedHours);
  };

  useEffect(() => {
    const options = {
      autoConfig: true, // set pixel's autoConfig
      debug: false, // enable logs
    };
    ReactPixel.init("622008134924153", {}, options);
    ReactPixel.track("ViewContent"); // Track the ViewContent event
  }, []);

  useEffect(() => {
    console.log("Integration Modal Visibility State:", showIntegrationModal);
  }, [showIntegrationModal]);

  useEffect(() => {
    const handleResize = () => {
      setIsSidebarOpen(window.innerWidth >= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const fetchSessions = async () => {
      if (currentUser) {
        const userId = mainUserId || currentUser.uid;
        const sessionsRef = collection(firestore, "users", userId, "sessions");
        const snapshot = await getDocs(sessionsRef);
        const sessionsData = snapshot.docs.map((doc) => ({
          sessionId: doc.id,
          ...doc.data(),
        }));
        setSessions(sessionsData);
      }
    };

    fetchSessions();
  }, [currentUser, mainUserId]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setShowSpinner(true);
      try {
        if (user) {
          setIsLoggedIn(true);
          const userRef = doc(firestore, "users", user.uid);
          const userDoc = await getDoc(userRef);

          let userData = {};
          let mainUserId = null;

          if (!userDoc.exists()) {
            const accountPIN = Math.floor(Math.random() * 90 + 10);
            const phoneNumber = await provisionPhoneNumber();
            setPhoneNumber(phoneNumber);
            await setDoc(userRef, {
              email: user.email,
              createdOn: new Date(),
              isPaidAccount: false,
              creditsAvailable: parseInt(
                process.env.REACT_APP_FREE_CREDITS,
                10
              ),
              accessPhone: phoneNumber,
              accountPIN: accountPIN,
            });

            userData = {
              email: user.email,
              accessPhone: phoneNumber,
              creditsAvailable: parseInt(
                process.env.REACT_APP_FREE_CREDITS,
                10
              ),
            };

            setCurrentUser({
              ...user,
              accessPhone: phoneNumber,
              creditsAvailable: parseInt(
                process.env.REACT_APP_FREE_CREDITS,
                10
              ),
            });

            const formsRef = collection(firestore, "users", user.uid, "forms");
            const snapshot = await getDocs(formsRef);

            if (snapshot.empty && !exampleFormCreated) {
              await createExampleForm(user.uid);
              setExampleFormCreated(true); // Set the flag after creating the form
              await createInitialSession(user.uid);
              await sendWelcomeEmail(user.email, user.displayName);
            }

            setShowAccountNameModal(true);
          } else {
            userData = userDoc.data();
            if (userData.role === "sub-user" && userData.mainUserId) {
              mainUserId = userData.mainUserId;
            }
          }

          const userId = mainUserId || user.uid;

          if (mainUserId) {
            const mainUserRef = doc(firestore, "users", mainUserId);
            const mainUserDoc = await getDoc(mainUserRef);
            if (mainUserDoc.exists()) {
              userData = mainUserDoc.data();
            }
          }

          let phoneNumber = userData.accessPhone;
          if (!phoneNumber) {
            phoneNumber = await provisionPhoneNumber();
            await updateDoc(doc(firestore, "users", userId), {
              accessPhone: phoneNumber,
            });
          }
          setPhoneNumber(phoneNumber);
          setCurrentUser({
            ...user,
            ...userData,
            email: user.email,
            accessPhone: phoneNumber,
            mainUserId: mainUserId || null,
          });
          setAccountName(userData.accountName || "");
          setHoursSaved(userData.hoursSaved || 0);
          setCreditsAvailable(userData.creditsAvailable || 0);
          setSubUsers(userData.subUsers || []);

          // Check if accountName is not set and show the modal
          if (!userData.accountName) {
            setShowAccountNameModal(true);
          }

          const formsRef = collection(firestore, "users", userId, "forms");
          onSnapshot(formsRef, (snapshot) => {
            const formsData = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setForms(formsData);
          });

          const subFoldersRef = collection(
            firestore,
            "users",
            userId,
            "subFolders"
          );
          onSnapshot(subFoldersRef, (snapshot) => {
            const subFoldersData = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setSubFolders(subFoldersData);
          });

          const questionnairesRef = collection(
            firestore,
            "users",
            userId,
            "questionnaires"
          );
          onSnapshot(questionnairesRef, (snapshot) => {
            const questionnairesData = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setQuestionnaires(questionnairesData);
          });

          const activityRef = collection(
            firestore,
            "users",
            userId,
            "activity"
          );
          onSnapshot(activityRef, (snapshot) => {
            const activityData = snapshot.docs.map(
              (doc) => doc.data().activity
            );
            setRecentActivity(activityData);
          });

          calculateEstimatedHoursSaved();
        } else {
          setPhoneNumber("");
          setIsLoggedIn(false);
          setCurrentUser(null);
          setAccountName("");
          setHoursSaved(0);
          setCreditsAvailable(0);
          setRecentActivity([]);
          setForms([]);
          setSubFolders([]);
          setQuestionnaires([]);
          setSubUsers([]);
        }
      } catch (error) {
        console.error("Error in auth state change: ", error);
      } finally {
        setShowSpinner(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const onShowIntegrationModal = () => {
    console.log("Attempting to open Integration Modal from App");
    setShowIntegrationModal(true);
  };

  const handleQuickReply = (message) => {
    console.log("Quick reply chosen:", message);
    setCurrentState("chatting");
  };

  const currentMessage =
    "FormVox: Automate client forms and questionnaires with a voice chatbot that gathers the information and transcribes it, saving valuable time and increasing completion rates.";
  const handleShowEditFormModal = (form) => {
    setEditForm(form);
    setShowCreateFormModal(true);
  };
  const addSubUser = async (email, permission) => {
    if (currentUser) {
      const userRef = doc(firestore, "users", currentUser.uid);
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        const subUsers = userData.subUsers || [];
        if (subUsers.length < 5) {
          const newSubUser = {
            email: email.toLowerCase(),
            id: new Date().getTime().toString(),
            permission: permission,
          };
          subUsers.push(newSubUser);
          await updateDoc(userRef, { subUsers });

          // Send sign-up email to sub-user
          await sendSubUserEmail(newSubUser.email, currentUser.uid);

          // Update state and refresh sidebar
          setSubUsers(subUsers);
          refreshSidebar();
        } else {
          console.error("Maximum of 5 sub-users allowed");
        }
      }
    }
  };

  const handleEditPermissions = async (subUserId, newPermission) => {
    console.log(currentUser.uid);
    refreshSidebar();
    console.log("SUB USER ID " + subUserId);

    // Reference to the sub-user's document
    const subUserDocRef = doc(firestore, "users", subUserId);
    const subUserDoc = await getDoc(subUserDocRef);

    // Reference to the current user's document
    const currentUserDocRef = doc(firestore, "users", currentUser.uid);
    const currentUserDoc = await getDoc(currentUserDocRef);

    if (subUserDoc.exists() && currentUserDoc.exists()) {
      // Update the permissions in the sub-user's document
      await updateDoc(subUserDocRef, {
        permission: newPermission,
      });

      // Locate the sub-user in the subUsers array and update permissions
      const currentUserData = currentUserDoc.data();
      const subUsers = currentUserData.subUsers || [];
      const updatedSubUsers = subUsers.map((subUser) => {
        if (subUser.id === subUserId) {
          return { ...subUser, permission: newPermission };
        }
        return subUser;
      });

      // Update the subUsers array in the current user's document
      await updateDoc(currentUserDocRef, {
        subUsers: updatedSubUsers,
      });

      toast.success("Permissions updated successfully!", { autoClose: 10000 });
      refreshSidebar();
    } else {
      toast.error("User has not accepted the invite yet.", {
        autoClose: 10000,
      });
    }
  };

  const sendSubUserEmail = async (email, mainUserId) => {
    console.log("THIS EMAIL " + email);
    const signUpLink = `https://www.formvox.com/signup-additional-users?mainUserId=${mainUserId}`;
    const msg = {
      email: email,
      from: "contact@formvox.com",
      subject: "You have been added as a sub-user to FormVox",
      text: `You have been invited to join FormVox as a sub-user. Click the link to sign up: ${signUpLink}`,
      html: `<p>You have been invited to join FormVox as a sub-user. Click the link to sign up:</p><p><a href="${signUpLink}">Sign Up</a></p><p>FormVox LLC<br>30 N Gould St Ste R<br>Sheridan, WY 82801</p>`,
    };

    try {
      await fetch("https://sendemail-cgn2j7f72a-uc.a.run.app", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(msg),
      });
      console.log("Sub-user invitation email sent successfully");
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };
  const removeSubUser = async (id) => {
    if (currentUser) {
      const userRef = doc(firestore, "users", currentUser.uid);
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        const subUsers = userData.subUsers || [];
        const subUserToRemove = subUsers.find((user) => user.id === id);
        const updatedSubUsers = subUsers.filter((user) => user.id !== id);

        // Remove sub-user from main user's subUsers array
        await updateDoc(userRef, { subUsers: updatedSubUsers });
        setSubUsers(updatedSubUsers);

        // Call backend endpoint to delete sub-user
        if (subUserToRemove) {
          try {
            const response = await fetch(
              "https://deleteuser-cgn2j7f72a-uc.a.run.app",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({ subUserId: subUserToRemove.id }),
              }
            );

            if (!response.ok) {
              throw new Error("Failed to delete sub-user");
            }

            const result = await response.json();
            console.log(result.message);
          } catch (error) {
            console.error("Error deleting sub-user:", error);
          }
        }
      }
    }
  };

  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider).catch((error) => {
      console.error("Error signing in with Google: ", error);
      setError(error.message);
    });
  };

  const signInWithMicrosoft = () => {
    const provider = new OAuthProvider("microsoft.com");
    signInWithPopup(auth, provider).catch((error) => {
      console.error("Error signing in with Microsoft: ", error);
      setError(error.message);
    });
  };

  const signOutUser = () => {
    signOut(auth).catch((error) => {
      console.error("Error signing out: ", error);
      setError(error.message);
    });
  };

  const signUpWithEmail = async () => {
    setShowSpinner(true);
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      console.log("Signed up with email: ", user);
      setError("");

      const phoneNumber = await provisionPhoneNumber();
      const accountPIN = Math.floor(Math.random() * 90 + 10);

      await setDoc(doc(firestore, "users", user.uid), {
        email: user.email,
        createdOn: new Date(),
        isPaidAccount: false,
        creditsAvailable: parseInt(process.env.REACT_APP_FREE_CREDITS, 10),
        accessPhone: phoneNumber,
        accountPIN: accountPIN,
      });

      setCurrentUser({
        ...user,
        accessPhone: phoneNumber,
        creditsAvailable: parseInt(process.env.REACT_APP_FREE_CREDITS, 10),
      });

      const formsRef = collection(firestore, "users", user.uid, "forms");
      const snapshot = await getDocs(formsRef);

      if (snapshot.empty && !exampleFormCreated) {
        await createExampleForm(user.uid);
        setExampleFormCreated(true); // Set the flag after creating the form
        await createInitialSession(user.uid);
        await sendWelcomeEmail(user.email, user.displayName);
      }

      setShowAccountNameModal(true);
    } catch (error) {
      console.error("Error signing up with email: ", error);
      setError(error.message);
    } finally {
      setShowSpinner(false);
    }
  };

  const signInWithEmail = () => {
    setShowSpinner(true);
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        console.log("Signed in with email: ", userCredential.user);
        setError("");
      })
      .catch((error) => {
        console.error("Error signing in with email: ", error);
        setError(error.message);
      })
      .finally(() => {
        setShowSpinner(false);
      });
  };

  const handleAccountNameSubmit = async (isSubUserState, mainUserId) => {
    console.log("NOW");
    console.log(currentUser);
    console.log("NOW");

    if (isSubUserState) {
      window.location.reload();
    }

    if (!accountName.trim()) {
      setValidationError("Cannot be blank");
      return;
    }

    setShowSpinner(true);
    try {
      if (currentUser && accountName) {
        await setDoc(
          doc(firestore, "users", currentUser.uid),
          {
            accountName: accountName,
          },
          { merge: true }
        );

        const userDoc = await getDoc(doc(firestore, "users", currentUser.uid));
        if (userDoc.exists()) {
          setCurrentUser((prevUser) => ({
            ...prevUser,
            ...userDoc.data(),
          }));
          setCreditsAvailable(userDoc.data().creditsAvailable || 0);
        }

        setShowAccountNameModal(false);
      }
    } catch (error) {
      console.error("Error saving account name: ", error);
      setError(error.message);
    } finally {
      setShowSpinner(false);
    }
  };
  const handleCreateSubFolder = async (folderName, type) => {
    if (currentUser) {
      const userId = mainUserId || currentUser.uid;
      const collectionRef = collection(firestore, "users", userId, type);
      await addDoc(collectionRef, {
        name: folderName,
        forms: [],
        createdOn: new Date(),
      });
    }
  };

  const handleDeleteSubFolder = async (folderId, type) => {
    if (currentUser) {
      const userId = mainUserId || currentUser.uid;
      const collectionRef = collection(firestore, "users", userId, type);
      await deleteDoc(doc(collectionRef, folderId));
    }
  };

  return (
    <Router>
      <div className="App">
        {window.location.pathname !== "/help" && isLoggedIn && (
          <div
            className="top-right-menu"
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          >
            {isLoggedIn ? (
              <img src={logo} alt="FormVox Logo" className="logo" />
            ) : null}
            {isLoggedIn && (
              <FontAwesomeIcon icon={faCaretDown} className="dropdown-icon" />
            )}
            {isLoggedIn && isDropdownOpen && (
              <div className="dropdown-menu">
                {isLoggedIn ? (
                  <>
                    <div
                      className="dropdown-item"
                      onClick={handleOpenAccountSettingsModal}
                    >
                      Account Settings
                    </div>
                    <div className="dropdown-item">
                      <Link className="no-link" to="/help">
                        Help Topics
                      </Link>
                    </div>

                    <div className="dropdown-item" onClick={signOutUser}>
                      Sign Out
                    </div>
                  </>
                ) : (
                  <>
                    <div className="dropdown-item" onClick={signInWithGoogle}>
                      Sign In with Google
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        )}

        <Routes>
          <Route
            path="/customer-support-chat"
            element={<CustomerSupportChat firestore={firestore} />}
          />

          <Route path="/help" element={<HelpPage />} />

          <Route
            path="/signup-additional-users"
            element={<SubUserSignUp setOnSignUpPage={setOnSignUpPage} />}
          />
          {!isLoggedIn && (
            <Route
              path="/login"
              element={
                <Login
                  signUpWithEmail={signUpWithEmail}
                  signInWithEmail={signInWithEmail}
                  signInWithGoogle={signInWithGoogle}
                  signInWithMicrosoft={signInWithMicrosoft}
                  setEmail={setEmail}
                  setPassword={setPassword}
                  email={email}
                  password={password}
                  error={error}
                />
              }
            />
          )}
          {!isLoggedIn && <Route path="/" element={<LandingPageLegal />} />}
          {!isLoggedIn && (
            <Route path="/legal" element={<LandingPageLegal />} />
          )}

          {!isLoggedIn && (
            <Route path="/prof" element={<LandingPageProfessionals />} />
          )}
          {!isLoggedIn && <Route path="/hr" element={<LandingPageHr />} />}

          {!isLoggedIn && (
            <Route
              path="/signup"
              element={
                <Signup
                  signUpWithEmail={signUpWithEmail}
                  signInWithGoogle={signInWithGoogle}
                  signInWithMicrosoft={signInWithMicrosoft}
                  setEmail={setEmail}
                  setPassword={setPassword}
                  email={email}
                  password={password}
                  error={error}
                />
              }
            />
          )}
          {currentUser && (
            <Route
              path="/google-drive-callback"
              element={<GoogleDriveCallback currentUser={currentUser} />}
            />
          )}

          {/* Other routes */}
        </Routes>
        {isLoggedIn && window.location.pathname !== "/help" && (
          <>
            <button
              onClick={toggleSidebarVisibility}
              className="toggle-sidebar-btn"
            >
              <FontAwesomeIcon icon={faBars} />
            </button>

            <div className={`sidebar ${isSidebarOpen ? "active" : "inactive"}`}>
              <Sidebar
                isLoggedIn={isLoggedIn}
                currentUser={currentUser}
                signInWithGoogle={signInWithGoogle}
                signInWithMicrosoft={signInWithMicrosoft}
                signOutUser={signOutUser}
                signUpWithEmail={signUpWithEmail}
                signInWithEmail={signInWithEmail}
                setEmail={setEmail}
                setPassword={setPassword}
                email={email}
                password={password}
                error={error}
                forms={forms}
                subFolders={subFolders}
                accountName={accountName}
                creditsAvailable={creditsAvailable}
                onCreateSubFolder={handleCreateSubFolder}
                onDeleteSubFolder={handleDeleteSubFolder}
                recentActivity={recentActivity}
                onShowCreateFormModal={() => setShowCreateFormModal(true)}
                onShowCreateQuestionnaireModal={() =>
                  setShowCreateQuestionnaireModal(true)
                }
                onShowEditFormModal={handleShowEditFormModal}
                onShowAddCreditsModal={() => setShowAddCreditsModal(true)}
                onAddSubUser={addSubUser}
                subUsers={subUsers}
                onRemoveSubUser={removeSubUser}
                handleEditPermissions={handleEditPermissions}
                onShowRecentActivityModal={() =>
                  setShowRecentActivityModal(true)
                }
                isSidebarOpen={isSidebarOpen}
                setIsSidebarOpen={setIsSidebarOpen}
                onShowIntegrationModal={onShowIntegrationModal}
              />
            </div>
            <div className="content">
              {showChatInputModal && (
                <TutorialProvider>
                  {showAccountSettingsModal && (
                    <AccountSettingsModal
                      onClose={() => setShowAccountSettingsModal(false)}
                    />
                  )}
                  <ChatInput
                    key={modalKey}
                    sessions={sessions}
                    isSidebarOpen={isSidebarOpen}
                    currentUser={currentUser}
                    currentMessage={currentMessage}
                    onQuickReply={handleQuickReply}
                    onShowCreateFormModal={() => setShowCreateFormModal(true)}
                    onShowCreateQuestionnaireModal={() =>
                      setShowCreateQuestionnaireModal(true)
                    }
                    forms={forms}
                    subFolders={subFolders}
                    questionnaires={questionnaires}
                    onShowEditFormModal={handleShowEditFormModal}
                    onCreateSubFolder={handleCreateSubFolder}
                    onDeleteSubFolder={handleDeleteSubFolder}
                    showAccountNameModal={showAccountNameModal}
                  />
                </TutorialProvider>
              )}
            </div>
          </>
        )}

        {showAccountNameModal && (
          <div className="modal-background">
            <AccountNameModal
              accountName={accountName}
              setAccountName={setAccountName}
              onSubmit={handleAccountNameSubmit}
              validationError={validationError}
              isSubUser={!!currentUser?.mainUserId} // Pass isSubUser prop
            />
          </div>
        )}
        {showSpinner && (
          <div className="spinner-overlay">
            <ThreeDots color="#00BFFF" height={80} width={80} />
          </div>
        )}
        {showCreateFormModal && (
          <div className="modal-background">
            <CreateFormModal
              onClose={() => {
                setShowCreateFormModal(false);
                setEditForm(null);
              }}
              editForm={editForm}
              phoneNumber={phoneNumber}
              currentUser={currentUser}
            />
          </div>
        )}
        {showCreateQuestionnaireModal && (
          <div className="modal-background">
            <CreateQuestionnaireModal
              onClose={() => setShowCreateQuestionnaireModal(false)}
            />
          </div>
        )}
        {showStartNewVoiceForm && (
          <div className="modal-background">
            <StartNewVoiceForm
              onClose={() => setShowStartNewVoiceForm(false)}
              forms={forms}
            />
          </div>
        )}
        {showAddCreditsModal && (
          <div className="modal-background">
            <AddCreditsModal
              onClose={() => setShowAddCreditsModal(false)}
              currentUser={currentUser}
              refreshSidebar={refreshSidebar}
            />
          </div>
        )}

        {showIntegrationModal && currentUser && currentUser.uid && (
          <IntegrationModal
            onClose={() => setShowIntegrationModal(false)}
            currentUser={currentUser}
          />
        )}
        {showRecentActivityModal && (
          <RecentActivityModal
            onClose={() => setShowRecentActivityModal(false)}
            downloadAuditLog={downloadAuditLog}
          />
        )}
        <ToastContainer
          position="top-right"
          autoClose={10000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </Router>
  );
}

export default App;
