import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  OAuthProvider,
} from "firebase/auth";
import {
  doc,
  getFirestore,
  getDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  setDoc,
} from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle, faMicrosoft } from "@fortawesome/free-brands-svg-icons";
import { ThreeDots } from "react-loader-spinner";
import styles from "./SubUserSignUp.module.css"; // Import the CSS module

const SubUserSignUp = ({ setOnSignUpPage }) => {
  const navigate = useNavigate();
  const auth = getAuth();
  const firestore = getFirestore();
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isSignedUp, setIsSignedUp] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Add loading state
  const mainUserId = new URLSearchParams(location.search).get("mainUserId");

  useEffect(() => {
    setOnSignUpPage(true);
    return () => setOnSignUpPage(false);
  }, [setOnSignUpPage]);

  const handleSignUp = async (event) => {
    event.preventDefault();
    setIsLoading(true); // Start loading
    try {
      const normalizedEmail = email.trim().toLowerCase();
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        normalizedEmail,
        password
      );
      const user = userCredential.user;

      if (mainUserId) {
        const mainUserDocRef = doc(firestore, "users", mainUserId);
        const mainUserDoc = await getDoc(mainUserDocRef);

        if (mainUserDoc.exists()) {
          const mainUserData = mainUserDoc.data();
          const subUsers = mainUserData.subUsers || [];
          const existingSubUser = subUsers.find(
            (subUser) => subUser.email.toLowerCase() === normalizedEmail
          );

          if (existingSubUser) {
            // Remove the existing sub-user entry

            const permission = existingSubUser.permission;
            await updateDoc(mainUserDocRef, {
              subUsers: arrayRemove(existingSubUser),
            });

            // Add the new sub-user entry
            await updateDoc(mainUserDocRef, {
              subUsers: arrayUnion({
                email: normalizedEmail,
                id: user.uid,
                permission: permission,
              }),
            });

            // Create a document for the sub-user with their role and mainUserId
            await setDoc(doc(firestore, "users", user.uid), {
              email: normalizedEmail,
              role: "sub-user",
              mainUserId: mainUserId,
            });

            console.log(
              `Sub-user signed up successfully with mainUserId: ${mainUserId}`
            );

            // Update state to hide the sign-up section
            setIsSignedUp(true);
            // Navigate to the home page
            navigate("/", { replace: true });
          } else {
            setError("This email is not invited as a sub-user.");
          }
        } else {
          setError("Main user not found.");
        }
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false); // End loading
    }
  };

  const handleGoogleSignUp = async () => {
    setIsLoading(true); // Start loading
    try {
      const provider = new GoogleAuthProvider();
      const userCredential = await signInWithPopup(auth, provider);
      const user = userCredential.user;
      const normalizedEmail = user.email.toLowerCase();

      if (mainUserId) {
        const mainUserDocRef = doc(firestore, "users", mainUserId);
        const mainUserDoc = await getDoc(mainUserDocRef);

        if (mainUserDoc.exists()) {
          const mainUserData = mainUserDoc.data();
          const subUsers = mainUserData.subUsers || [];
          const existingSubUser = subUsers.find(
            (subUser) => subUser.email.toLowerCase() === normalizedEmail
          );

          if (existingSubUser) {
            // Remove the existing sub-user entry
            await updateDoc(mainUserDocRef, {
              subUsers: arrayRemove(existingSubUser),
            });

            // Add the new sub-user entry
            await updateDoc(mainUserDocRef, {
              subUsers: arrayUnion({
                email: normalizedEmail,
                id: user.uid,
              }),
            });

            // Create a document for the sub-user with their role and mainUserId
            await setDoc(doc(firestore, "users", user.uid), {
              email: normalizedEmail,
              role: "sub-user",
              mainUserId: mainUserId,
            });

            console.log(
              `Sub-user signed up successfully with mainUserId: ${mainUserId}`
            );

            // Update state to hide the sign-up section
            setIsSignedUp(true);
            // Navigate to the home page
            navigate("/", { replace: true });
          } else {
            setError("This email is not invited as a sub-user.");
          }
        } else {
          setError("Main user not found.");
        }
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false); // End loading
    }
  };

  const handleMicrosoftSignUp = async () => {
    setIsLoading(true); // Start loading
    try {
      const provider = new OAuthProvider("microsoft.com");
      const userCredential = await signInWithPopup(auth, provider);
      const user = userCredential.user;
      const normalizedEmail = user.email.toLowerCase();

      if (mainUserId) {
        const mainUserDocRef = doc(firestore, "users", mainUserId);
        const mainUserDoc = await getDoc(mainUserDocRef);

        if (mainUserDoc.exists()) {
          const mainUserData = mainUserDoc.data();
          const subUsers = mainUserData.subUsers || [];
          const existingSubUser = subUsers.find(
            (subUser) => subUser.email.toLowerCase() === normalizedEmail
          );

          if (existingSubUser) {
            // Remove the existing sub-user entry
            await updateDoc(mainUserDocRef, {
              subUsers: arrayRemove(existingSubUser),
            });

            // Add the new sub-user entry
            await updateDoc(mainUserDocRef, {
              subUsers: arrayUnion({
                email: normalizedEmail,
                id: user.uid,
              }),
            });

            // Create a document for the sub-user with their role and mainUserId
            await setDoc(doc(firestore, "users", user.uid), {
              email: normalizedEmail,
              role: "sub-user",
              mainUserId: mainUserId,
            });

            console.log(
              `Sub-user signed up successfully with mainUserId: ${mainUserId}`
            );

            // Update state to hide the sign-up section
            setIsSignedUp(true);
            // Navigate to the home page
            navigate("/", { replace: true });
          } else {
            setError("This email is not invited as a sub-user.");
          }
        } else {
          setError("Main user not found.");
        }
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false); // End loading
    }
  };

  return (
    <div className={styles.container}>
      <div
        className={`${styles.subUserAuthSection} ${
          isSignedUp ? styles.hidden : ""
        }`}
      >
        <h2>Sub-user Sign Up</h2>
        <form onSubmit={handleSignUp} className={styles.subUserAuthForm}>
          <div className={styles.subUserAuthItem}>
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className={styles.subUserAuthItem}>
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          {error && <div className={styles.subUserErrorMessage}>{error}</div>}
          <button type="submit" className={styles.subUserAuthButton}>
            Sign Up
          </button>
        </form>
        <div className={styles.subUserAuthDivider}>
          <div className={styles.line}></div>
          <span>OR</span>
          <div className={styles.line}></div>
        </div>
        <div
          className={styles.subUserAuthSocialItem}
          onClick={handleGoogleSignUp}
        >
          <FontAwesomeIcon icon={faGoogle} /> Continue with Google
        </div>
        <div
          className={styles.subUserAuthSocialItem}
          onClick={handleMicrosoftSignUp}
        >
          <FontAwesomeIcon icon={faMicrosoft} /> Continue with Microsoft
        </div>
      </div>
      {isLoading && (
        <div className={styles.spinnerOverlay}>
          <div className={styles.spinnerContainer}>
            <ThreeDots color="#00BFFF" height={80} width={80} />
          </div>
        </div>
      )}
    </div>
  );
};

export default SubUserSignUp;
