import React, { useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Button,
  Typography,
  Container,
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Slider,
  Card,
  CardContent,
  CardMedia,
  Tabs,
  Tab,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faCheckCircle,
  faCircleChevronDown,
  faCircleChevronUp,
  faFileAlt,
  faPause,
  faShieldAlt,
  faPoll,
  faCogs,
  faUsers,
  faBriefcase,
  faMobileAlt,
  faCloudUploadAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Menu as MenuIcon } from "@mui/icons-material";
import ReactPlayer from "react-player";
import Modal from "./LandingPageModal";
import logo from "../assets/logo.png";
import placeholderImage from "../assets/image001b.png";
import placeholderImage2 from "../assets/image002b.png";

// TabPanel Component (you need to create this)
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const LandingPage = () => {
  const [activeStep, setActiveStep] = useState(0);

  const handleStepChange = (event, newValue) => {
    setActiveStep(newValue);
  };

  const [questions, setQuestions] = useState(15); // Default number of questions
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [activeTab, setActiveTab] = useState("contact"); // Default active tab
  const faqRef = useRef(null);
  const priceRef = useRef(null);
  const navigate = useNavigate();

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const openModal = (tab) => {
    setActiveTab(tab);
    setModalVisible(true);
  };

  const handleSignUpClick = () => {
    navigate("/signup", { state: { activeTab: "signUp" } });
  };

  const calculateMetrics = (numQuestions) => {
    const timePerForm = 4 + ((numQuestions - 10) / 20) * 3;
    const formsPerHour = Math.floor(60 / timePerForm);
    const costPerForm = (10 / formsPerHour).toFixed(2);

    return {
      timePerForm: `${timePerForm.toFixed(0)} minutes`,
      formsPerHour,
      costPerForm: `$${costPerForm}`,
    };
  };

  const { timePerForm, formsPerHour, costPerForm } =
    calculateMetrics(questions);

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
    closeMenu();
  };

  const faqData = [
    {
      question: "How does a client complete a form using the voice chatbot?",
      answer:
        "The actual form filling or survey completion takes place over the phone when respondents are transferred to the voice chatbot (at a dedicated number we provide) or have them call directly and enter a unique session PIN.",
    },
    {
      question: "How does the client intake process work?",
      answer:
        "Easily onboard clients by sending them a tailored intake form they can complete at their convenience, ensuring you have all the necessary details before starting a project.",
    },
    {
      question: "How can I use this tool to skip unnecessary meetings?",
      answer:
        "Use pre-meeting screening forms to gather all the information you need upfront, allowing you to skip unnecessary meetings and focus on the work that matters.",
    },
    {
      question: "How can I define project scope using this tool?",
      answer:
        "Create detailed project scope questionnaires to ensure both you and your client are on the same page before beginning any work, reducing the risk of scope creep.",
    },

    {
      question:
        "Can clients pause and resume their forms later? / What happens if a voice form is interrupted?",
      answer:
        "Respondents can resume their questions at a later time. It means they can hang up or in case of accidental disconnection — using their session PIN they would connect again and pick up where they left off e.g. “continue from question #25…” so there is no threat of wasted time when going through a long question process.",
    },
    {
      question: "How can I integrate FormVox with other tools or platforms?",
      answer:
        "FormVox has built-in integration with Google Drive, presenting many automation opportunities out of the box.",
    },
    {
      question: "How secure is my client data?",
      answer:
        "Your data is secure with us. We prioritize protecting your client information, ensuring that all data is stored securely with industry-standard encryption.",
    },
    {
      question: "How do I start using this tool for my freelance work?",
      answer: (
        <Button variant="contained" onClick={handleSignUpClick}>
          Sign Up and Get Started
        </Button>
      ),
    },
  ];

  return (
    <Box>
      <AppBar position="static">
        <Toolbar>
          <img
            src={logo}
            alt="Your Freelance Tool Logo"
            style={{ height: "50px" }}
          />
          <Box sx={{ flexGrow: 1 }} />
          <Button color="inherit" onClick={() => navigate("/login")}>
            Sign In
          </Button>
          <Button variant="contained" onClick={handleSignUpClick}>
            Sign Up
          </Button>
          <IconButton
            color="inherit"
            edge="end"
            onClick={openMenu}
            aria-controls="simple-menu"
            aria-haspopup="true"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={closeMenu}
          >
            <MenuItem onClick={() => openModal("demo")}>Book a Demo</MenuItem>
            <MenuItem onClick={() => scrollToSection(priceRef)}>
              Pricing
            </MenuItem>
            <MenuItem onClick={() => scrollToSection(faqRef)}>FAQ</MenuItem>
            <MenuItem onClick={() => openModal("contact")}>Contact Us</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>

      <Box
        sx={{
          textAlign: "center",
          py: 4,
          bgcolor: "primary.main",
          color: "white",
        }}
      >
        <Container maxWidth="md">
          <Typography variant="h2" component="h1" gutterBottom>
            Automate Client Intake and Project Onboarding
          </Typography>
          <Typography variant="h5" paragraph>
            Clients can direct dial to complete the intake or project discovery
            form over the phone, or you can transfer them to our voice chatbot.
            It's as simple as speaking the answers—no more lengthy meetings or
            online forms.
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            onClick={handleSignUpClick}
          >
            Get Started Free
          </Button>
        </Container>
      </Box>
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <CardMedia
              component="img"
              alt="Form Creation"
              image={placeholderImage}
              sx={{
                width: "100%",
                height: "auto",
                objectFit: "contain", // or "cover" based on your needs
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: "flex", alignItems: "center" }} // Vertical alignment
          >
            <Box>
              <Typography variant="h4" gutterBottom>
                Quickly create client intake and project scope forms
              </Typography>
              <Typography variant="body1" paragraph>
                Customize forms with multiple question types, conditional logic,
                and optional spelling confirmation for complete control over
                client data collection. Whether it's a project brief or contract
                approval, tailor each form to your specific needs.
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={4} sx={{ mt: 4 }}>
          <Grid
            item
            xs={12}
            md={6}
            order={{ xs: 2, md: 1 }}
            sx={{ display: "flex", alignItems: "center" }} // Vertical alignment
          >
            <Box>
              <Typography variant="h4" gutterBottom>
                Skip the meeting—let your clients provide the details you need
                upfront
              </Typography>
              <Typography variant="body1" paragraph>
                Use pre-meeting screening forms to gather all the necessary
                details before scheduling time-consuming meetings. Focus on the
                work that matters most and keep projects on track.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
            <CardMedia
              component="img"
              alt="Call Transfers"
              image={placeholderImage2}
              sx={{
                width: "100%",
                height: "auto",
                objectFit: "contain", // or "cover" based on your needs
              }}
            />
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth="md" sx={{ py: 4, textAlign: "center" }}>
        <Typography variant="h3" gutterBottom>
          How It Works
        </Typography>
        <Tabs
          value={activeStep}
          onChange={handleStepChange}
          variant="scrollable" // Makes the Tabs scrollable
          scrollButtons="auto" // Adds arrows for scrolling on overflow
          indicatorColor="primary"
          textColor="primary"
          sx={{
            overflowX: "auto", // Allow the tabs to scroll horizontally if they overflow
            whiteSpace: "nowrap",
          }}
        >
          <Tab label="1. Add Your Questions" />
          <Tab label="2. Generate a Session PIN" />
          <Tab label="3. Start the Process" />
          <Tab label="4. View Responses" />
        </Tabs>

        <TabPanel value={activeStep} index={0}>
          <Typography variant="body1">
            Easily customize your client intake or project scope form by adding
            questions directly in the dashboard.
          </Typography>
        </TabPanel>
        <TabPanel value={activeStep} index={1}>
          <Typography variant="body1">
            Generate a unique session PIN for each client, ensuring secure and
            personalized access.
          </Typography>
        </TabPanel>
        <TabPanel value={activeStep} index={2}>
          <Typography variant="body1">
            Have your client call the dedicated number and enter their PIN to
            start the voice-powered form or screening.
          </Typography>
        </TabPanel>
        <TabPanel value={activeStep} index={3}>
          <Typography variant="body1">
            Instantly access and review client responses in CSV or PDF format,
            available immediately after completion.
          </Typography>
        </TabPanel>
      </Container>

      <Container
        maxWidth="md"
        sx={{ py: 4, textAlign: "center" }}
        ref={priceRef}
      >
        <Typography variant="h3" gutterBottom>
          Pricing Information
        </Typography>
        <Typography variant="h4" color="secondary" paragraph>
          Pay as you go – $10 per hour of Voice Chatbot Time
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
            <FontAwesomeIcon
              icon={faCheckCircle}
              color="primary"
              style={{ marginRight: 8 }}
            />
            <Typography variant="h6" color="primary">
              No subscription required!
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 1,
            }}
          >
            <FontAwesomeIcon
              icon={faCheckCircle}
              color="primary"
              style={{ marginRight: 8 }}
            />
            <Typography variant="h6" color="primary">
              Up to 16x cheaper than a human!
            </Typography>
          </Box>
        </Box>

        <Typography variant="body1" paragraph>
          Adjust the slider to estimate costs based on the number of questions
          per form:
        </Typography>
        <Slider
          value={questions}
          onChange={(e, val) => setQuestions(val)}
          min={10}
          max={100}
          step={5}
          sx={{ mb: 2 }}
        />
        <Typography variant="h6">
          Number of Questions per Form: {questions}
        </Typography>

        {/* Pricing Table */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 3,
          }}
        >
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              maxWidth: "500px",
            }}
          >
            <thead>
              <tr>
                <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                  Estimated Voice Chatbot Time
                </th>
                <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                  Forms Completed per Hour
                </th>
                <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                  Cost per Form Completion
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  {timePerForm}
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  {formsPerHour}
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  {costPerForm}
                </td>
              </tr>
            </tbody>
          </table>
        </Box>

        <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
          *Estimates only. Actual call length may vary.
        </Typography>
      </Container>

      <Container maxWidth="lg" sx={{ py: 4 }} ref={faqRef}>
        <Grid container spacing={4}>
          {faqData.map((faq, index) => (
            <Grid item xs={12} key={index}>
              <Typography
                variant="h5"
                gutterBottom
                sx={{
                  cursor: "pointer",
                  color: "primary.main",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                onClick={() =>
                  setActiveTab((prev) => (prev === index ? null : index))
                }
              >
                {faq.question}
                <FontAwesomeIcon
                  icon={activeTab === index ? faChevronUp : faChevronDown}
                />
              </Typography>
              {activeTab === index && (
                <Typography variant="body1" paragraph>
                  {faq.answer}
                </Typography>
              )}
            </Grid>
          ))}
        </Grid>
      </Container>

      <Modal
        show={modalVisible}
        onClose={() => setModalVisible(false)}
        initialTab={activeTab}
      />

      <Box
        sx={{ textAlign: "center", py: 2, bgcolor: "grey.900", color: "white" }}
      >
        <Typography variant="body2">
          &copy; 2024 Your Freelance Tool. All rights reserved.
        </Typography>
        <Typography variant="body2">
          30 N Gould St Ste R, Sheridan, WY 82801
        </Typography>
        <Typography variant="body2">
          Contact:{" "}
          <a
            href="mailto:contact@yourtool.com"
            style={{
              textDecoration: "none",
              color: "white",
            }}
          >
            contact@yourtool.com
          </a>
        </Typography>

        <Typography variant="body2">
          <a
            href="/privacy_policy.html"
            target="_blank"
            style={{
              textDecoration: "none",
              color: "white",
            }}
          >
            Privacy Policy
          </a>
        </Typography>
      </Box>
    </Box>
  );
};

export default LandingPage;
