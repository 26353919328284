// Modal.js
import React from "react";
import "./Modal.css";

const Modal = ({ message, onClose }) => {
  // Function to convert newline characters into line breaks
  const formatMessage = (message) => {
    return message.split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <p>{formatMessage(message)}</p>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default Modal;
