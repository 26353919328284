// GoogleDriveCallback.js
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";

const GoogleDriveCallback = ({ currentUser }) => {
  console.log("in g d c");
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get("code");
    console.log(currentUser);
    console.log(code);
    const uid = currentUser.uid;
    console.log(uid);
    if (code) {
      // Send the authorization code to your backend to exchange for an access token
      fetch("https://exchangetoken-cgn2j7f72a-uc.a.run.app", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ code, uid }),
      })
        .then((response) => {
          if (response.ok) {
            toast.success("Google Drive has been successfully connected!");
            return response.json(); // Parse JSON only if response is OK
          }
          throw new Error("Network response was not ok.");
        })
        .then((data) => {
          if (data.success) {
            console.log("Token exchange successful", data);
            navigate("/"); // Redirect to the main page or another appropriate route
          } else {
            console.error("Token exchange failed:", data.error);
            // Handle error, perhaps show user a message
            toast.error("An error occurred please contact support.");
          }
        })
        .catch((error) => {
          console.error("Error during token exchange:", error);
          // Handle network error, perhaps show user a message
        });
    }
  }, [location, navigate]);

  return (
    <div className="loading-spinner">
      <ThreeDots color="#00BFFF" height={80} width={80} />
    </div>
  );
};

export default GoogleDriveCallback;
