import React, { useState } from "react";
import "./ConfirmationModalDelete.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const ConfirmationModal = ({ message, onClose, onConfirm, additionalText }) => {
  const [confirmationInput, setConfirmationInput] = useState("");

  const handleConfirm = () => {
    if (confirmationInput === "DELETE") {
      onConfirm();
    } else {
      alert("Please type DELETE to confirm.");
    }
  };

  return (
    <div className="confirmation-modal-overlay">
      <div className="confirmation-modal-content">
        <p>{message}</p>
        {additionalText && (
          <p>
            <FontAwesomeIcon
              icon={faInfoCircle}
              style={{ marginRight: "8px" }}
            />
            {additionalText}
          </p>
        )}
        <input
          type="text"
          placeholder='Type "DELETE" to confirm'
          value={confirmationInput}
          onChange={(e) => setConfirmationInput(e.target.value)}
        />
        <div>
          <button className="confirm-btn" onClick={handleConfirm}>
            Confirm
          </button>
          <button className="close-btn" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
