import React, { useState } from "react";
import "./HelpPage.css"; // Make sure this path is correct
import ReactPlayer from "react-player";

import addUserImage from "../assets/add_users.png";
import logo from "../assets/logo.png"; // Add your logo file path here

const HelpPage = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const handleScroll = (id) => {
    document.getElementById(id).scrollIntoView({ behavior: "smooth" });
    setSelectedTopic(id); // Set the current topic as selected
    setSidebarOpen(false); // Optionally close the sidebar on selection on mobile
  };

  return (
    <div className="help-page">
      <header className="header-help-page">
        <a href="https://www.formvox.com">
          <img src={logo} alt="FormVox Logo" className="logo-help-page" />
        </a>
      </header>
      <button
        className="menu-icon-help-page"
        onClick={() => setSidebarOpen(!sidebarOpen)}
      >
        Help Topics
      </button>
      <div className="container-help-page">
        <aside
          className={`sidebar-help-page ${sidebarOpen ? "open-help-page" : ""}`}
        >
          <h2>Help Topics</h2>
          <ul>
            <li
              className={
                selectedTopic === "quickStart" ? "selected-help-page" : ""
              }
              onClick={() => handleScroll("quickStart")}
            >
              Quick Start Tutorial
            </li>
            <li
              className={
                selectedTopic === "addSubUsers" ? "selected-help-page" : ""
              }
              onClick={() => handleScroll("addSubUsers")}
            >
              How to Add Sub-users
            </li>
            <li
              className={
                selectedTopic === "trackUsage" ? "selected-help-page" : ""
              }
              onClick={() => handleScroll("trackUsage")}
            >
              Track Voice Chatbot Usage
            </li>
            <li
              className={
                selectedTopic === "billing" ? "selected-help-page" : ""
              }
              onClick={() => handleScroll("billing")}
            >
              How Does Billing Work?
            </li>
            <li
              className={
                selectedTopic === "integrateDrive" ? "selected-help-page" : ""
              }
              onClick={() => handleScroll("integrateDrive")}
            >
              Integrate Google Drive
            </li>
          </ul>
        </aside>
        <main className="main-content-help-page">
          <div id="quickStart" className="content-section-help-page">
            <h2>Quick Start Tutorial</h2>
            <ReactPlayer
              url="https://player.vimeo.com/video/991446894" // Replace {your_video_id} with actual Vimeo ID
              controls={true}
              width="100%"
              height="360px"
            />
            <p className="left-auto-first-help-page">
              Explore our QuickStart Tutorial Video to quickly learn the basics
              of our platform.
            </p>
            <p className="left-auto-help-page">What you'll learn:</p>
            <br></br>
            <ul className="left-auto-help-page">
              <li>
                <strong>Creating a form:</strong> Simple instructions for
                setting up your first form.
              </li>
              <li>
                <strong>Sharing your form:</strong> Easy ways to distribute your
                forms and reach your intended audience.
              </li>
              <li>
                <strong>Viewing responses:</strong> How to access and understand
                the responses from your forms.
              </li>
            </ul>
            <div className="steps-help-page">
              <h3>Creating a Form</h3>
              <ol className="left-auto-help-page">
                <li>
                  Log in to your FormVox account at{" "}
                  <a href="https://formvox.com/login">formvox.com/login</a>.
                </li>
                <li>
                  Click the <strong>Setup New Form</strong> button on the main
                  screen.
                </li>
                <li>
                  Name your form, e.g., "Client Intake #1", and click{" "}
                  <strong>Save</strong>.
                </li>
                <li>
                  To edit the name later, click the edit icon next to the form
                  name.
                </li>
                <li>
                  Start with the Welcome Message: Click{" "}
                  <strong>Edit Welcome Message</strong> to customize it.
                </li>
                <li>Save your changes.</li>
                <li>
                  Add questions by clicking <strong>Add Questions</strong>. For
                  example, add "What is your first name?" and select the
                  question type that confirms spelling. Click{" "}
                  <strong>Save</strong>.
                </li>
                <li>
                  Continue adding questions as needed. Use the{" "}
                  <strong>Yes/no conditional follow-ups</strong> for questions
                  that depend on a yes or no answer.
                </li>
                <li>
                  End with a Conclusion Message where you can provide final
                  instructions or expected follow-up time. Click{" "}
                  <strong>Save and Complete Form</strong>.
                </li>
              </ol>
              <h3>Sharing Your Form</h3>
              <ol className="left-auto-help-page">
                <li>
                  Click <strong>Create Form Session</strong> and select the form
                  you created.
                </li>
                <li>
                  Optionally assign a Client ID or use the{" "}
                  <strong>Auto Generate</strong> option for the system to create
                  one.
                </li>
                <li>
                  After creating the session, you will receive a session PIN.
                  Provide this PIN to the client if they are to call in
                  themselves, or use it to start the form session yourself, and
                  then transfer the client during the 10-seconds of hold music.
                </li>
              </ol>
              <h3>Viewing Form Responses</h3>
              <ol className="left-auto-help-page">
                <li>
                  Go to the main screen and view the status of form sessions
                  under the form you created.
                </li>
                <li>
                  Click on a session PIN to see detailed status and manage the
                  session.
                </li>
                <li>
                  As responses are collected, you can download transcripts,
                  either partial or complete, depending on the completion status
                  of the form.
                </li>
              </ol>
              <p className="left-auto-help-page">
                If you need further assistance, our support team is available
                via the chatbot on our website. Or contact us at{" "}
                <a href="mailto:support@formvox.com">support@formvox.com</a>.
              </p>
            </div>
          </div>

          <div id="addSubUsers" className="content-section-help-page">
            <h3>How to Add Sub-users to My Account?</h3>
            <p className="left-auto-help-page">
              Sub-users are individuals whom you grant access to your account.
              They can create and initiate forms, and perform other actions
              depending on the permissions you assign. Sub-users help manage
              workflows without needing full account ownership.
            </p>
            <br></br>
            <ol className="left-auto-help-page">
              <li>
                Click on <strong>Add Users</strong> in the left-side menu of
                your dashboard.
              </li>
              <img
                src={addUserImage}
                className="helpImage1-help-page"
                alt="Adding Sub-users Example"
              />
              <li>Type in the email address of the user you want to add.</li>
              <li>
                Select the level of permissions:
                <ul>
                  <li>
                    <strong>Full Access:</strong> Allows managing payments,
                    adding or removing payment methods, and viewing account
                    usage history.
                  </li>
                  <li>
                    <strong>No Billing Access:</strong> Permits the user to
                    perform all necessary account functions like creating forms
                    and initiating form sessions, without access to billing
                    information.
                  </li>
                </ul>
              </li>
              <li>
                Click <strong>Add</strong> to send an invitation to the user.
              </li>
              <li>
                Once the user accepts the invite and registers, they will be
                able to log in and access your account as per the permissions
                granted.
              </li>
              <li>
                To modify a sub-user's permissions at any time, select{" "}
                <strong>Edit</strong> next to their name, change the permission
                scope, and click <strong>Save</strong>.
              </li>
            </ol>
          </div>

          <div id="trackUsage" className="content-section-help-page">
            <h3>Track Voice Chatbot Usage</h3>
            <ol className="left-auto-help-page">
              <li>
                Click on <strong>Manage Billing</strong> in the left side menu.
              </li>
              <li>
                Click the <strong>View Purchase History/Usage</strong> button.
              </li>
              <li>
                On the next screen, click <strong>Download Usage Data</strong>{" "}
                to get a PDF.
              </li>
              <li>
                The PDF includes the last 300 sessions' billing information,
                session PINs, and billed amounts.
              </li>
              <li>
                The document updates dynamically to reflect usage, e.g., if a
                session resumes, the time updates accordingly.
              </li>
            </ol>
            <p className="left-auto-help-page">
              This real-time tracking helps you manage your expenses
              efficiently.
            </p>
          </div>

          <div id="billing" className="content-section-help-page">
            <h3>How Does Billing Work?</h3>
            <p className="left-auto-help-page">
              Billing for voice chatbot time is based on the purchase of blocks
              of time, such as 1 hour for $10. The duration of voice chatbot
              usage varies depending on the number of questions in a form and
              the length of the call.
            </p>
            <br></br>
            <p className="left-auto-help-page">
              Minutes are deducted in 30-second increments with rounding
              applied. For example, a call lasting 5 minutes and 21 seconds is
              billed as 5 minutes, while a call of 5 minutes and 45 seconds is
              rounded up and billed as 6 minutes.
            </p>
            <br></br>
            <p className="left-auto-help-page">
              This method ensures that you are billed fairly, only for the time
              that is utilized.
            </p>
          </div>

          <div id="integrateDrive" className="content-section-help-page">
            <h3>How to Integrate Google Drive?</h3>
            <ol className="left-auto-help-page">
              <li>
                Navigate to <strong>Integrations</strong> in the left menu.
              </li>
              <li>
                Agree to the terms and click <strong>Connect to Google</strong>.
              </li>
              <li>
                Follow the prompts to authorize FormVox to write transcripts to
                your Google Drive.
              </li>
              <li>
                Once connected, all completed session transcripts will be sent
                to a Google Drive folder named "FormVox/Month", where "Month" is
                the current month.
              </li>
              <li>You can disconnect Google Drive at any time if needed.</li>
            </ol>
            <br></br>
            <p className="left-auto-help-page">
              This integration simplifies managing your transcripts and creates
              new automation opportunities.
            </p>
          </div>
        </main>
      </div>
    </div>
  );
};

export default HelpPage;
