import React from "react";
import "./QuickReplies.css";

const QuickReplies = ({ onReply, isSidebarOpen, disabled, replies }) => {
  const handleReplyClick = (reply) => {
    onReply(reply);
  };

  return (
    <div className={`quick-replies ${isSidebarOpen ? "sidebar-open" : ""}`}>
      {replies.map((reply, index) => (
        <button
          key={index}
          onClick={() => handleReplyClick(reply)}
          disabled={disabled}
        >
          {reply}
        </button>
      ))}
    </div>
  );
};

export default QuickReplies;
