import React, { createContext, useContext, useState } from "react";

// Create Context
const TutorialContext = createContext();

// Hook for easy context usage
export const useTutorial = () => useContext(TutorialContext);

// Provider Component
export const TutorialProvider = ({ children }) => {
  const [runTour, setRunTour] = useState(false);

  const startTour = () => setRunTour(true);
  const stopTour = () => setRunTour(false);

  return (
    <TutorialContext.Provider value={{ runTour, startTour, stopTour }}>
      {children}
    </TutorialContext.Provider>
  );
};
