import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle, faMicrosoft } from "@fortawesome/free-brands-svg-icons";
import "./Signup.css"; // Create a new CSS file for the Signup component
import ReactPixel from "react-facebook-pixel";

const Signup = ({
  signUpWithEmail,
  signInWithGoogle,
  signInWithMicrosoft,
  setEmail,
  setPassword,
  email,
  password,
  error,
}) => {
  useEffect(() => {
    const options = {
      autoConfig: true, // set pixel's autoConfig
      debug: false, // enable logs
    };
    ReactPixel.init("622008134924153", {}, options);
    ReactPixel.track("InitiateCheckout"); // Track the ViewContent event
  }, []);
  return (
    <div className="signup-auth-container">
      <div className="signup-auth-box">
        <div className="signup-auth-section">
          <h1 className="signup-heading">
            Get 60 Minutes of Free Chatbot Talk Time
          </h1>

          <p className="signup-sub-heading">
            Sign up now and get 60 minutes of free chatbot talk time. Complete
            multiple phone interviews and gather forms effortlessly—no credit
            card required, cancel anytime.
          </p>

          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button onClick={signUpWithEmail}>Start Your Free Plan</button>
        </div>

        <div className="signup-auth-divider">
          <div className="line"></div>
          <span>OR</span>
          <div className="line"></div>
        </div>

        <div className="signup-auth-item" onClick={signInWithGoogle}>
          <FontAwesomeIcon icon={faGoogle} /> Continue with Google
        </div>
        <div className="signup-auth-item" onClick={signInWithMicrosoft}>
          <FontAwesomeIcon icon={faMicrosoft} /> Continue with Microsoft
        </div>
        {error && <div className="error-message">{error}</div>}

        {/*  <div className="signup-price-info-section">
          <h2>Our Pricing</h2>
          <p>Get started with our free plan or upgrade for more features.</p>
         Add more detailed pricing info here if needed 
        </div>

        <div className="signup-image-placeholder">
          <img
            src="path_to_your_image.jpg"
            alt="Signup"
            className="signup-image"
          />
        </div>*/}

        <div className="signup-toggle-auth">
          <span>
            Already have an account? <Link to="/login">Sign in</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Signup;
