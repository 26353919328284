import React, { Component } from "react";
import "./Dots.css"; // Import the CSS for the 3 dots

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.timeoutId = null;
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("ErrorBoundary caught an error", error, errorInfo);

    // Check for specific errors and handle them
    if (this.isRecoverableError(error)) {
      this.retryRender();
    }
  }

  isRecoverableError(error) {
    return this.isDOMException(error) || this.isSpecificTypeError(error);
  }

  isDOMException(error) {
    return (
      error instanceof DOMException &&
      (error.message.includes("insertBefore") ||
        error.message.includes("removeChild"))
    );
  }

  isSpecificTypeError(error) {
    return (
      error instanceof TypeError &&
      error.message.includes("u[v] is not a function")
    );
  }

  retryRender() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    this.timeoutId = setTimeout(() => {
      this.setState({ hasError: false });
      this.timeoutId = null;
    }, 3000); // Wait 3 seconds before retrying
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="dots">
          <h3>Loading</h3>
          <br></br>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
        </div>
      ); // Use the 3 dots loading animation
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
