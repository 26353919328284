import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faPlus,
  faMinus,
  faTrash,
  faCreditCard,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import {
  faCcVisa,
  faCcMastercard,
  faCcAmex,
  faCcDiscover,
} from "@fortawesome/free-brands-svg-icons";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import ConfirmationModal from "./ConfirmationModal";
import { ThreeDots } from "react-loader-spinner";
import "./AddCreditsModal.css";
import {
  getFirestore,
  doc,
  getDoc,
  updateDoc,
  collection,
  getDocs,
} from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

const stripePromise = loadStripe(
  // "pk_test_51PacsQLfKaTnLFZOThlmUSd0o9PK1vJFSlmjM3w45sd6n9bZeL6yMpoayo1qP55Gv1xw7PMHoPsx36nPkf2CxX9u00VFy0oYgl"
  "pk_live_51PacsQLfKaTnLFZOxYE0XkYzHkYXYeSwo3OOxPLILso1VnJ7d46oel3vLluGQkoYQBfiqz9XUfhyKKMhAzLExDPZ004DzcINrN"
);

const AddCreditsModal = ({ onClose, currentUser, refreshSidebar }) => {
  // Ensure currentUser.uid is set to mainUserId if currentUser.email exists in subUsers
  if (currentUser && currentUser.subUsers) {
    const subUser = currentUser.subUsers.find(
      (sub) => sub.email === currentUser.email
    );

    if (subUser) {
      currentUser.uid = currentUser.mainUserId;
    }
  }

  const [amount, setAmount] = useState(10); // Minimum amount is $10
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [confirmationAction, setConfirmationAction] = useState(null);
  const [initialLoad, setInitialLoad] = useState(true);
  const [loading, setLoading] = useState(false); // Add loading state
  const [autoRefill, setAutoRefill] = useState(false);
  const [autoRefillAmount, setAutoRefillAmount] = useState(10);
  const [saveCard, setSaveCard] = useState(false);
  const [savedCards, setSavedCards] = useState([]);
  const [customerId, setCustomerId] = useState(null); // State for customer ID
  const [selectedCard, setSelectedCard] = useState(null); // New state for selected card
  const [cardSelected, setCardSelected] = useState(false); // State for tracking if a card is selected
  const [cardToDelete, setCardToDelete] = useState(null); // State for tracking card to be deleted
  const [errorMessage, setErrorMessage] = useState(""); // Add error message state

  const [showPurchaseHistory, setShowPurchaseHistory] = useState(false);
  const [purchaseHistory, setPurchaseHistory] = useState([]); // Add this state
  const [loadingHistory, setLoadingHistory] = useState(false); // Add loading state for purchase history
  const [sessionUsage, setSessionUsage] = useState([]); // Add this state for session usage

  const firestore = getFirestore();

  useEffect(() => {
    const submitButtons = document.querySelectorAll('button[type="submit"]');
    const stripeElements = document.querySelectorAll(
      ".StripeElement.StripeElement--empty"
    );

    submitButtons.forEach((button) => {
      if (amount < 5) {
        button.style.display = "none"; // Hide submit buttons if amount is less than 5
      } else {
        button.style.display = ""; // Show submit buttons if amount is 5 or more
      }
    });

    stripeElements.forEach((element) => {
      if (cardSelected) {
        element.style.display = "none"; // Hide StripeElement if a card is selected
      } else {
        element.style.display = ""; // Show StripeElement if no card is selected
      }
    });
  }, [cardSelected, amount]);

  useEffect(() => {
    if (currentUser) {
      const fetchUserSettings = async () => {
        const userDoc = await getDoc(doc(firestore, "users", currentUser.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setAutoRefill(userData.autoRefill || false);
          setAutoRefillAmount(userData.autoRefillAmount || 10);
          setCustomerId(userData.stripeCustomerId || null); // Set customer ID
          if (userData.stripeCustomerId) {
            const response = await fetch(
              `https://retrievecustomercards-cgn2j7f72a-uc.a.run.app`,
              {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ customerId: userData.stripeCustomerId }),
              }
            );
            const data = await response.json();
            setSavedCards(data.cards || []);
          }
        }
        setInitialLoad(false); // Set initial load to false after fetching user settings
      };
      fetchUserSettings();
    } else {
      setInitialLoad(false); // If no currentUser, set initial load to false
    }
  }, [currentUser, firestore]);

  const handleIncrement = () => {
    setAmount(amount + 10);
    setErrorMessage(""); // Clear error message
  };

  const handleDecrement = () => {
    if (amount > 5) {
      setAmount(amount - 10);
      setErrorMessage(""); // Clear error message
    } else {
      setErrorMessage("$5 minimum");
    }
  };

  const handleClose = () => {
    setConfirmationMessage("Are you sure you want to close?");
    setConfirmationAction(() => handleConfirmClose);
    setShowConfirmationModal(true);
  };

  const handleConfirmClose = async () => {
    if (currentUser) {
      await updateDoc(doc(firestore, "users", currentUser.uid), {
        autoRefill,
        autoRefillAmount: isNaN(parseInt(autoRefillAmount, 10))
          ? 0
          : parseInt(autoRefillAmount, 10),
      });
    }
    setShowConfirmationModal(false);
    onClose();
  };

  const fetchPurchaseHistory = async () => {
    setLoadingHistory(true); // Set loading state to true before starting the fetch
    if (customerId) {
      const response = await fetch(
        `https://getpurchasehistory-cgn2j7f72a-uc.a.run.app`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ customerId }),
        }
      );
      const data = await response.json();
      setPurchaseHistory(data.history || []);
      setLoadingHistory(false); // Set loading state to false after fetch is complete
    } else {
      setLoadingHistory(false); // Set loading state to false if no customerId
    }
  };

  const fetchSessionUsage = async () => {
    if (currentUser) {
      const sessionsRef = collection(
        firestore,
        "users",
        currentUser.uid,
        "sessions"
      );
      const sessionsSnapshot = await getDocs(sessionsRef);
      const sessionsData = sessionsSnapshot.docs.map((doc) => doc.data());
      setSessionUsage(sessionsData);
    }
  };

  const getCardLogo = (brand) => {
    switch (brand.toLowerCase()) {
      case "visa":
        return faCcVisa;
      case "mastercard":
        return faCcMastercard;
      case "amex":
        return faCcAmex;
      case "discover":
        return faCcDiscover;
      default:
        return faCreditCard;
    }
  };

  const handleAmountChange = (e) => {
    const inputVal = e.target.value;
    const parsedValue = parseInt(inputVal, 10);
    if (!isNaN(parsedValue)) {
      setAmount(parsedValue);
      if (parsedValue < 5) {
        setErrorMessage("$5 minimum");
      } else {
        setErrorMessage("");
      }
    }
  };

  const handleSaveAndClose = () => {
    setConfirmationMessage("Are you sure you want to save and close?");
    setConfirmationAction(() => handleConfirmClose);
    setShowConfirmationModal(true);
  };

  const handleAmountBlur = () => {
    const parsedValue = parseInt(amount, 10); // Parse the latest state on blur
    const validatedAmount = isNaN(parsedValue) ? 10 : Math.max(5, parsedValue);
    setAmount(validatedAmount);
    if (validatedAmount < 5) {
      setErrorMessage("$5 minimum");
    } else {
      setErrorMessage("");
    }
  };

  const handleAutoRefillChange = async (e) => {
    const newAutoRefill = e.target.checked;
    setAutoRefill(newAutoRefill);
    if (!initialLoad && currentUser) {
      await updateDoc(doc(firestore, "users", currentUser.uid), {
        autoRefill: newAutoRefill,
        autoRefillAmount,
      });
    }
  };

  const handleAutoRefillAmountChange = (e) => {
    const newAutoRefillAmount = e.target.value;
    setAutoRefillAmount(newAutoRefillAmount);
  };

  const handleSaveCardChange = (e) => setSaveCard(e.target.checked);

  const handleCardSelect = (card) => {
    if (selectedCard && selectedCard.id === card.id) {
      // If the clicked card is already selected, deselect it
      setSelectedCard(null);
      setCardSelected(false); // Toggle cardSelected state
    } else {
      // Otherwise, select the clicked card
      setSelectedCard(card);
      setCardSelected(true); // Toggle cardSelected state
    }
  };

  const handleCardDelete = async () => {
    if (currentUser && customerId && cardToDelete) {
      setLoading(true); // Start loading
      await fetch(`https://deletecustomercard-cgn2j7f72a-uc.a.run.app`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ customerId, cardId: cardToDelete }),
      });

      // Remove the deleted card from savedCards
      const updatedCards = savedCards.filter(
        (card) => card.id !== cardToDelete
      );
      setSavedCards(updatedCards);

      // Check if there are any remaining saved cards
      if (updatedCards.length === 0) {
        // Update autoRefill to false and autoRefillAmount to null in Firestore
        await updateDoc(doc(firestore, "users", currentUser.uid), {
          autoRefill: false,
          autoRefillAmount: null,
        });
      }

      toast.success("Saved card removed");
      setLoading(false); // Stop loading
      setShowConfirmationModal(false);
    }
  };

  const confirmCardDelete = (cardId) => {
    setCardToDelete(cardId);
    setConfirmationMessage("Are you sure you want to delete this card?");
    setConfirmationAction(() => handleCardDelete);
    setShowConfirmationModal(true);
  };

  const handlePurchaseResult = (result) => {
    setLoading(false); // Stop loading
    if (result.success) {
      toast.success("Purchase successful!", { autoClose: 10000 });

      // Prepare email data
      const emailData = {
        email: currentUser.email,
        name: currentUser.displayName,
        amount: amount.toFixed(2),
        from: "FormVox Support <contact@formvox.com>",
        subject: "Order Confirmation - FormVox",
        text: `Dear ${
          currentUser.displayName || "Customer"
        },\n\nThank you for your purchase. Here are the details of your transaction:\n\nAmount: $${amount.toFixed(
          2
        )}\nStatus: Succeeded\n\nIf you have any questions, please feel free to contact us at contact@formvox.com.\n\nBest regards,\nFormVox Team\n\nFormVox LLC\n30 N Gould St Ste R\nSheridan, WY 82801\ncontact@formvox.com`,
        html: `<p>Dear ${
          currentUser.displayName || "Customer"
        },</p><p>Thank you for your purchase. Here are the details of your transaction:</p><p><strong>Amount:</strong> $${amount.toFixed(
          2
        )}<br><strong>Status:</strong> Succeeded</p><p>If you have any questions, please feel free to contact us at <a href="mailto:contact@formvox.com">contact@formvox.com</a>.</p><p>Best regards,<br>FormVox Team</p><p>FormVox LLC<br>30 N Gould St Ste R<br>Sheridan, WY 82801<br><a href="mailto:contact@formvox.com">contact@formvox.com</a></p>`,
      };

      // Send order confirmation email
      try {
        fetch("https://sendemail-cgn2j7f72a-uc.a.run.app", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(emailData),
        });
        console.log("Order confirmation email sent successfully");
      } catch (error) {
        console.error("Error sending email:", error);
      }
      refreshSidebar();
    } else {
      setLoading(false);
      toast.error(`Purchase failed: ${result.message}`, { autoClose: false });
    }
  };

  useEffect(() => {
    const updateAutoRefillAmount = async () => {
      const newAutoRefillAmount = parseInt(autoRefillAmount, 10);
      if (!initialLoad && currentUser) {
        await updateDoc(doc(firestore, "users", currentUser.uid), {
          autoRefill,
          autoRefillAmount: isNaN(newAutoRefillAmount)
            ? 0
            : newAutoRefillAmount,
        });
      }
    };
    updateAutoRefillAmount();
  }, [autoRefillAmount, autoRefill, currentUser, firestore, initialLoad]);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };

  const generatePDF = () => {
    const doc = new jsPDF();

    doc.setFontSize(12);

    doc.text("FormVox LLC", 10, 10);
    doc.text("30 N Gould St Ste R", 10, 20);
    doc.text("Sheridan, WY 82801", 10, 30);
    doc.text("contact@formvox.com", 10, 40);

    doc.setFontSize(14);
    doc.text("Transaction History", 10, 60);

    doc.setFontSize(10);
    const rows = purchaseHistory
      .sort((a, b) => b.created - a.created)
      .map((purchase) => [
        formatDate(purchase.created),
        `$${(purchase.amount / 100).toFixed(2)}`,
        purchase.status,
        purchase.description || "FormVox Services",
      ]);

    autoTable(doc, {
      head: [["Date", "Amount", "Status", "Description"]],
      body: rows,
      startY: 70,
    });

    doc.save("purchase_history.pdf");
  };
  const generateUsagePDF = () => {
    const doc = new jsPDF();

    doc.setFontSize(14);
    doc.text("Session Usage Data", 10, 10);

    doc.setFontSize(10);
    const rows = sessionUsage
      .filter((session) => session.cumulativeMinutes > 0) // Filter sessions with minutes data
      .sort((a, b) => b.createdAt.seconds - a.createdAt.seconds)
      .map((session) => [
        session.sessionID,
        session.clientID,
        session.cumulativeMinutes || 0,
        formatDate(session.createdAt.seconds),
      ]);

    autoTable(doc, {
      head: [
        ["Session ID", "Client ID", "Minutes Used", "Session Created Date"],
      ],
      body: rows,
      startY: 20,
    });

    doc.save("session_usage.pdf");
  };

  return (
    <>
      {initialLoad ? (
        <div className="loading-spinner">
          <ThreeDots color="#00BFFF" height={80} width={80} />
        </div>
      ) : (
        <div className="modal-overlay">
          <div className="modal-content">
            <div className="modal-header">
              <h2>Add Credits / View Usage</h2>
              <button className="close-btn" onClick={handleClose}>
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
            {!loading && (
              <>
                <button
                  className="view-purchase-history-btn"
                  onClick={() => {
                    setShowPurchaseHistory(!showPurchaseHistory);
                    if (!showPurchaseHistory) {
                      fetchPurchaseHistory();
                      fetchSessionUsage(); // Fetch session usage when viewing purchase history
                    }
                  }}
                >
                  {showPurchaseHistory ? "Hide" : "View"} Purchase History /
                  Usage
                </button>
                {showPurchaseHistory && (
                  <div className="purchase-history">
                    {loadingHistory ? (
                      <div className="loading-background">
                        <div className="loading-spinner">
                          <ThreeDots color="#00BFFF" height={80} width={80} />
                        </div>
                      </div>
                    ) : purchaseHistory.length > 0 ? (
                      <>
                        <div className="history-header">
                          <p>
                            View/Download Purchase History or Download Usage
                            History
                          </p>
                          <button
                            className="download-pdf-btn"
                            onClick={generatePDF}
                          >
                            <FontAwesomeIcon icon={faDownload} /> Download
                            Purchase History
                          </button>
                          <button
                            className="add-download-usage-btn"
                            onClick={generateUsagePDF}
                          >
                            <FontAwesomeIcon icon={faDownload} /> Download Usage
                            Data
                          </button>
                        </div>
                        <ul>
                          {purchaseHistory.map((purchase) => (
                            <li key={purchase.id} className="purchase-item">
                              <div className="purchase-details">
                                <p>
                                  <strong>Date:</strong>{" "}
                                  {formatDate(purchase.created)}
                                </p>
                                <p>
                                  <strong>Amount:</strong> $
                                  {(purchase.amount / 100).toFixed(2)}
                                </p>
                                <p>
                                  <strong>Status:</strong> {purchase.status}
                                </p>
                                <p>
                                  <strong>Description:</strong>{" "}
                                  {purchase.description || "FormVox Services"}
                                </p>
                              </div>
                              <hr />
                            </li>
                          ))}
                        </ul>
                      </>
                    ) : (
                      <p>No purchase history found.</p>
                    )}
                  </div>
                )}
              </>
            )}

            <div className="modal-body">
              {loading && (
                <div className="loading-spinner">
                  <ThreeDots color="#00BFFF" height={80} width={80} />
                </div>
              )}
              {!loading && (
                <>
                  <label className="amount-label">
                    To add money to your balance, enter an amount below:
                  </label>
                  <div className="amount-selection">
                    <button className="decrement-btn" onClick={handleDecrement}>
                      <FontAwesomeIcon icon={faMinus} />
                    </button>
                    <span>$</span>{" "}
                    <input
                      type="number"
                      value={amount}
                      onChange={handleAmountChange}
                      onBlur={handleAmountBlur}
                      className="amount-input"
                      min="5"
                    />
                    <button className="increment-btn" onClick={handleIncrement}>
                      <FontAwesomeIcon icon={faPlus} />
                    </button>
                  </div>
                  {errorMessage && (
                    <div className="error-message">{errorMessage}</div>
                  )}
                  <div className="equivalent-time">
                    <strong>{amount * 6} minutes</strong> of Voice Chatbot Time
                  </div>
                  <div className="auto-refill">
                    {autoRefill && (
                      <div className="auto-refill-info">
                        <h4>Auto-Refill Enabled</h4>
                        <p>Amount: ${autoRefillAmount}</p>
                        {savedCards.length > 0 && (
                          <p>
                            Card: {savedCards[0].brand.toUpperCase()} ending in{" "}
                            {savedCards[0].last4}
                          </p>
                        )}
                      </div>
                    )}
                    <label>
                      <input
                        type="checkbox"
                        checked={autoRefill}
                        onChange={handleAutoRefillChange}
                      />
                      Auto refill if balance reaches 0
                    </label>
                    {autoRefill && (
                      <div className="auto-refill-amount">
                        <label>
                          Refill amount: $
                          <input
                            type="number"
                            value={autoRefillAmount}
                            onChange={handleAutoRefillAmountChange}
                            className="amount-input"
                          />
                        </label>
                      </div>
                    )}
                  </div>
                  <div className="save-card">
                    <label>
                      <input
                        type="checkbox"
                        checked={saveCard}
                        onChange={handleSaveCardChange}
                      />
                      Save card for future payments
                    </label>
                  </div>
                  {savedCards.length > 0 && (
                    <div className="saved-cards">
                      <h3>Saved Cards</h3>
                      <p>Click a saved card to use or enter card number</p>
                      <ul>
                        {savedCards.map((card) => (
                          <li
                            key={card.id}
                            onClick={() => handleCardSelect(card)}
                            className={
                              selectedCard?.id === card.id ? "selected" : ""
                            }
                          >
                            <div className="card-details">
                              <FontAwesomeIcon
                                icon={getCardLogo(card.brand)}
                                size="2x"
                              />
                              {card.brand.toUpperCase()} ending in {card.last4}
                            </div>
                            <FontAwesomeIcon
                              icon={faTrash}
                              className="delete-icon"
                              onClick={(e) => {
                                e.stopPropagation();
                                confirmCardDelete(card.id); // Set the card to be deleted
                              }}
                            />
                          </li>
                        ))}
                      </ul>
                      {/* Display "Deselect card" checkbox only when a card is selected */}
                      {cardSelected && (
                        <div className="card-selection-toggle">
                          <label>
                            <input
                              type="checkbox"
                              checked={selectedCard !== null}
                              onChange={() => {
                                setSelectedCard(null);
                                setCardSelected(false);
                              }}
                            />
                            Deselect card
                          </label>
                        </div>
                      )}
                    </div>
                  )}
                  {savedCards.length === 0 && (
                    <div className="saved-cards">
                      <p>Enter card number</p>
                    </div>
                  )}
                  <div className="stripe-checkout">
                    <Elements stripe={stripePromise}>
                      <CheckoutForm
                        amount={amount}
                        customerId={customerId}
                        userId={currentUser.uid}
                        autoRefill={autoRefill}
                        autoRefillAmount={autoRefillAmount}
                        saveCard={saveCard}
                        selectedCard={selectedCard} // Pass the selected card to the checkout form
                        onPurchaseResult={handlePurchaseResult} // Pass the handler for purchase results
                        setLoading={setLoading} // Pass the setLoading function to the checkout form
                      />
                    </Elements>
                  </div>
                  <div className="modal-footer">
                    <button
                      className="save-close-btn"
                      onClick={handleSaveAndClose}
                    >
                      Save and Close
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
      {showConfirmationModal && (
        <ConfirmationModal
          message={confirmationMessage}
          onClose={() => setShowConfirmationModal(false)}
          onConfirm={confirmationAction}
        />
      )}
    </>
  );
};

export default AddCreditsModal;
