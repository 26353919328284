import React, { useState, useEffect } from "react";
import {
  getFirestore,
  doc,
  getDoc,
  updateDoc,
  deleteField,
} from "firebase/firestore";
import "./IntegrationModal.css";
import googleDriveIcon from "../assets/logo_drive_2020q4_color_2x_web_64dp.png";
import oneDriveIcon from "../assets/128px-Microsoft_Office_OneDrive_2019–present.png";
import ConfirmationModal from "./ConfirmationModalHIPAA"; // Ensure the path is correct

const IntegrationModal = ({ onClose, currentUser }) => {
  const firestore = getFirestore();
  const [isConnected, setIsConnected] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [showDisclaimer, setShowDisclaimer] = useState(false);

  // Determine the effective user ID to use throughout the component
  const effectiveUserId = currentUser?.mainUserId || currentUser?.uid;

  useEffect(() => {
    const checkConnection = async () => {
      if (currentUser) {
        const userRef = doc(firestore, "users", effectiveUserId);
        const docSnap = await getDoc(userRef);
        if (docSnap.exists() && docSnap.data().googleDriveTokens) {
          setIsConnected(true);
        } else {
          setIsConnected(false);
        }
      }
    };

    checkConnection();
  }, [currentUser, firestore, effectiveUserId]); // Add effectiveUserId to dependency array

  const handleGoogleConnect = () => {
    if (!isChecked) {
      alert(
        "Please agree to the HIPAA Compliance Disclaimer before proceeding."
      );
      return;
    }
    console.log("right here");
    const rootUrl = "https://accounts.google.com/o/oauth2/v2/auth";
    const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
    const redirectUri = encodeURIComponent(
      "https://formvox.com/google-drive-callback"
    );

    const scopes = [
      "https://www.googleapis.com/auth/drive.file",
      "https://www.googleapis.com/auth/drive.appdata",
    ];
    const scope = encodeURIComponent(scopes.join(" "));
    const authUrl = `${rootUrl}?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&access_type=offline&prompt=consent`;

    window.location.href = authUrl;
  };

  const handleDisconnect = async () => {
    if (currentUser) {
      const userRef = doc(firestore, "users", effectiveUserId);
      await updateDoc(userRef, {
        googleDriveTokens: deleteField(),
      });
      setIsConnected(false);
    }
  };

  const handleDisclaimerClose = (agree) => {
    setShowDisclaimer(false);
    if (agree) {
      setIsChecked(true);
    }
  };

  const openDisclaimer = () => {
    setShowDisclaimer(true);
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <h2>Integrations</h2>
          <button className="close-btn" onClick={onClose}>
            &times;
          </button>
        </div>
        <div className="modal-body">
          <h3>Connect to your favorite services</h3>
          <div className="service-section">
            <div className="service">
              <img src={googleDriveIcon} alt="Google Drive" />
              {isConnected ? (
                <div>
                  <p>Connected to Google Drive</p>
                  <button onClick={handleDisconnect}>Disconnect</button>
                </div>
              ) : (
                <>
                  <div>
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={(e) => setIsChecked(e.target.checked)}
                    />{" "}
                    <span
                      style={{ cursor: "pointer", textDecoration: "underline" }}
                      onClick={openDisclaimer}
                    >
                      I have read the HIPAA Compliance Disclaimer
                    </span>
                  </div>
                  <div className="button-group">
                    <button disabled={!isChecked} onClick={handleGoogleConnect}>
                      Connect to Google Drive
                    </button>
                  </div>
                </>
              )}
            </div>
            {/*  <div className="service">
              <img src={oneDriveIcon} alt="OneDrive" />
              <button disabled>Connect to OneDrive (Coming Soon)</button>
            </div>*/}
          </div>
        </div>
        <div className="modal-footer">
          <button className="save-close-btn" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
      {showDisclaimer && (
        <ConfirmationModal
          message={`HIPAA Compliance Disclaimer\n\nPlatform Compliance: Our platform adheres to HIPAA compliance standards, ensuring the confidentiality, integrity, and availability of protected health information (PHI) that is processed and stored within our systems. We offer a Business Associate Agreement (BAA) that covers the data managed directly within our platform.\n\nGoogle Drive Integration: Users have the option to integrate with Google Drive for storing or managing files generated by our platform. While we facilitate this integration:\n\nUser Responsibility: The responsibility for ensuring HIPAA compliance when using Google Drive lies with you, the user. This includes choosing an appropriate version of Google Drive (such as Google Workspace or Google Workspace for Healthcare) that supports HIPAA compliance and obtaining a BAA directly from Google.\nAccount Requirements: It is crucial that you use a Google account that is eligible for a BAA with Google. The free version of Google Drive does not typically support HIPAA compliance. Ensure you upgrade to a suitable paid Google Workspace plan.\nCompliance Maintenance: When utilizing Google Drive in conjunction with our services, compliance with HIPAA and the maintenance of appropriate safeguards is your responsibility. This includes securing PHI, managing access controls, and adhering to proper data protection protocols as required by HIPAA.\nConsult Legal Advice: We recommend consulting with a legal expert specialized in HIPAA compliance to ensure that your use of Google Drive in conjunction with our services meets all necessary legal requirements.\n\nBy using the Google Drive integration feature, you acknowledge and agree that you have the requisite arrangements in place with Google, including a BAA, and that you maintain all necessary measures to comply with HIPAA and other relevant laws.`}
          onClose={() => setShowDisclaimer(false)}
          onConfirm={() => handleDisclaimerClose(true)}
        />
      )}
    </div>
  );
};

export default IntegrationModal;
