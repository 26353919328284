import React, { useState, useEffect } from "react";
import "./LandingPageModal.css"; // Ensure your CSS file is correctly linked

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faClose,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";

const Modal = ({ show, onClose, initialTab }) => {
  const [activeTab, setActiveTab] = useState(initialTab);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    setActiveTab(initialTab);
  }, [initialTab]);

  useEffect(() => {
    if (show) {
      setIsSubmitted(false); // Reset only when modal is shown
    }
  }, [show]);

  const scrollToBottom = () => {
    const form = document.querySelector(".lp-input-form");
    form.scrollIntoView({ behavior: "smooth", block: "end", offset: 20 });
  };

  if (!show) {
    return null;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const formProps = Object.fromEntries(formData.entries());
    const isDemo = activeTab === "demo";
    const message = isDemo
      ? "New Demo Request - FormVox"
      : "New Contact Request - FormVox";

    const emailContent = `
      <p><strong>Name:</strong> ${formProps.name}</p>
      <p><strong>Email Address:</strong> ${formProps.email}</p>
      <p><strong>Phone Number:</strong> ${formProps.phone}</p>
      <p><strong>Company Name:</strong> ${
        formProps.company || "Not provided"
      }</p>
      <p><strong>Request:</strong> ${formProps.request}</p>
      ${
        isDemo
          ? `<p><strong>Preferred Day:</strong> ${formProps["preferred-day"]}</p>
                  <p><strong>Preferred Time:</strong> ${formProps["preferred-time"]}</p>
                  <p><strong>Time Zone:</strong> ${formProps["time-zone"]}</p>`
          : ""
      }
    `;

    const dataToSend = {
      email: "formvoxllc@gmail.com",
      from: "contact@formvox.com",
      subject: message,
      text: `A new form has been submitted as a ${message}. Please check the details below:\nName: ${
        formProps.name
      }\nEmail: ${formProps.email}\nPhone: ${formProps.phone}\nRequest: ${
        formProps.request
      }${
        isDemo
          ? `\nPreferred Day: ${formProps["preferred-day"]}\nPreferred Time: ${formProps["preferred-time"]}\nTime Zone: ${formProps["time-zone"]}`
          : ""
      }`,
      html: `<h1>${message}</h1>${emailContent}`,
    };

    console.log("Data to send:", dataToSend); // Log the data being sent

    try {
      const response = await fetch(
        "https://sendemail-cgn2j7f72a-uc.a.run.app",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataToSend),
        }
      );
      console.log("Response:", response); // Log the response
      if (response.status === 200) {
        console.log("Email sent successfully");
        setIsSubmitted(true);
      } else {
        console.error("Failed to send email:", response.statusText);
        alert("Failed to send email.");
      }
    } catch (error) {
      console.error("Failed to send email:", error);
      alert("Failed to send email.");
    }
  };

  return (
    <div className="lp-modal-overlay">
      <div className="lp-modal-content">
        <div className="lp-modal-close" onClick={onClose}>
          <FontAwesomeIcon icon={faClose} />
        </div>

        {isSubmitted ? (
          <div className="success-message">
            <FontAwesomeIcon icon={faCheckCircle} size="3x" color="green" />
            <h3>Message sent successfully.</h3>
            <h4>We will contact you to confirm shortly.</h4>
            <button className="lp-close-button" onClick={onClose}>
              Close
            </button>
          </div>
        ) : (
          <>
            <h2>{activeTab === "demo" ? "Schedule a Demo" : "Contact Us"}</h2>
            <div className="lp-tabs">
              <div
                className={`lp-tab ${activeTab === "contact" ? "active" : ""}`}
                onClick={() => setActiveTab("contact")}
              >
                Contact Us
              </div>
              <div
                className={`lp-tab ${activeTab === "demo" ? "active" : ""}`}
                onClick={() => setActiveTab("demo")}
              >
                Schedule a Demo
              </div>
            </div>

            <form className="lp-input-form" onSubmit={handleSubmit}>
              <label className="lp-input-label">Email Address *</label>
              <input
                name="email"
                className="lp-input-field"
                type="email"
                placeholder="Email Address *"
                required
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                title="Please enter a valid email address."
              />

              <label className="lp-input-label">Phone Number *</label>
              <input
                name="phone"
                className="lp-input-field"
                type="tel"
                placeholder="Phone Number *"
                required
              />

              <label className="lp-input-label">Name *</label>
              <input
                name="name"
                className="lp-input-field"
                type="text"
                placeholder="Name *"
                required
              />

              <label className="lp-input-label">Company Name</label>
              <input
                name="company"
                className="lp-input-field"
                type="text"
                placeholder="Company Name"
              />

              <label className="lp-input-label">Write your request *</label>
              <textarea
                name="request"
                className="lp-input-field"
                placeholder="Write your request *"
                required
              ></textarea>

              {activeTab === "demo" && (
                <>
                  <label className="lp-input-label">Preferred Day</label>
                  <input
                    name="preferred-day"
                    className="lp-input-field"
                    type="date"
                    placeholder="Preferred day"
                  />

                  <label className="lp-input-label">Preferred Time</label>
                  <input
                    name="preferred-time"
                    className="lp-input-field"
                    type="time"
                    placeholder="Preferred time"
                  />

                  <label className="lp-input-label">Time Zone</label>
                  <select className="lp-input-field" name="time-zone">
                    {[
                      "Pacific Time",
                      "Mountain Time",
                      "Central Time",
                      "Eastern Time",
                    ].map((tz) => (
                      <option key={tz} value={tz}>
                        {tz}
                      </option>
                    ))}
                  </select>
                </>
              )}

              {activeTab === "contact" && (
                <div className="lp-input-form">
                  <label className="lp-input-label">
                    How would you like us to contact you?
                  </label>
                  <select className="lp-input-field" name="contact-method">
                    <option value="email">Email</option>
                    <option value="phone">Phone</option>
                  </select>
                </div>
              )}

              <button type="submit" className="lp-submit-button">
                Submit
              </button>
            </form>
            <button className="lp-close-button" onClick={onClose}>
              Close
            </button>
            <div className="lp-scroll-to-bottom" onClick={scrollToBottom}>
              <FontAwesomeIcon icon={faArrowDown} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Modal;
