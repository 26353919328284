import React, { useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Button,
  Typography,
  Container,
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Slider,
  Card,
  CardMedia,
  Tabs,
  Tab,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faCheckCircle,
  faArrowRight,
  faRandom,
  faExchangeAlt,
  faFileAlt,
  faUsers,
  faBriefcase,
  faMobileAlt,
  faCloudUploadAlt,
} from "@fortawesome/free-solid-svg-icons";

import { Menu as MenuIcon } from "@mui/icons-material";
import ReactPlayer from "react-player";
import Modal from "./LandingPageModal";
import logo from "../assets/logo.png";

import formImage from "../assets/form.png";
import phoneImage from "../assets/phone.png";

import placeholderImage from "../assets/image001b.png";
import placeholderImage2 from "../assets/image002b.png";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const HrLandingPage = () => {
  const [activeStep, setActiveStep] = useState(0);
  const handleStepChange = (event, newValue) => {
    setActiveStep(newValue);
  };

  const [questions, setQuestions] = useState(15);
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [activeTab, setActiveTab] = useState("contact");
  const faqRef = useRef(null);
  const priceRef = useRef(null);
  const navigate = useNavigate();

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const openModal = (tab) => {
    setActiveTab(tab);
    setModalVisible(true);
  };

  const handleSignUpClick = () => {
    navigate("/signup", { state: { activeTab: "signUp" } });
  };

  const calculateMetrics = (numQuestions) => {
    const timePerForm = 4 + ((numQuestions - 10) / 20) * 3;
    const formsPerHour = Math.floor(60 / timePerForm);
    const costPerForm = (10 / formsPerHour).toFixed(2);

    return {
      timePerForm: `${timePerForm.toFixed(0)} minutes`,
      formsPerHour,
      costPerForm: `$${costPerForm}`,
    };
  };

  const { timePerForm, formsPerHour, costPerForm } =
    calculateMetrics(questions);

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
    closeMenu();
  };

  const faqData = [
    {
      question:
        "How does a candidate complete a pre-interview screening using the voice chatbot?",
      answer:
        "Candidates complete the pre-interview screening over the phone by direct dialing or being transferred to the voice chatbot, entering their unique session PIN to begin.",
    },
    {
      question: "How does the job screening process work?",
      answer:
        "Create screening forms with ease in the dashboard. Candidates complete them over the phone, allowing you to assess their qualifications before an interview, saving you hours in manual processing.",
    },
    {
      question:
        "Can candidates pause and resume their screenings later? What happens if a screening is interrupted?",
      answer:
        "Yes, they can pause and resume later. If interrupted, candidates simply call back, enter their session PIN, and pick up right where they left off.",
    },
    {
      question: "How can I review the screening data?",
      answer:
        "Real-time transcripts in CSV and PDF formats are available instantly in your dashboard, allowing immediate review and action.",
    },
    {
      question:
        "What types of job screening forms are best suited for this tool?",
      answer:
        "Ideal for pre-interview screenings, skill assessments, and more, particularly in HR and legal industries where automation is key.",
    },
    {
      question: "How can I integrate this tool with other HR systems?",
      answer:
        "Currently, we offer integration with Google Drive, making it easy to store and access candidate data securely.",
    },
    {
      question: "Is my data secure?",
      answer:
        "Absolutely. We prioritize your data security with industry-standard encryption and HIPAA compliance, ensuring your information is safe.",
    },
    {
      question: "How do I start using this tool for my HR needs?",
      answer: (
        <Button variant="contained" onClick={handleSignUpClick}>
          Sign Up and Get Started
        </Button>
      ),
    },
  ];

  return (
    <Box>
      <AppBar position="static">
        <Toolbar>
          <img src={logo} alt="FormVox Logo" style={{ height: "50px" }} />
          <Box sx={{ flexGrow: 1 }} />
          <Button color="inherit" onClick={() => navigate("/login")}>
            Sign In
          </Button>
          <Button variant="contained" onClick={handleSignUpClick}>
            Get Started Free
          </Button>
          <IconButton
            color="inherit"
            edge="end"
            onClick={openMenu}
            aria-controls="simple-menu"
            aria-haspopup="true"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={closeMenu}
          >
            <MenuItem onClick={() => openModal("demo")}>Book a Demo</MenuItem>
            <MenuItem onClick={() => scrollToSection(priceRef)}>
              Pricing
            </MenuItem>
            <MenuItem onClick={() => scrollToSection(faqRef)}>FAQ</MenuItem>
            <MenuItem onClick={() => openModal("contact")}>Contact Us</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>

      <Box
        sx={{
          textAlign: "center",
          py: 4,
          bgcolor: "primary.main",
          color: "white",
        }}
      >
        <Container maxWidth="md">
          <Typography variant="h2" component="h1" gutterBottom>
            Automate Candidate Screening with a Voice Chatbot
          </Typography>

          <Box sx={{ textAlign: "center", mt: 4 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={formImage}
                alt="Form"
                style={{
                  margin: "0 10px",
                  width: "120px",
                  height: "auto",
                  borderRadius: "10px", // Adds rounded corners
                }}
              />
              <FontAwesomeIcon
                icon={faArrowRight}
                size="3x"
                style={{ margin: "0 20px" }}
              />
              <img
                src={phoneImage}
                alt="Phone"
                style={{
                  margin: "0 10px",
                  width: "120px",
                  height: "auto",
                  borderRadius: "10px", // Adds rounded corners
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                mt: 2,
              }}
            >
              {/*  <Typography variant="h6" sx={{ margin: "0 10px" }}>
              Turn this into this
            </Typography>*/}
            </Box>
          </Box>

          <Typography variant="h5" paragraph>
            Complete dozens of pre-interview screenings daily with our automated
            voice chatbot. Experience hands-free efficiency and cut down manual
            labor significantly.
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            onClick={handleSignUpClick}
          >
            Get Started Free
          </Button>
        </Container>
      </Box>
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <CardMedia
              component="img"
              alt="Job Screening Creation"
              image={placeholderImage}
              sx={{
                width: "100%",
                height: "auto",
                objectFit: "contain",
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Box>
              <Typography variant="h4" gutterBottom>
                Create Customized Screening Forms in Minutes
              </Typography>
              <Typography variant="body1" paragraph>
                Tailor screening forms to your specific needs with various
                question types and conditional logic. Easily manage and collect
                the most relevant data, saving you hours every week.
              </Typography>
            </Box>
          </Grid>
        </Grid>
        {/* <Grid container spacing={4} sx={{ mt: 4 }}>
          <Grid
            item
            xs={12}
            md={6}
            order={{ xs: 2, md: 1 }}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Box>
              <Typography variant="h4" gutterBottom>
                Let Candidates Start Anytime with Voice Chatbot
              </Typography>
              <Typography variant="body1" paragraph>
                Candidates can initiate their screening by calling or being
                transferred to our voice chatbot. They can pause and resume as
                needed, making it flexible for all participants.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
            <CardMedia
              component="img"
              alt="Call Transfers"
              image={placeholderImage2}
              sx={{
                width: "100%",
                height: "auto",
                objectFit: "contain",
              }}
            />
          </Grid>
        </Grid>  */}
      </Container>

      <Container maxWidth="md" sx={{ py: 4, textAlign: "center" }}>
        <Typography variant="h3" gutterBottom>
          How It Works
        </Typography>
        <Tabs
          value={activeStep}
          onChange={handleStepChange}
          variant="scrollable"
          scrollButtons="auto"
          indicatorColor="primary"
          textColor="primary"
          sx={{
            overflowX: "auto",
            whiteSpace: "nowrap",
          }}
        >
          <Tab label="1. Add Your Questions" />
          <Tab label="2. Generate a Session PIN" />
          <Tab label="3. Start the Process" />
          <Tab label="4. View Responses" />
        </Tabs>
        <TabPanel value={activeStep} index={0}>
          <Typography variant="body1">
            Quickly add and customize your questions directly in our intuitive
            dashboard. Tailor forms to fit your exact needs with ease.
          </Typography>
        </TabPanel>
        <TabPanel value={activeStep} index={1}>
          <Typography variant="body1">
            Generate a secure, unique session PIN for each candidate. This
            ensures personalized, uninterrupted access to the screening process.
          </Typography>
        </TabPanel>
        <TabPanel value={activeStep} index={2}>
          <Typography variant="body1">
            Candidates dial in and enter their PIN to begin the voice-powered
            screening, providing you with valuable insights instantly.
          </Typography>
        </TabPanel>
        <TabPanel value={activeStep} index={3}>
          <Typography variant="body1">
            Access and review detailed responses in real-time. All data is
            available in both CSV and PDF formats for easy integration and
            analysis.
          </Typography>
        </TabPanel>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            my: 4,
          }}
        >
          <Box
            sx={{
              position: "relative",
              width: "80%",
              paddingTop: "45%",
            }}
          >
            <ReactPlayer
              url="https://youtu.be/eGLSKyzT4Yg"
              controls={true}
              width="100%"
              height="100%"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
              }}
            />
          </Box>
        </Box>
      </Container>

      <Container
        maxWidth="md"
        sx={{ py: 4, textAlign: "center" }}
        ref={priceRef}
      >
        <Typography variant="h3" gutterBottom>
          Pricing Information
        </Typography>
        <Typography variant="h4" color="secondary" paragraph>
          Pay as you go – $10 per hour of Voice Chatbot Time
        </Typography>
        <Typography variant="h5" color="primary" paragraph>
          Plus, get a $50 bonus when you add $100 in credit!
        </Typography>
        <Typography variant="body1" paragraph>
          Try it risk-free with our 60-minute free trial. We guarantee you’ll
          save at least 10 hours per week—or your money back!
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
            <FontAwesomeIcon
              icon={faCheckCircle}
              color="primary"
              style={{ marginRight: 8 }}
            />
            <Typography variant="h6" color="primary">
              No subscription required!
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 1,
            }}
          >
            <FontAwesomeIcon
              icon={faCheckCircle}
              color="primary"
              style={{ marginRight: 8 }}
            />
            <Typography variant="h6" color="primary">
              Up to 16x cheaper than manual screening!
            </Typography>
          </Box>
        </Box>

        <Typography variant="body1" paragraph>
          Adjust the slider to estimate costs based on the number of questions
          per form:
        </Typography>
        <Slider
          value={questions}
          onChange={(e, val) => setQuestions(val)}
          min={10}
          max={100}
          step={5}
          sx={{ mb: 2 }}
        />
        <Typography variant="h6">
          Number of Questions per Form: {questions}
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 3,
          }}
        >
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              maxWidth: "500px",
            }}
          >
            <thead>
              <tr>
                <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                  Estimated Voice Chatbot Time
                </th>
                <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                  Forms Completed per Hour
                </th>
                <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                  Cost per Form Completion
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  {timePerForm}
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  {formsPerHour}
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  {costPerForm}
                </td>
              </tr>
            </tbody>
          </table>
        </Box>

        <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
          *Estimates only. Actual call length may vary.
        </Typography>
      </Container>

      <Container maxWidth="lg" sx={{ py: 4 }} ref={faqRef}>
        <Grid container spacing={4}>
          {faqData.map((faq, index) => (
            <Grid item xs={12} key={index}>
              <Typography
                variant="h5"
                gutterBottom
                sx={{
                  cursor: "pointer",
                  color: "primary.main",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                onClick={() =>
                  setActiveTab((prev) => (prev === index ? null : index))
                }
              >
                {faq.question}
                <FontAwesomeIcon
                  icon={activeTab === index ? faChevronUp : faChevronDown}
                />
              </Typography>
              {activeTab === index && (
                <Typography variant="body1" paragraph>
                  {faq.answer}
                </Typography>
              )}
            </Grid>
          ))}
        </Grid>
      </Container>

      <Modal
        show={modalVisible}
        onClose={() => setModalVisible(false)}
        initialTab={activeTab}
      />

      <Box
        sx={{ textAlign: "center", py: 2, bgcolor: "grey.900", color: "white" }}
      >
        <Typography variant="body2">
          &copy; 2024 FormVox LLC. All rights reserved.
        </Typography>
        <Typography variant="body2">
          30 N Gould St Ste R, Sheridan, WY 82801
        </Typography>
        <Typography variant="body2">
          Contact:{" "}
          <a
            href="mailto:contact@formvox.com"
            style={{
              textDecoration: "none",
              color: "white",
            }}
          >
            contact@formvox.com
          </a>
        </Typography>

        <Typography variant="body2">
          <a
            href="/privacy_policy.html"
            target="_blank"
            style={{
              textDecoration: "none",
              color: "white",
            }}
          >
            Privacy Policy
          </a>
        </Typography>
      </Box>
    </Box>
  );
};

export default HrLandingPage;
