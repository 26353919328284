import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import ConfirmationModal from "./ConfirmationModal"; // Import the new confirmation modal component
import "./CreateFormModal.css"; // Import a CSS file for styling

const CreateFormModal = ({ onClose }) => {
  const [isManualInput, setIsManualInput] = useState(false);
  const [formName, setFormName] = useState("");
  const [formNameLocked, setFormNameLocked] = useState(false);
  const [questions, setQuestions] = useState([
    { text: "", type: "Yes/No", condition: { yes: "", no: "" }, locked: false },
  ]);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [deleteQuestionIndex, setDeleteQuestionIndex] = useState(null);

  const modalContentRef = useRef(null);

  const handleUploadCSV = () => {
    // Functionality to handle CSV upload
    alert("CSV upload functionality coming soon!");
  };

  const handleManualInput = () => {
    setIsManualInput(true);
  };

  const handleFormNameSave = () => {
    setFormNameLocked(true);
  };

  const handleFormNameEdit = () => {
    setFormNameLocked(false);
  };

  const handleQuestionChange = (index, field, value) => {
    const newQuestions = [...questions];
    newQuestions[index][field] = value;
    setQuestions(newQuestions);
  };

  const handleConditionChange = (index, response, value) => {
    const newQuestions = [...questions];
    newQuestions[index].condition[response] = value;
    setQuestions(newQuestions);
  };

  const handleAddQuestion = () => {
    setQuestions([
      ...questions,
      {
        text: "",
        type: "Yes/No",
        condition: { yes: "", no: "" },
        locked: false,
      },
    ]);
  };

  const handleSave = () => {
    // Functionality to handle saving the form
    console.log("Form Name:", formName);
    console.log("Questions:", questions);
    alert("Form saved successfully!");
    onClose();
  };

  const handleCancel = () => {
    setShowCancelModal(true);
  };

  const confirmCancel = () => {
    setShowCancelModal(false);
    onClose();
  };

  const handleLockQuestion = (index) => {
    const newQuestions = [...questions];
    newQuestions[index].locked = !newQuestions[index].locked;
    setQuestions(newQuestions);
  };

  const handleDeleteQuestion = (index) => {
    setDeleteQuestionIndex(index);
  };

  const confirmDeleteQuestion = () => {
    const newQuestions = questions.filter(
      (_, qIndex) => qIndex !== deleteQuestionIndex
    );
    setQuestions(newQuestions);
    setDeleteQuestionIndex(null);
  };

  const allFieldsFilled = () => {
    if (!formName) return false;
    for (let i = 0; i < questions.length; i++) {
      if (!questions[i].text) return false;
    }
    return true;
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content" ref={modalContentRef}>
        <h2>Create New Client Form</h2>
        {!formNameLocked ? (
          <div className="form-name-input-container">
            <input
              type="text"
              placeholder="Enter name for client form"
              value={formName}
              onChange={(e) => setFormName(e.target.value)}
              className="form-name-input"
            />
            <button onClick={handleFormNameSave} disabled={!formName}>
              Save
            </button>
          </div>
        ) : (
          <div className="saved-form-name">
            <span>{formName}</span>
            <FontAwesomeIcon
              icon={faEdit}
              onClick={handleFormNameEdit}
              className="edit-icon"
            />
          </div>
        )}
        {!isManualInput && (
          <>
            <p>How do you want to provide the questions?</p>
            <div className="button-group">
              <button onClick={handleUploadCSV}>Upload CSV</button>
              <button onClick={handleManualInput}>
                Manually Input Questions Here
              </button>
            </div>
          </>
        )}
        {isManualInput && (
          <div className="manual-input-section">
            {questions.map((question, index) => (
              <div key={index} className="question-input">
                {!question.locked ? (
                  <>
                    <input
                      type="text"
                      placeholder={`Enter question #${index + 1}`}
                      value={question.text}
                      onChange={(e) =>
                        handleQuestionChange(index, "text", e.target.value)
                      }
                    />
                    <select
                      value={question.type}
                      onChange={(e) =>
                        handleQuestionChange(index, "type", e.target.value)
                      }
                    >
                      <option value="Firstname">First name</option>
                      <option value="Lastname">Last name</option>
                      <option value="Open">Open</option>
                      <option value="Yes/No">Yes/No</option>
                    </select>
                    {question.type === "Yes/No" && (
                      <div className="conditional-questions">
                        <p>If client responds "Yes":</p>
                        <select
                          value={question.condition.yes}
                          onChange={(e) =>
                            handleConditionChange(index, "yes", e.target.value)
                          }
                        >
                          <option value="next">Proceed to next question</option>
                          <option value="custom">
                            Ask this question (specify)
                          </option>
                        </select>
                        {question.condition.yes === "custom" && (
                          <input
                            type="text"
                            placeholder="Enter follow-up question"
                            value={question.condition.customYes || ""}
                            onChange={(e) =>
                              handleConditionChange(
                                index,
                                "customYes",
                                e.target.value
                              )
                            }
                          />
                        )}
                        <p>If client responds "No":</p>
                        <select
                          value={question.condition.no}
                          onChange={(e) =>
                            handleConditionChange(index, "no", e.target.value)
                          }
                        >
                          <option value="next">Proceed to next question</option>
                          <option value="custom">
                            Ask this question (specify)
                          </option>
                        </select>
                        {question.condition.no === "custom" && (
                          <input
                            type="text"
                            placeholder="Enter follow-up question"
                            value={question.condition.customNo || ""}
                            onChange={(e) =>
                              handleConditionChange(
                                index,
                                "customNo",
                                e.target.value
                              )
                            }
                          />
                        )}
                      </div>
                    )}
                  </>
                ) : (
                  <div className="saved-question">
                    <div className="question-text">
                      <p>{`Question #${index + 1}: ${question.text}`}</p>
                      <p>{`Type: ${question.type}`}</p>
                      {question.type === "Yes/No" && (
                        <div className="conditional-summary">
                          <p>{`If Yes: ${
                            question.condition.yes === "custom"
                              ? question.condition.customYes
                              : "Proceed to next question"
                          }`}</p>
                          <p>{`If No: ${
                            question.condition.no === "custom"
                              ? question.condition.customNo
                              : "Proceed to next question"
                          }`}</p>
                        </div>
                      )}
                    </div>
                    <div className="question-icons">
                      <FontAwesomeIcon
                        icon={faEdit}
                        onClick={() => handleLockQuestion(index)}
                        className="edit-icon"
                      />
                      <FontAwesomeIcon
                        icon={faTrash}
                        onClick={() => handleDeleteQuestion(index)}
                        className="delete-icon"
                      />
                    </div>
                  </div>
                )}
                {!question.locked && (
                  <button onClick={() => handleLockQuestion(index)}>
                    Save
                  </button>
                )}
              </div>
            ))}
            <button
              onClick={handleAddQuestion}
              disabled={questions.some((q) => q.text === "")}
            >
              Add New Question
            </button>
          </div>
        )}
      </div>
      <div className="bottom-buttons">
        <button
          className="save-complete-btn"
          onClick={handleSave}
          disabled={!allFieldsFilled()}
        >
          Save and Complete Form
        </button>
        <button className="close-btn" onClick={handleCancel}>
          {isManualInput ? "Cancel" : "Close"}
        </button>
      </div>
      {showCancelModal && (
        <ConfirmationModal
          message="Are you sure you want to cancel? All changes will be lost."
          onClose={() => setShowCancelModal(false)}
          onConfirm={confirmCancel}
        />
      )}
      {deleteQuestionIndex !== null && (
        <ConfirmationModal
          message="Are you sure you want to delete this question?"
          onClose={() => setDeleteQuestionIndex(null)}
          onConfirm={confirmDeleteQuestion}
        />
      )}
    </div>
  );
};

export default CreateFormModal;
